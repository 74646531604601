// @import url('https://fonts.cdnfonts.com/css/ds-digital');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap');

.nav-item {
  .nav-link {
    display: flex;
    align-items: center;

    &.active {
      color: #fff;
      background: rgba(255, 255, 255, .23);
      opacity: 1;
    }
  }
}

.cr-app {
  overflow: hidden;
}

#coordinator-nav {
  background: #fbffd6;
  border-bottom: 1px solid #f8ffb4;
}

#entry-guide-modal button.submit-entry {
  background: #1ec2f3;
  color: #ffffff;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  display: block;
  text-align: center;
  padding: 0.375rem 1.5rem;
  margin-right: auto;
  margin-left: auto;
  outline: none;
  margin-bottom: 15px;
}

.seat-container .seat {
  width: 15px;
  border: 2px solid #1ec2f3;
  height: 15px;
  border-radius: 50%;
  position: absolute;
}

.seat-container .seat-venue-2 {
  width: 12px;
  border: 2px solid #1ec2f3;
  height: 12px;
  border-radius: 50%;
  position: absolute;
}

.floor-plan-back {
  position: absolute;
  right: 25px;
  padding-top: 0;
}

.seat-container .seat-1 {
  top: -44px;
}

.seat-container .seat-2 {
  top: -36px;
  right: -26px;
}

.seat-container .seat-3 {
  top: -14px;
  right: -43px;
}

.seat-container .seat-4 {
  top: 14px;
  right: -43px;
}

.seat-container .seat-5 {
  top: 37px;
  right: -26px;
}

.seat-container .seat-6 {
  top: 44px;
  right: 0;
}

.seat-container .seat-7 {
  top: 37px;
  left: -26px;
}

.seat-container .seat-8 {
  top: 14px;
  left: -43px;
}

.seat-container .seat-9 {
  top: -14px;
  left: -43px
}

.seat-container .seat-10 {
  top: -36px;
  left: -26px;
}

.seat-container {
  width: 15px;
  max-width: 15px;
  height: 15px;
  max-height: 15px;
  position: absolute;
}

.entry-guide-card {
  height: 700px;
}

#judge-nav {
  background: #e9f9ff;
  border-bottom: 1px solid #d4f3ff;
}

.imgPreview {
  margin-bottom: 20px;
  border: solid 2px #1ec2f3;
  margin-top: 20px;
  max-width: 350px;
}

.gallery-table-loading {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  // bottom: 10px;
  // left: -15px;
  // right: -15px;
  width: 100%;
}

.imgPreview img {
  margin-bottom: 0;
  max-height: 350px;
  width: 100%;
}

.crop-container {
  position: relative;
  width: 500px;
  height: 300px;
}

#resizer-button {
  max-width: 120px;
  width: 100%;
}

#logo-resizer-container input[type="button"] {
  background: #1E2226;
  color: white;
  margin: 30px 0;
}

#logo-resizer-container .reactEasyCrop_Contain {
  background: rgba(0, 0, 0, .5);
}

.mw-100px {
  max-width: 100px;
}

.croped-image-container {
  border: solid 2px #ced4da;
  max-width: 250px;
  max-height: 250px;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Toastify__toast-body {
  font-family: 'Poppins';
}

.edit-sponsor-modal .crop-container,
.add-sponsor-modal .crop-container {
  position: relative;
  width: 350px;
  height: 200px;
}

.judging-results-card .card-body {
  padding: 0 !important;
  border: 1px solid #dee2e6;
  border-radius: 5px;
}

.judging-results-card {
  $var: 5;

  @for $i from 1 through 1000 {
    &:nth-child(#{$i+1}) {
      margin-bottom: 48px;
    }
  }
}

#report-modal {
  max-width: 95% !important;
  margin-top: 2%;

}

.judging-results-card {
  padding: 25px;
  border: 0;
}

.judging-results-card table {
  //width: 2000px;
  margin-bottom: 0;
  max-width: none;
  width: 100%;
  overflow-x: scroll;
  display: block;
  // padding-bottom: 30px;
}

.judging-results-card table th {
  min-width: 300px;
  border-top: 0;
}

.header-entry {
  background: #2e2e2e;
  color: #fff;
}

.body-entry {
  background: whitesmoke;
}

.header-criteria {
  background: #1ec2f3;
  color: #fff;
}

.body-score {
  background: #f1fcff;
}

.header-rating {
  background: #ff5d5d;
  color: #fff
}

.body-rating {
  background: #ffefef;
}

.header-comment {
  background: #f0ff95;
}

.body-comment {
  background: #ffffde;
}

.judging-results-card table thead td {
  font-weight: 600;
  border-left: 0;
  border-top: 0;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}



.judging-results-card table td {
  border: 1px solid #fff;
}

.report-judge-name {
  padding: 15px;
  font-size: 18px;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 0;
}

.judging-results-card td {
  white-space: nowrap;
  overflow: hidden;
  padding: 8px 15px;
}

#bookings-modal {
  max-width: 1000px !important;
}

.edit-sponsor-modal .croped-image-container,
.add-sponsor-modal .croped-image-container {
  max-width: 150px;
  max-height: 150px;

}

.sidebar .nav a {
  transition: all 150ms ease-in;
}

.card-header {
  text-transform: uppercase;
}

.badge:empty {
  display: inline-block;
}

#mainTab .nav-item .nav-link.active {
  color: #fff;
  background: #1E2226;
}

#mainTab .nav-item .nav-link {
  cursor: pointer;
  text-align: center;
  display: block;
  font-weight: 700;
  font-size: 17px;
  transition: 0.4s
}

.popover-inner .bg-gradient-theme {
  background: linear-gradient(to bottom, #1c2023, #212529);
  border: none;
}

.popover .arrow {
  display: none;
}

#mainTab li.nav-item {
  width: 100%;
}

@media only screen and (min-width: 769px) {
  #mainTab li.nav-item {
    max-width: 20%;
  }
}

#mainTab .nav-tabs {
  border-bottom: 1px solid #dee2e6;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  max-width: 1034px;
}

#mainTab .nav-tabs .nav-link:hover,
#tab .nav-tabs .nav-link:focus {
  background: #1E2226;
  color: #fff !important;
}

#mainTab .form-container {
  max-width: 1034px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 40px;
  border-radius: 15px;
  padding: 40px 40px 25px 40px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  background: #ffffff;
}

.gallery-video.gallery-video-big {
  width: 100%;
  height: auto;
}

.container-iframe {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio */
}

// Marquee
.marquee-container {
  overflow: hidden;
}

.marquee-item img {
  width: 100%;
  max-width: 180px;
}

.marquee-item {
  padding-left: 25px;
  padding-right: 25px;
}

@media only screen and (min-width: 1081px) {
  .marquee-item img {
    width: auto;
    height: 3.5vw;
    max-width: 100%;
  }
}

@media only screen and (min-width: 1400px) {
  .marquee-item {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.gallery-video.iframe {
  pointer-events: none;
  border: 0;
}

.video-controller iframe {
  width: 100%;
  border: 0;
}

.btn:focus,
.btn.focus {
  box-shadow: none !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.AwardPage {
  background: url(../../src/assets/img/bg/form-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#mainTab .form-control {
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  // padding: 20px;
  font-size: 14px;
}

#mainTab label {
  color: rgb(0, 0, 0);
  font-weight: 700;
  text-transform: uppercase;
}

#mainTab h3.livestream-title {
  text-transform: uppercase;
  font-weight: 500;
  color: #1E2226;
  font-size: 20px;
  margin-top: 10px;
}

.h2.cr-page__title {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;
  font-weight: 700;
  color: #1ec2f3;
}

.cr-page__header {
  display: block !important;
}

nav.cr-header.bg-white.navbar.navbar-expand.navbar-light {
  display: flex;
}

nav.navbar {
  background: #1E2226;
  text-align: center;
  color: #fff;
  display: block;
  margin-bottom: 0 !important;
}

.cr-sidebar__content nav.text-center.navbar {
  background: transparent;
}

#award_table .table-logo {
  width: inherit;
  max-width: 130px;
}

#award_table .logo-name {
  margin-bottom: 0;
}

#award_table .table-title {
  background: #1ec2f3;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 3px;
}

#award_table h5 {
  margin-bottom: 0;
  color: #ffffff;
  font-weight: 700;
  font-size: 18px;
}

#award_table .row>[class^='col-'] {
  margin-bottom: 0;
  padding-right: 0;
  padding-left: 0;
}

#award_table .table-container {
  align-items: center;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding-top: 10px;
  padding-bottom: 40px;
  max-width: 1034px;
  margin-left: auto;
  margin-right: auto;
}

#award_table {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

#award_table .action-btn {
  background: #1E2226;
  border-radius: 3px;
  border: none;
  color: #fff;
  padding: 4px 14px;
  font-size: 13px;
  font-weight: 700;
  outline: none;
}

#award_table a {
  color: #1ec2f3;
  text-decoration: none;
}

#award_table .col {
  padding-left: 0;
  padding-right: 0;
}

#award_table .card-group {
  max-width: 1034px;
  margin-left: auto;
  margin-right: auto;
}

#award_table .table thead th {
  border-bottom: none;
  border-top: 0;
}

.setup-submit-container {
  max-width: 1034px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

#award_table .table {
  margin-bottom: 0;
}

#mainTab .card {
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  align-items: center;
  max-width: 1034px;
}

#mainTab .card-title {
  font-weight: 700;
  font-size: 25px;
}

#mainTab .card-body {
  padding: 20px 40px;
}

#mainTab p.card-text {
  margin-bottom: 0;
}

#mainTab p.card-text a {
  color: #1EC2F3;
  text-decoration: none;
}

#mainTab .tab-content {
  padding-top: 30px;
  padding-bottom: 40px;
}

#mainTab .host-container {
  display: block;
}

#mainTab .sponsor-container {
  display: block;
  padding: 0;
}

#mainTab .sponsor-logo {
  margin-bottom: 0;
  padding-bottom: 0;
  max-width: 75px;
}

#mainTab .card-body a {
  color: #1EC2F3;
  text-decoration: none;
}

#mainTab .sponsor-form {
  margin-top: 30px;
  display: table;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

#mainTab input.form-control-file {
  padding-left: 100px;
}

.table-container .card-body {
  padding: 0;
}

#mainTab .table th,
.table td {
  vertical-align: top;
}

#mainTab .table {
  margin-bottom: 0;
}

.sponsor-container .card-body {
  padding-bottom: 0 !important;
}

.cr-sidebar::after {
  background: #1E2226 !important;
  /*background: linear-gradient(to top, #1ec2f3, #1ec2f3)!important;*/
}


.cr-sidebar__background {
  background-position: right !important;
}

.cr-sidebar .nav .nav-link {
  /*font-weight: 700;*/
  color: #ffffff !important;
}

.ticket-container .card-body {
  padding-bottom: 0 !important;
}

#mainTab .ticket-container {
  display: block;
  padding: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

.ticket-container .card-body {
  padding: 0 !important;
}

.progress-bar-container {
  width: 100%;
  height: 10px;
  background: #a5a5a5;
  border-radius: 5px;
  position: relative;
}

.progress-bar-percent {
  height: 10px;
  background: #1ec2f3;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  font-size: 10px;
  // padding-top: 2px;
  overflow: hidden;
}

/*.ticket-container .table thead th {
  border-bottom: none;
}

.ticket-container .table th, .ticket-container .table td {
  border-top: none;
}*/

.modal-dialog {
  // max-width: 700px !important;
  max-width: 700px;
}

.ticket-container tr.ticket-header {
  background: #1ec2f3;
  color: #ffffff;
}

.ticket-container .action-btn {
  background: #1E2226;
  border-radius: 3px;
  border: none;
  color: #fff;
  padding: 4px 14px;
  font-size: 13px;
  font-weight: 700;
  outline: none;
  transition: none;
}

#logo-resizer-container input[type="file"] {
  margin-bottom: 20px;
}

#mainTab .TicketBtn {
  background: #1E2226;
  color: #fff;
  padding: 5px 20px;
  border-radius: 3px;
  text-decoration: none;
  font-weight: 700;
  outline: none;
  border: none;
  transition: none;
}

.AwardPage {
  padding-bottom: 40px;
}

.AwardPage .card {
  margin-left: auto;
  margin-right: auto;
  max-width: 1034px;
  padding: 20px 10px 20px 10px;
}

.AwardPage .card-title {
  font-weight: 700;
  font-size: 17px;
  max-width: 363px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 5px;

}

.notification.notification-info {
  display: none !important;
}

.AwardPage .card-body {
  padding: 0px 10px 0 20px;
}

.AwardPage p.card-text {
  margin-bottom: 0;
  font-size: 13px;
}

.AwardPage p.card-text a {
  color: #1EC2F3;
  text-decoration: none;
}

.AwardPage .tab-content {
  padding-top: 30px;
  padding-bottom: 40px;
}

.AwardPage img.card-img-left.card-img {
  margin-top: 5px;
}

.AwardPage .AddBtn {
  background: #1E2226;
  color: #fff;
  padding: 5px 20px;
  border-radius: 3px;
  text-decoration: none;
  font-weight: 700;
  outline: none;
}

.AwardPage .EditBtn {
  background: #1E2226;
  color: #fff !important;
  padding: 4px 14px;
  border-radius: 3px;
  text-decoration: none;
  font-weight: 700;
  outline: none;
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #1E2226 !important;
  background-image: none;
  border-color: transparent;
  border: none;
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: none;
}

.btn-secondary {
  background: #1E2226 !important;
  border: none;
}

.modal-body {
  padding: 20px 30px 30px 30px;
}

.modal-title {
  font-weight: 700;
  color: #1E2226;
  font-size: 20px;
  text-align: center;
}

.modal-title-small {
  font-weight: 700;
  color: #1E2226;
  font-size: 16px;
  text-align: center;
}

.modal-body label {
  color: #1E2226;
  font-weight: 700;
  text-transform: uppercase;
}

.close {
  position: absolute;
  right: 10px;
  top: 8px;
  outline: none;
}

.close span {
  outline: none;
}

.modal-header {
  display: block;
}

.modal-header .close {
  outline: none;
}

.sponsor-container .action-btn {
  background: #1E2226;
  border-radius: 3px;
  border: none;
  color: #fff;
  padding: 4px 14px;
  font-size: 13px;
  font-weight: 700;
  outline: none;
  transition: none;
}

button.modalBtn {
  background: #1EC2F3;
  border-radius: 3px;
  padding: 5px 30px;
  border: none;
  box-shadow: none;
  font-weight: 700;
  margin-left: auto;
  margin-right: auto;
  display: block;
  outline: none;
}

button.modalBtn:hover {
  background: #1EC2F3;
}

#mainTab .SponsorBtn {
  background: #1E2226;
  color: #fff;
  padding: 5px 20px;
  border-radius: 3px;
  text-decoration: none;
  font-weight: 700;
  outline: none;
  border: none;
  transition: none;
}

.btnContainer {
  max-width: 1034px;
  margin-left: auto;
  margin-right: auto;
}

.AwardContainer.row {
  max-width: 1070px;
  margin-left: auto;
  margin-right: auto;
}

.DashboardPage .card-subtitle {
  font-weight: 700;
  color: #1E2226;
}

body {
  padding-right: 0 !important;
}





/* Entry Page CSS */
#your_details .form-control {
  font-size: 14px;
}

.entryPage {
  // background-image: url("../assets/img/logo/entry-bg.png");
  // background-position: top;
  // background-size: cover;
  // background-repeat: no-repeat;
  padding-top: 50px;
  padding-bottom: 50px;
}

.entryPage .entry_steps_form {
  max-width: 900px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.entryPage .entry_logo {
  width: 200px;
}

#show-pdf-modal {
  display: block;
  background: rgba(0, 0, 0, 0.8);
  padding-top: 0;
}

.entryPage .fm_logo {
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 20px;
}

.entryPage .steps-container {
  margin-top: 20px;
}

.entryPage .progress .icon_title {
  font-weight: 700;
  color: #000;
  margin-top: 10px;
  margin-left: 15px;
  font-size: 16px;
}

.entryPage .progress .icon_steps {
  filter: brightness(0.5);
}

.entryPage .progress .steps_img_container1 .icon_steps {
  padding: 14px 0px 0px 7px;
  filter: brightness(1);
}

.entryPage .progress .icon_steps {
  max-width: 48px;
}

.entryPage .progress .steps_img_container {
  padding: 11px 0px 0px 0px;
}

.entryPage h2 {
  color: #1EC2F3;
  font-weight: 700;
}

/*.entryPage .form-container {
  padding: 50px 30px;
  border-radius: 20px;
  margin-top: 30px;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}*/

.entryPage .required {
  font-style: italic;
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 15px;
}

.entryPage label {
  margin-bottom: 5px;
}

.hidden {
  display: none;
}

.entryPage span.asterisk_red {
  color: #f00;
}

.asterisk_red {
  color: #f00;
}

.entryPage .form-p {
  margin-bottom: 10px;
  font-size: 16px;
}

.entryPage .form-btn {
  background: #ffffff;
  color: #000;
  text-decoration: none;
  padding: 15px;
  border: 1px solid #dfdfdf;
  border-radius: 0.25rem;
  display: block;
  max-width: 170px;
  text-align: center;
  padding: 0.375rem 0.75rem;
  font-weight: 600;
}

button:disabled,
button[disabled] {
  opacity: 0.5;
}

.primary-btn,
.btn-primary {
  background: #1ec2f3 !important;
  color: #fff !important;
}

.primary-btn.btn-hollow {
  background: #ffffff !important;
  color: #1ec2f3 !important;
}

.primary-btn.btn-hollow:hover {
  background: #1ec2f3 !important;
  color: #ffffff !important;
}

.success-btn,
.btn-success {
  background: #5ed55e !important;
  color: #fff !important;
}

.warning-btn,
.btn-warning {
  background: #ffd700 !important;
  color: #fff !important;
}

.cancel-btn {
  background: red !important;
  color: #fff !important;
}

.no-shadow {
  box-shadow: none !important;
}

.inline-block {
  display: inline-block !important
}

.submit-btn {
  background: #5ed55e !important;
  color: #fff !important;
  border-color: #5ed55e !important;
}

.entryPage .form-btn:hover {
  color: #212529;
  background: #ebebeb;
}

.entryPage .form-btn:focus {
  outline: none;
}


.entryPage .left-btn {
  margin-left: auto;
}

.entryPage .right-btn {
  margin-right: auto;
}

.entryPage .center-btn {
  margin-right: auto;
  margin-left: auto;
}

.entryPage .download_icon,
#admin-create-award .download_icon,
.modal-body .download_icon,
.update-entry .download_icon,
.draft-entry .download_icon,
.task-upload-card .download_icon {
  border: 1px solid #ced4da;
  padding: 80px;
  border-radius: 0.25rem;
  margin-top: 10px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
}

.auto-height {
  height: auto !important;
}

.dropdown-menu-nav:hover .dropdown-menu {
  display: block;
}

.dropdown-menu-nav {
  display: flex;
  align-items: center;
}

.dropdown-menu-nav .dropdown-menu {
  left: -70px;
  padding-top: 0;
  padding-bottom: 0;
  top: 80%;
  width: 202px;
}

.modal-dialog-centered {
  align-items: start !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

// .dropdown-menu-nav .dropdown-menu .nav-link:nth-child(1) {
//   padding-bottom: 0;
// }

.entryPage .form-check {
  padding-left: 0;
}

.entryPage .checkbox-form {
  padding-left: 20px;
}

.btn-secondary.tabs-btn.active {
  background-color: #777777 !important;
  font-weight: 600;
}

.tabs-btn.btn-secondary:not(:disabled):not(.disabled).active {
  background-color: #777777 !important;
  font-weight: 600;
}

#booking-details h3.event-details-header {
  font-size: 20px;
}

.btn-secondary.tabs-btn {
  background: #777777 !important;
  font-weight: 600;
}

.roundroom-img {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
}

.entryPage form {
  padding-left: 20px;
  padding-right: 20px;
}

.progress {
  display: inline-flex;
  align-items: center;
  padding: 0;
  margin: 0 auto;
  max-width: 670px;
  width: 100%;
  list-style: none;
  height: auto;
  background: none;
  margin-bottom: 50px;
  margin-top: 40px;
}

.progress-v2 {
  display: inline-flex;
  align-items: center;
  padding: 0;
  margin: 0 auto;
  max-width:850px;
  width: 100%;
  list-style: none;
  height: auto;
  background: none;
  margin-bottom: 50px;
  margin-top: 40px;
}

.progress.progress-allocation {
  display: block;
  height: 1.1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #1ec2f3;
  border-radius: 50rem;
  width: 55px;
  margin-top: 3px;
  margin-bottom: 0;
  position: relative;
  color: #fff;
}

.progress.progress-allocation .progress-bar {
  // width: 20%;
  // display: block;
  position: absolute;
  height: 100%;
}

.progress_title1 {
  text-align: center;
  font-size: 20px;
  margin-left: -35px;
  position: absolute;
}

.progress_title2 {
  text-align: center;
  font-size: 20px;
  margin-left: -30px;
  position: absolute;
}

.progress_title3 {
  text-align: center;
  font-size: 20px;
  margin-left: -10px;
  position: absolute;
}

.progress_title4 {
  text-align: center;
  font-size: 20px;
  margin-left: -10px;
  position: absolute;
}

.steps_img_container1 {
  //padding-top: 11px;
  width: 80px;
  height: 80px;
  border: 5px solid #F2F2F2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  background: #f2f2f2;
}

.steps_img_container {
  width: 80px;
  height: 80px;
  border: 5px solid #F2F2F2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  background: #f2f2f2;
}

.steps_img_container_active {
  width: 80px;
  height: 80px;
  border: 5px solid #F2F2F2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
}

.entryPage .progress .icon_steps_active {
  padding: 11px 0px 0px 0px;
  filter: brightness(1) !important;
  max-width: 48px;
}


.progress .icon_step_active {
  padding: 11px 0px 0px 0px;
  // filter: brightness(1) !important;
  max-width: 48px;
}

.progress__bar {
  flex: 1;
  height: 8px;
  background: #F2F2F2;
}

.progress--done {
  background: #1EC2F3;
}

.progress--active {
  border: 5px solid #1EC2F3;
  color: #1EC2F3;
}


/* BIS Login Form CSS */
#bis-form .btn-secondary {
  background: #1EC2F3 !important;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
}

#bis-form .bis-link {
  color: #1EC2F3;
  text-decoration: none;
}

.bis_form_card {
  border: none !important;
  background: transparent;
  padding-top: 50px;
  padding-bottom: 50px;
}

#bis-form a.forget_password {
  color: #7F7F7F;
  text-align: center;
  // padding-left: 14px;
  text-decoration: none;
}

@media only screen and (min-width: 981px) {
  .login-registration-row {
    width: 700px;
    min-height: 555px;
  }
  .maintenance-row {
    width: 700px;
    min-height: 555px;
  }
}

.login-registration-row {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.39);
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 0px 0px 15px 0px rgba(20, 10, 10, 0.39);
}

#bis-form .bis_signup_form h3 {
  font-weight: 700;
  font-size: 17px;
  margin-top: 30px;
  margin-left: 0;
}

#bis-form .form_login {
  // font-size: 20px;
  font-size: 28px;
  font-weight: 700;
  color: #1ec2f3;
  margin-top: 10px;
}

#bis-form .bis_login_form {
  background: #fff;
  // padding: 30px 20px;
  // border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.bis-logo-white {
  height: auto;
  min-height: 50px;
  max-width: 130px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: auto;
  margin-right: auto;
}

.bis-logo-white img {
  width: 100%;
}

#bis-form .card_bg_gray {
  background: #e6e6e6;
  padding: 30px 10px 10px 10px;
  border-radius: 5px;
}

#bis-form .form-group {
  margin-bottom: 0;
}

#bis-form .card_bg_white {
  background: #ffffff;
  padding: 30px 10px 30px 10px;
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-left: 0;
  margin-right: 0;
}

.mobile-only {
  display: none;
}

@media only screen and (max-width:981px) {
  .bis-logo-white {
    margin-top: 20px !important;
  }

  .VEPcardbackground {
    height: auto !important;
    padding-bottom: 20px !important;
  }

  #bis-form .form_login {
    margin-top: 0 !important;
    margin-bottom: 10px !important;
    font-size: 22px !important;
  }

  .VEPbackground .form-control {
    margin-bottom: 20px !important;
  }

  .bis_signup_form.container {
    padding-left: 0;
    padding-right: 0;
  }

  .mobile-only {
    display: block !important;
  }

  #bis-form .card_bg_white {
    padding: 20px 0px 0 0 !important;
  }
}

#bis-form h3 {
  font-weight: 700;
  font-size: 17px;
  margin-top: 30px;
  margin-left: -15px;
}

/* BIS Profile CSS */
.username-row .edit-name {
  position: absolute;
  top: -5px;
  right: 0px;
  cursor: pointer;
}

.companyname-row .edit-company {
  position: absolute;
  top: -5px;
  right: 0px;
  cursor: pointer;
}

#profile-user-bg {
  width: 100%;
  height: 100%;
  max-height: 150px;
}

#user-avatar-container {
  height: 100%;
  width: 100%;
  max-height: 170px;
  max-width: 170px;
  border-radius: 50%;
  background: white;
  padding: 10px;
  margin: 0px auto;
  z-index: 1;
  position: relative;
}

#profile-view-container .avatar-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

#user-avatar-container .edit-avatar {
  position: absolute;
  right: 5px;
  width: 35px;
  height: 35px;
  background: white;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
}

#profile-view-container h4,
#profile-view-container h3,
#profile-view-container h6 {
  font-weight: 700;
}

#profile-view-container .profile-cards-container {
  background: #fff;
  border-radius: 5px;
  padding: 30px 40px 20px 40px;
  box-shadow: 0px 0px 5px 0px #efefef;
}


// input[type="file"] {
//   display: none;
// }

#profile-view-page {
  padding: 0 !important;
}

#profile-view-container .left-cards {
  padding-right: 25px;
}

#profile-view-container .right-cards {
  padding-left: 25px;
}

#profile-view-container .col-md-6 {
  border-bottom: 1px solid #00000012;
}

.modal .btn-secondary {
  background: #1E2226 !important;
  border: 0;
}

.edit-profile-modal .modal-header,
.edit-password-modal .modal-header,
.edit-company-modal .modal-header {
  padding-left: 25px;
  padding-right: 25px;
}

.edit-profile-modal input,
.edit-profile-modal textarea,
.edit-password-modal input,
.edit-password-modal textarea,
.edit-company-modal input,
.edit-company-modal select {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.edit-profile-modal input:focus,
.edit-profile-modal textarea:focus,
.edit-password-modal input:focus,
.edit-password-modal textarea:focus {
  box-shadow: none !important;
}

.edit-profile-modal .form-group,
.edit-password-modal .form-group,
.edit-company-modal .form-group {
  margin-bottom: 30px;
}

.edit-profile-modal .modal-body,
.edit-password-modal .modal-body,
.edit-company-modal .modal-body {
  padding: 30px;
}

#profile-view-container #change-pass-btn {
  color: #1EC2F3;
  cursor: pointer;
  text-decoration: none !important;
}

.btn-info:hover {
  color: #fff;
  background: #1EC2F3;
  opacity: 1;
}

.btn-info {
  color: #fff;
  background: #1EC2F3;
}

.edit-password-modal .show-pass-btn {
  position: absolute;
  right: 40px;
  top: 11px;
  cursor: pointer;
}

#profile-view-container {
  max-width: 1350px !important;
  padding-bottom: 50px;
}

.edit-profile-modal .label-container {
  text-align: left;
  padding-top: 6px;

}

.edit-password-modal .label-container {
  text-align: left;
  padding-top: 6px;

}

.edit-company-modal .label-container {
  text-align: left;
  padding-top: 6px;

}

.edit-profile-modal .label-container label {
  margin-bottom: 0;
}

#profile-view-container #change-pass-btn:hover {
  text-decoration: underline;
}

#logo-resizer-container input[type="button"] {
  background: #1E2226;
  color: white;
  margin: 30px 0;
}

.cropper-container {
  height: 605px;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: 0 auto;
}

.container-image {
  height: fit-content;
  margin: 30px 0;
}

.container-cropper {
  height: 85%;
  padding: 10px;
}

.cropper {
  height: 100%;
  position: relative;
}

.container-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1024px) {
  #profile-view-container .right-cards {
    padding-right: 15px;
  }

  #profile-view-container .left-cards {
    padding-left: 15px;
  }

  #profile-view-container .col-md-6 {
    border-bottom: none;
  }
}

/* BIS Awards Page */

#bis-award h1 {
  font-size: 25px;
  font-weight: 700;
}

.table-titles {
  font-size: 25px;
  font-weight: 700;
}

#bis-award .awards {
  /*border-radius: 10px;*/
  background-color: #ffffff;
  /*padding: 10px 20px 0;
  margin: 20px 10px;*/
  min-height: 215px;
  position: relative;
}

.not-visible {
  visibility: hidden;
}

#bis-award .awards:hover {
  background-color: #1ec2f3;
  cursor: pointer;
  color: #ffffff;
  font-weight: 700;
  transition-delay: .1s;
}

#bis-award .title-award {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5em;
  margin-top: 10px;
  margin-bottom: 15px
}

#bis-award .awards-img {
  max-width: 40px;
}

#bis-award .awards-container {
  width: 60px;
  height: 60px;
  display: block;
  margin-right: auto;
  margin-left: auto;
  box-shadow: 0 0 10px 0 rgba(66, 66, 66, 0.4) !important;
  border-radius: 50%;
  padding: 10px;
  background-color: #fff;
}

#awards-search,
#awards-date,
#awards-category {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border: none;
}

#bis-award .form-group {
  display: flex;
  padding: 0 10px;
  margin-bottom: 10px;
}

#bis-award .award-search-icon,
.award-date-icon,
.award-category-icon {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: none;
  background-color: #fff;
  padding-left: 15px;
  color: #ced4da;
  outline: none !important;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

#bis-award .form-control:focus {
  box-shadow: none;
}

#bis-award .awards .check-icon svg {
  color: #1EC2F3;
}

#bis-award .awards:hover .check-icon svg {
  color: #ffffff;
}

@media only screen and (min-width: 981px) {
  #bis-award .title-award {
    min-height: 35px;
  }
}

/*#bis-award .awards .check-icon{
  visibility: hidden;
}

#bis-award .awards:hover .check-icon{
  visibility: visible;
}*/

#bis-award .awards .awards-booking {
  padding: 10px;
  font-size: 12px;
  margin-right: -20px;
  margin-left: -20px;
  /*border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;*/
  background-color: #f2f2f2;
  position: absolute;
  width: 100%;
  bottom: 0;
  transition: 0.5;
}

.check-icon {
  float: right;
}

.awards:hover .awards-booking {
  background-color: #212529 !important;
  transition-delay: .1s;
}

.awards-booking .categories {
  float: left;
}

.awards-booking .booked {
  text-align: right;
}

.awards-booking p {
  margin-bottom: 0;
  color: #888686;
}

.awards:hover .awards-booking p {
  color: #fff;
  transition-delay: .1s;
}

.awards .fit-awards-booking {
  margin-top: 40px;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

/* Bis Setup Award Page */
#bis_award_tab {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 48px;
  padding-bottom: 50px;
}

#bis_award_tab.page-container {
  padding-bottom: 100px;
}

#bis_award_tab h1 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 30px;
}

.bis_navigations .card {
  font-weight: 700;
  font-size: 17px;
  text-align: center;
  display: block;
  cursor: pointer;
  background: #fff;
  padding: 15px;
}

.bis_navigations .card:hover {
  color: #212529;
  background: #ebebeb;
}

.bis_navigations .active.card {
  color: #212529;
  background-color: #ebebeb;
  border: none;
}

#bis_award_tab .nav-tabs {
  border-bottom: none;
}

#bis_award_tab .nav-tabs .nav-item {
  max-width: 356px;
  width: 100%;
}

// .timer-container {
//   display: block !important;
//   width: 50% !important;
//   margin-bottom: 5px;
//   // margin-left: 120px;
//   margin-left: 250px;
// }

.timer-container {
  display: block !important;
  width: 50% !important;
  margin-bottom: 5px;
  margin-left: 0;
}

// .timer-container.big {
//   // width: 60% !important;
//   width: 60% !important;
// }

.timer-container.big {
  // width: 60% !important;
  width: 35% !important;
  margin-left: -28px;
}

.timer-container .list-inline-item {
  font-size: 40px;
  padding-top: 15px;
  height: 60px;
}

.timer-container .timer-text {
  font-size: 14px;
  margin-top: 13px;
  display: block;
}

.timer-container .entrant-award-subheader {
  font-size: 18px;
}

.entering-award .card {
  font-weight: 700;
  font-size: 15px;
  text-align: center;
  display: block;
  cursor: pointer;
  background: #ebebeb;
  padding: 15px;
  border: none;
}

// .entering-award .card:hover {
//   color: #fff;
//   background: #1ec2f3;
// }

.entering-award .active.card {
  color: #212529;
  background: #fff;
  border: 1px solid #DFDFDF;
}

.entering-award .nav-tabs .nav-item {
  max-width: 100% !important;
  width: 100%;
}

#bis_award_tab .bis_tab_content {
  padding-top: 0;
  padding-bottom: 50px;
}

#bis_award_tab .tab_btn_content {
  // text-align: left;
  margin-bottom: 0;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  // align-items: center;
  justify-content: center;
}

.award-sidebar h5 {
  font-size: 18px;
  font-weight: 600;
}

.award-sidebar img {
  width: 100%;
}

.table-loading {
  margin-bottom: -18px;
}

#bis_award_tab .tab_btn_content_image {
  // justify-content: normal !important;
  // align-items: normal !important;
  // display: block !important;
  justify-content: center !important;
  // align-items: center !important;
  display: flex !important;
  padding-top: 25px;
}

#bis_award_tab .tab_btn_container {
  align-items: center;
  margin-top: 28px;
}

.btn-main.sidebar-btn-alt {
  color: #212529 !important;
  border: 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 17px;
  text-align: center;
  display: block;
  cursor: pointer;
  background: #fff;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, .125);
  text-transform: uppercase;
}

.btn-main.sidebar-btn-alt.active {
  background: #ebebeb;
  border: 1px solid transparent;
}

.btn-main.sidebar-btn-alt:hover {
  color: #212529;
}

.btn.btn-main-alt {
  background: rgba(0, 0, 0, 0.125) !important;
  color: #212529 !important;
  border: 0;
  //text-transform: uppercase;
}

.btn-main-alt:hover,
.btn-main-alt:focus,
.btn-main-alt:active {
  background: rgba(0, 0, 0, 0.125) !important;
  color: #212529 !important;
  border: 0;
}

// #bis_award_tab .col-sm-3 {
//   padding-left: 8px;
//   padding-right: 8px;
// }

#bis_award_tab .tab_btn_content:hover {
  background: #1EC2F3;
  color: #ffffff;
}

#bis_award_tab .btn_text {
  font-size: 12px;
  margin-bottom: 0;
  text-transform: none;
}

#bis_award_tab .btn_img {
  margin-left: auto;
  margin-right: auto;
  display: block;
  max-height: 41px;
  min-height: 41px;
}

#bis_award_tab .bottom_container {
  align-items: center;
}

#bis_award_tab .judging_panel_container {
  padding: 30px 30px 10px 30px;
}

#bis_award_tab .judge_container {
  padding: 10px;
  margin-top: 110px;
  min-height: 386px;
}

#bis_award_tab .judge_container:hover {
  color: #ffffff;
  background: #1ec2f3;
}

#bis_award_tab .judge_container:hover .judge_desc {
  color: #ffffff;
}

#bis_award_tab .judge_image {
  margin-top: -80px;
  margin-bottom: 20px;
}

#bis_award_tab .judge_name {
  font-weight: 700;
  margin-bottom: 10px;
}

#bis_award_tab .judge_desc {
  color: #1ec2f3;
  font-size: 14px;
  margin-bottom: 10px;
}

#bis_award_tab .judge_content {
  font-size: 14px;
}

/*Bis Awards Single Page*/

#bis_award_tab .admin-event-information {
  display: flex;
  padding: 10px;
  flex-direction: row;
  justify-content: left;
  align-items: left;
}

// #bis_award_tab .informations{
//   padding-bottom: 30px;
// }

#bis_award_tab .attending-digital-event {
  margin-left: -8px !important;
  margin-right: -8px !important;
  padding-bottom: 0px;
}

#bis_award_tab .event-information p {
  padding-left: 10px;
  // margin-bottom: 0!important;
}

#bis_award_tab .event-information .tel-number {
  padding-left: 10px;
  color: #000;
}

#bis_award_tab h3 {
  font-weight: 700 !important;
  font-size: 17px;
}

.award-subheader {
  margin-bottom: 15px;
  font-size: 17px;
  font-weight: 700;
}

#bis_award_tab .Sponsors {
  padding-bottom: 50px;
}

#bis_award_tab .sponsor-name p {
  text-align: center;
}

// .sponsors-table td {
//   padding: 0;
// }

#bis_award_tab .sponsor-list {
  padding: 20px;
}

#bis_award_tab .sponsor-list:hover {
  background-color: #fff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.sponsor-image-container .sponsor-img {
  max-width: 120px;
}

.sponsor-name .gold-sponsors {
  padding-top: 10px;
}

.supporter-container .sponsor-img {
  max-width: 90px;
}

#bis_award_tab .irish-times p {
  margin-bottom: 25px;
}

.inperson-notice .card {
  font-size: 14px;
}

.guests-notice .notice-list {
  display: -webkit-inline-box;
  padding-right: 15px;
}

.guests-notice .notice-list p {
  padding-left: 10px;
}

#bis_award_tab .guests-instruction {
  font-size: 16px;
}


#bis_award_tab .notice-list svg,
#bis_award_tab .event-information svg,
#bis_award_tab .admin-event-information svg,
.event-information svg,
#bis_award_tab .itinerary-list svg,
.event-information-print-mgnt svg {
  color: #1ec2f3;
  // color: #277DC2;
}

// .award-sidebar .subheader svg {
//   color: #1ec2f3;
// }

#bis_award_tab .attending-live-event {
  padding-bottom: 50px;
}

.life-event-itinerary .itinerary {
  justify-content: center;
}

.life-event-itinerary .itinerary-list {
  font-size: 14px;
}

.itinerary-list p {
  margin-bottom: 0;
  margin-left: 10px;
  display: inline-table;
}

.life-event-itinerary .subject-change {
  font-size: 12px;
}

.notice-button button {
  background: #1ec2f3;
  color: #ffffff;
  text-decoration: none;
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
}

.notice-button button:focus {
  outline: none !important;
}

.attending-digital-event .guests-notice {
  font-size: 14px;
}

.digital-event-itinerary .itinerary {
  margin: 0;
  justify-content: center;
}

#bis_award_tab .itinerary-time {
  color: #1ec2f3;
  margin: 10px 0 15px 25px;
  display: block;
}

.itinerary-list .row {
  align-items: baseline;
}

.itinerary-list {
  display: contents;
  font-size: 14px;
}

.itinerary-list p {
  margin-bottom: 0;
  margin-left: 10px;
}

.digital-event-itinerary .subject-change {
  font-size: 14px;
}

.policies-procedures .card {
  padding: 20px;
}

#bis_award_tab .policies-procedures {
  padding-right: 25px;
  padding-left: 10px;
}

#bis_award_tab .policies-procedures p,
#bis_award_tab .policies-procedures li {
  font-size: 14px;
  line-height: 2;
}



/*Bis Single Award Page - Details*/

/*#bis_single_award h3{
  font-weight: 700!important;
  padding-bottom: 15px;
  font-size: 20px;
}*/

#bis_single_award {
  /*max-width: 1070px;*/
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /*padding-top: 50px;*/
  padding-bottom: 50px;
}

#bis_single_award h1 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 25px;
}

#bis_single_award a.nav-link {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  display: block;
  cursor: pointer;
  background: #fff;
  margin-left: 5px;
  margin-right: 5px;
  padding: 15px;
  box-shadow: 0px 0px 5px 0px #efefef;
  border-radius: 5px;
  border: none;
}

#bis_single_award a.nav-link:hover {
  color: #ffffff;
  background: #1EC2F3;
  border: none;
}

#bis_single_award a.active.nav-link {
  color: #ffffff;
  background: #1EC2F3;
  border: none;
}

#bis_single_award .nav-tabs {
  border-bottom: none;
}

#bis_single_award .nav-tabs .nav-item {
  width: 100%;
}

#bis_single_award .bis_tab_content {
  padding-top: 25px;
  padding-bottom: 50px;
}

#bis_single_award h2 {
  font-size: 25px;
}

#bis_single_award .details {
  padding-bottom: 50px !important;
}

#bis_single_award .HR-award {
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #efefef;
  padding: 25px 20px 20px;
  border-radius: 10px;
}

#bis_single_award .Entry {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px #efefef;
}

.Entry .Entry-container {
  justify-content: center;
  padding: 30px 20px;
}

.submit-entry-container .submit-entry,
.submit-judging {
  width: 100%;
  background: #1ec2f3;
  color: #ffffff;
  text-decoration: none;
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  display: block;
  text-align: center;
  padding: 0.375rem 0.75rem;
}

.divider {
  width: 80px;
  padding: 2px;
  background: #1ec2f3;
  margin-bottom: 20px;
}

.award-header {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 10px;
}

h2.award-header {
  font-size: 22px;
}

.btn-main {
  background: #1e2226;
  font-weight: 600;
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.btn-main:hover {
  color: #fff;
}

/*.Criteria .criteria-list {
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #efefef;
  border-radius: 10px;
}*/

.Criteria .criteria-description {
  font-size: 18px;
}

.criteria-list .criteria-alignment {
  display: -webkit-inline-box;
}

.criteria-list .checkcircle {
  padding-right: 10px;
  color: #1ec2f3;
}

.criteria-list .criteria-alignment p {
  margin-bottom: 10px;
}

.criteria-list .criteria-alignment .criteria-text {
  padding-right: 20px;
}


/*Bis Setup Award Page V2 - How to Enter*/

.how-to-enter .terms-conditions li {
  font-size: 14px;
  padding-bottom: 15px;
}

#bis_single_award .how-to-enter {
  padding-bottom: 50px;
}

.submitting-entry-container {
  padding-bottom: 50px;
}

// .submitting-entry-container .submitting-entry-info {
//     background-color: #fff;
//     box-shadow: 0px 0px 5px 0px #efefef;
//     padding: 40px 30px 20px 30px;
//     border-radius: 10px;
//     margin-left: -5px;
//     margin-right: -5px;
// }

.submitting-entry-container .submitting-entry-info h4 {
  font-size: 18px;
  font-weight: 700;
}

.submitting-entry-container .submitting-entry-info li,
.submitting-entry-container .submitting-entry-info i {
  font-size: 14px;
  padding-bottom: 10px;
}

.step-by-step-container .step-list {
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #efefef;
  text-align: -webkit-center;
  padding: 20px 15px 0;
  border-radius: 10px;
  margin-top: 100px;
  min-height: 350px;
}

.step-list .step-image {
  text-align: -webkit-center;
  margin-top: -90px;
}

.step-list .step-image p {
  font-size: 100px;
  font-weight: 700;
  color: #1EC2F3;
  border-radius: 50%;
  max-width: 150px;
  box-shadow: 0px 0px 5px 0px #efefef;
  background-color: #fff;
}

.step-list .step-name p {
  font-size: 16px;
  font-weight: 700;
  min-height: 50px;
}

.step-list .step-desc p,
.step-list .step-desc li {
  font-size: 14px;
  text-align: left;
}

.step-list .step-desc ul {
  padding-left: 15px;
  margin-bottom: 0 !important;
}

.step-list .step-desc li {
  padding-bottom: 10px;
}

/*Bis Single Award Page - Entry Guide PDF*/

#entry-row .alert hr {
  border-top-color: #857000;
}

.entry-guide-thank-you .thank-you-notice {
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 30px;
}

.entry-registration-form .entry-form-input {
  min-height: 50px;
  width: 100%;
  border-radius: 10px;
  border: none;
  margin-bottom: 20px;
  padding-left: 25px;
  box-shadow: 0px 0px 5px 0px #efefef;
}

.entry-registration-form .entry-registration-button {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.entry-registration-button .entry-button {
  color: #fff;
  background-color: #1EC2F3;
  font-weight: 700;
  border: none;
  border-radius: 10px;
  min-height: 50px;
  width: 100%;
  outline: none;
}

.entry-guide-thank-you .hr-leadership-image {
  border-radius: 50%;
  background-color: #fff;
  max-width: 300px;
  padding: 50px;
  margin-right: auto;
  margin-left: auto;
  box-shadow: 0px 0px 5px 0px #efefef;
}

.entry-registration-form .entry-contact-info {
  margin-top: 20px;
  padding-left: 20px;
}

.entry-registration-form .entry-contact-info a {
  color: #000;
  margin: 0 15px 0 5px;
  font-size: 15px;
}

.entry-registration-form .entry-contact-info a:hover {
  text-decoration: none;
}

.entry-registration-form .entry-contact-info svg {
  color: #1EC2F3;
}


/*Bis Award Payment Step 1*/

.step-progress--active {
  border: 5px solid #1EC2F3;
  color: #fff;
}

.stepPage .steps-container {
  margin-top: 20px;
}

// .stepPage .step-progress .icon_title {
//   font-weight: 700;
//   color: #000;
//   margin-top: 10px;
// }
.icon_title {
  font-weight: 700;
  color: #000;
  margin-top: 10px;
  font-size: 18px;
}

.stepPage .step-progress .number-steps {
  filter: brightness(0.5);
}

.stepPage .step-progress .steps_img_container1 .number-steps {
  padding: 14px 0px 0px 7px;
  filter: brightness(1);
}

.stepPage .step-progress .icon_steps {
  max-width: 48px;
}

.stepPage .step-progress .steps_img_container {
  padding: 11px 0px 0px 0px;
}

.steps-progress {
  display: inline-flex;
  align-items: center;
  padding: 0;
  margin: 0 auto;
  max-width: 670px;
  width: 100%;
  list-style: none;
  height: auto;
  background: none;
  margin-bottom: 50px;
  margin-top: 40px;
}

.steps-progress .number_steps_active {
  color: #fff !important;
}

.steps-progress .number_steps_active,
.steps-progress .number-steps {
  font-size: 50px;
  font-weight: 700;
}

.steps-progress .number-steps {
  color: #C3C3C3;
}

.steps-progress .first {
  color: #fff !important;
}

.progress_title1 {
  text-align: center;
  font-size: 20px;
  margin-left: -35px;
  position: absolute;
}

.progress_title2 {
  text-align: center;
  font-size: 20px;
  margin-left: -30px;
  position: absolute;
}

.progress_title3 {
  text-align: center;
  font-size: 20px;
  margin-left: -10px;
  position: absolute;
}

.progress_title4 {
  text-align: center;
  font-size: 20px;
  margin-left: -10px;
  position: absolute;
}

.stepPage .step-progress .icon_steps_active {
  padding: 11px 0px 0px 0px;
  filter: brightness(1) !important;
  max-width: 48px;
}

.progress__bar {
  flex: 1;
  height: 8px;
  background: #F2F2F2;
}

.entry-progress {
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 0;
}

.step-progress--done {
  background: #1EC2F3;
  color: #fff;
}

.step-progress--active {
  border: 5px solid #1EC2F3;
}

.stepPage {
  background-image: url("../assets/img/logo/entry-bg.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 50px;
  padding-bottom: 50px;
}

.stepPage .entry_steps_form {
  max-width: 900px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.stepPage .steps-container {
  margin-top: 20px;
}

.stepPage .step-progress .icon_title {
  font-weight: 700;
  color: #000;
  margin-top: 10px;
}

.stepPage .step-progress .number_steps {
  filter: brightness(0.5);
}

.stepPage .step-progress .steps_number_container1 .number_steps {
  padding: 14px 0px 0px 7px;
  filter: brightness(1);
}

.stepPage .step-progress .icon_steps {
  max-width: 48px;
}

.stepPage .step-progress .steps_number_container {
  padding: 11px 0px 0px 0px;
}

.stepPage .form-container {
  box-shadow: 0px 0px 12px 0px #c5c5c5;
  padding: 50px 30px;
  border-radius: 20px;
  margin-top: 30px;
  background-image: url("../assets/img/logo/form-bg.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.stepPage .step-progress .icon_steps_active {
  padding: 11px 0px 0px 0px;
  filter: brightness(1) !important;
  max-width: 48px;
}

#award-hero {
  background: #f5f5f5;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: -8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125)
}

#award-hero img {
  max-width: 100px;
  width: 100%;
}

#award-hero h2 {
  font-weight: 600;
  color: #1e2226;
  margin-top: 10px;
  font-size: 25px;
}

#booking-details {
  padding: 48px 15px;
}

.payment-steps-form form {
  width: 100%;
}

#booking-details .booking-info-step-1 {
  padding: 20px;
}

#booking-details .booking-info-step-1 input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #e6eafe;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(106, 130, 251, 0.4);
}

#booking-details h3 {
  font-size: 17px;
  font-weight: 700;
  color: #212529;
  line-height: 1.5;
}

#booking-details .booking-info-step-1 input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s;
}

#judge-assignment-table img.profile_table,
#coordinator-assignment-table img.profile_table {
  max-width: 60px !important;
}

#booking-details .total-price p {
  margin-bottom: 0;
  // font-size: 20px;
  font-weight: 700;
  text-align: right;
}

#booking-details .total-price p.small {
  font-size: 14px;
}

#booking-details .quantity {
  max-width: 70px;
}

#booking-details .price {
  text-align: right;
}

.performance-benchmark-chart {
  width: 100%;
}

// .all-winners {
//   width: auto;
//   max-height: 170px;;
// }

.all-winners {
  width: 100%;
}

.total-price.card {
  background: #efefef;
}

.total-price.info {
  background: #ccf4ff;
}

.irish-times-logo {
  width: 100%;
  // max-width: 150px;
  margin-top: 15px;
}

.performance-benchmark-container {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.performance-benchmark-container-lg {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
}

.hidden-attendee-id {
  opacity: 0 !important;
  margin-bottom: -40px;
}

.all-winners-container {
  max-width: 6 00px;
  margin-left: auto;
  margin-right: auto;
}

.irish-times-container {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.booking-button.text-left button {
  margin-right: auto;
  margin-left: 0;
}

.booking-button.text-right button {
  margin-right: 0;
  margin-left: auto;
}

.booking-button .form-button {
  background: #ffffff;
  color: #000;
  text-decoration: none;
  border: 1px solid #dfdfdf;
  border-radius: 0.25rem;
  display: block;
  max-width: 230px;
  text-align: center;
  padding: 0.375rem 0.75rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  font-weight: 600;
}

.booking-button .form-button:hover {
  color: #212529;
  background: #ebebeb;
}

.booking-button .form-button:focus {
  outline: none;
}


.booking-info-step-1 .booking-button {
  width: 100%;
}

#booking-details .radio_btn {
  margin-right: 15px;
  margin-left: 20px;
}

#booking-details .price_booking,
#booking-details .price_booking_discount {
  font-weight: 400;
  padding-left: 10px;
}

#booking-details .price_booking_discount {
  text-decoration: line-through;
  color: #808080;
}

.purchasing ::marker {
  color: #1EC2F3;
  font-size: 25px;
}

span.package_title {
  font-size: 18px;
}


/*Bis-Receipt*/

.bis-receipt-container .payment-receipt .col-lg-6 {
  display: inline;
}

.bis-receipt-container .payment-receipt tr {
  display: inline-grid;
  padding: 0px 30px;
}

.bis-receipt-container .receipt-table {
  padding-left: 15px;
  padding-right: 15px;
}

#bis_receipt .product-name {
  text-align: left;
}

#bis_receipt .table td {
  border: none !important;
}

#bis_receipt .pricing-container {
  width: 100%;
}

// .receipt-table p {
//   margin-bottom: 10px;
// }

.receipt-table hr {
  margin-top: 0;
}

#bis_receipt .btn-receipt {
  background: #1ec2f3;
  color: #ffffff;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  display: block;
  text-align: center;
  padding: 5px 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  float: right;
  outline: none;
  text-decoration: none !important;
}

.bis-receipt-container h3 {
  color: #1ec2f3;
  font-size: 20px;
  font-weight: 600;
}

#bis_receipt {
  padding: 50px 20px;
}

.bis-receipt-container svg {
  color: #fff;
  font-size: 55px;
  // margin-top: 15px;
  border-radius: 50%;
  border: 2px solid #1ec2f3;
  background: #1ec2f3;
  // margin-bottom: 15px;
}

#bis_receipt .card {
  // width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.preview .nav-tabs {
  border-bottom: none;
}

.main-header {
  font-size: 25px;
  font-weight: 700;
}

.preview .preview_logo {
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 20px;
}

.preview .award_col_title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.preview .award_entry_content {
  margin-bottom: 0px;
  // font-size: 14px;
  line-height: 1.8;
}

// .preview .award_entry_content:hover {
//   color: #1ec2f3;
// }


.preview .award_entry_btn {
  background: #1E2226;
  color: #fff;
  text-decoration: none;
  padding: 0px 5px;
  margin-left: 10px;
  float: right;
}

.preview .table {
  margin-bottom: 0;
}

.total {
  font-weight: 700 !important;
  font-size: 17px !important;
}

.preview .form-control {
  font-size: 14px;
}

.form-invalid {
  border-color: #dc3545 !important;
}

.form-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
}


.preview .form-btn {
  background: #ffffff;
  color: #000;
  text-decoration: none;
  padding: 15px;
  border: 1px solid #dfdfdf;
  border-radius: 0.25rem;
  display: block;
  max-width: 230px;
  text-align: center;
  padding: 0.375rem 0.75rem;
  font-weight: 600;
}

.preview .form-btn:hover {
  color: #212529;
  background: #ebebeb;
}

.preview .form-btn:focus {
  outline: none;
}

.preview .left_btn {
  margin-left: auto;
  width: 100%;
}

.invalid-feedback {
  display: block;
}

.preview .right_btn {
  margin-right: auto;
}

.thank_you_heading {
  font-size: 35px;
  font-weight: 600;
}

.profile_btn .btn-secondary {
  width: 100%;
  margin-top: 15px;
}

.profile_btn a {
  text-decoration: none !important;
}

.preview p.profile_number {
  color: #1e2226;
  font-size: 30px;
  line-height: .8;
  font-weight: 700;
}

.preview img.profile_table {
  max-width: 90px;
  width: 100%;
  margin-bottom: 0;
}

.preview .book_svg {
  color: #fd88ff;
  width: 50px;
  height: 50px;
}

.preview .entry_svg {
  color: #6564cb;
  width: 50px;
  height: 50px;
}

.preview .icons_row {
  align-items: center;
}

.preview .award-search-icon {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: none;
  background-color: #fff;
  padding-left: 15px;
  color: #ced4da;
  outline: none !important;
}

.preview .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.preview .award-search-container {
  display: flex;
  padding: 0 10px;
  margin-bottom: 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.preview .form-control:focus {
  box-shadow: none !important;
}

.admin_award_table th {
  border: none;
}

.preview .book_svg_single {
  color: #1ec2f3;
  width: 50px;
  height: 50px;
}

.preview .entry_svg_single {
  color: #1ec2f3;
  width: 50px;
  height: 50px;
}

.preview .categories_svg_single {
  color: #1ec2f3;
  width: 50px;
  height: 50px;
}

.remove-icon:hover,
.edit-icon:hover {
  cursor: pointer;
}

.modal.show {
  display: block !important;
  background: rgba(0, 0, 0, 0.5);
  // position: absolute;
}

.absolute {
  position: absolute !important;
}

.list-group-flush .list-group-item:focus {
  outline: none !important;
}

.fixed {
  position: fixed !important;
}

.react-modal-close {
  margin: 0 !important;
  padding-top: 10px !important;
}

.text-left .modal-title {
  text-align: left;
}

.preview img.judge_img {
  max-width: 42px;
}

.preview .judge_container {
  align-items: center;
}

.preview .gray_bg.card {
  background: #1E2226;
}

.preview .users_svg {
  color: #ffffff;
  width: 50px;
  height: 50px;
}

.preview .judge_svg {
  color: #767676;
  width: 50px;
  height: 50px;
}

.preview .sponsors_svg {
  color: #4fb9b9;
  width: 50px;
  height: 50px;
}

.stage-box {
  width: 200px;
  height: 100px;
  margin-top: 10px;
  border-radius: 0;
  // border: none !important;
  background: #222;
  color: #fff;
  // border-radius: 5px;
  //border: 5px solid rgb(56, 93, 138);
  position: absolute;
  top: 0;
  left: 0;
}

.venue-stage-box {
  width: 200px;
  height: 100px;
  margin-top: 10px;
  border-radius: 0;
  // border: none !important;
  background: #dadedf;
  color: #000;
  // border-radius: 5px;
  //border: 5px solid rgb(56, 93, 138);
  position: absolute;
  top: 0;
  left: 0;
}

.table-box {
  width: 100px;
  height: 100px;
  margin-top: 10px;
  border-radius: 100%;
  border: none !important;
  background: #ebebeb;
  color: #000;
}

.stage-box h2 {
  margin-top: 30px;
}

.preview img.img_table {
  max-width: 40px;
  width: 100%;
}

.user_award_table th {
  font-size: 16px !important;
}

.action_table {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.action_table .action_content {
  visibility: hidden;
  // width: 94px;
  width: 200px;
  padding: 0;
  right: 15px;
  top: 5px;
  position: absolute;
  z-index: 1;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.39);

}

.action_content_large {
  width: 250px !important;
}

.action_table:hover .action_content {
  visibility: visible;
}

.action_table .action_container {
  padding: 0px !important;
}

.action_container a {
  text-decoration: none;
  color: #212529;
  font-size: 14px;
  font-weight: 600;
  // padding-left: 5px;
  padding-left: 12px;
  display: inline-block;
  width: 115px;
}

.action_container .link_container {
  // padding: 3px 10px;
  padding: 10px 30px;
  text-align: left !important;
}

.action_container .link_container:hover {
  background: #ebebeb;
}

.modal-preview .modal-body {
  padding: 0px;
}

.modal-preview label {
  color: inherit;
  font-weight: 500;
  text-transform: none;
}

.modal-preview .form-check-inline {
  display: grid;
  margin-right: 0;
}

.modal-preview .card-body {
  padding: 10px 15px;
}

.modal-preview p,
.preview p {
  margin-bottom: 0;
}

.modal-preview img.img_table {
  max-width: 40px;
  width: 100%;
}

.modal-preview .card_container.card-body {
  padding: 20px;
}

.table-card {
  position: absolute;
  left: 0;
  right: 0;
}

.bis-tooltip-table {
  cursor: pointer;
}

.bis-tooltip-attendee {
  position: relative;
  display: inline-block;
  //cursor: pointer;
}

.bis-tooltip.no-bg {
  background: transparent;
  border: 0px;
}

.bis-tooltip-table .bis-tooltiptext {
  visibility: hidden;
  width: 100%;
  top: -20px;
  left: 0;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer !important;
}

.bis-tooltip-table .bis-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.bis-tooltip-attendee .bis-tooltiptext {
  visibility: hidden;
  width: 130px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 105%;
  left: 50%;
  margin-left: -65px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.bis-tooltip-attendee .bis-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.bis-tooltip-table:hover .bis-tooltiptext {
  visibility: visible;
}

.bis-tooltip-attendee:hover .bis-tooltiptext {
  visibility: visible;
}

.modal-preview .seat_container.card-body {
  padding: 0;
  display: flex;
  //height: 1000px;
  flex-wrap: wrap;
  align-content: flex-start;
  // margin: 10px;
  //border: 5px solid #4f81bd;
  width: 100%;
  position: relative;
}

.page-container {
  position: relative;
  min-height: 110vh;
}

.footer {
  // position: absolute;
  // bottom: 0;
  // width: 100%;
  position: absolute;
  bottom: 0;
  width: 100%;
  // height: 2.5rem;
}

.BookingsPage .table th {
  border-top: none;
}

.BookingsContainer.row {
  display: block;
}

.BookingsContainer .table-hover tbody tr:hover {
  color: inherit !important;
  background-color: transparent !important;
}

.action_container hr {
  margin: 0;
}

.dashboard-p {
  font-size: 14px;
}

.action_table .admin-content {
  visibility: hidden;
  // width: 113px;
  padding: 0;
  right: 15px;
  top: 5px;
  position: absolute;
  z-index: 1;
}

.table-responsive {
  overflow: hidden !important;
}

.VEPcardbackground {
  background-image: url('../assets/img/logo/VEP_.png');
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;

}

.payment-success-title {
  font-size: 25px !important
}

.VEPbackground {
  background-image: url('../assets/img/logo/Component 125 – 1.png');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 20px;
  padding-right: 20px;
}

// my addition below

.cardbgcolor {
  background-color: #fff;
  color: #000;
  border-bottom: 1px solid #f8f9fa;
}

.useravatar {
  padding: 0.5px;
}

.useravatarborder:hover {
  box-shadow: 0px 0px 5px 0px #1ec2f3;
}

.profilename {
  font-size: 15px;
  font-weight: 800;
}

.profilesubtitle {
  font-size: 12px;
  font-weight: 500;
}

/* #VEPcontainer{
  margin-top:10%;
  margin-bottom: 10%;
} */

/* #VEPform{
  background-color: white;
} */


/* New Css */
.category_checkbox {
  height: 20px;
  width: 20px;
  margin-top: 5px;
}

.paymentPage .trophy-img {
  // max-width: 170px;
  width: 100%;
  margin: 0px auto 0 auto;
  display: block;
}

// .table_checkbox {
//   border: 1px solid #DEE2E6;
// }


/* Judge Category Stylings */

// #judge-category .progress-group {
//   display: flex;
//   column-gap: 10px;
//   justify-content: center;
// }


#judge-category .progress-group h4 {
  font-weight: 700;
  font-size: 18px;
}

#judge-category .progress-group p {
  font-size: 16px;
  letter-spacing: 1px;
}

#judge-category .judge-category-header {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 700;
}

#judge-category .view-span {
  font-weight: 600;
  padding-left: 12px;
}


#coordinator-category .progress-group h4 {
  font-weight: 700;
  font-size: 18px;
}

#coordinator-category .progress-group p {
  font-size: 16px;
  letter-spacing: 1px;
}

#coordinator-category .coordinator-category-header {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 700;
}

#coordinator-category .view-span {
  font-weight: 600;
  padding-left: 12px;
}

.description-card p:last-child {
  margin-bottom: 0 !important;
}

.category-spot-checking-modal .remarks-textarea {
  width: 100%;
  padding: 10px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.category-spot-checking-modal.modal-preview .card-body {
  padding: 1.25rem !important;
}

.category-spot-checking-modal .remarks-textarea:focus {
  box-shadow: none;
  outline: none;
}

.category-spot-checking-modal .main-header {
  font-size: 20px;
  font-weight: 600;
  color: #212529;
  letter-spacing: 1px;
}

.category-spot-checking-modal .sub-header {
  font-size: 17px;
  font-weight: 600;
  color: #212529;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.category-spot-checking-modal .contents {
  font-size: 16px;
  color: #212529;
  margin-bottom: 10px;
}

.category-spot-checking-modal {
  max-width: 1200px !important;
}

.category-spot-checking-modal .award-logo {
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  display: block;
  width: 100%;
}

.category-spot-checking-modal .modal-group {
  display: flex;
  justify-content: center;
}

.category-spot-checking-modal .bottom-border {
  border-bottom: 1px solid #dee2e6;
}

.category-spot-checking-modal .preview_logo {
  width: 100%;
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 20px;
}

/*  judge assignment status color */
.judgeassignmentssvgcolor,
.coordinatorassignmentssvgcolor {
  background-color: rgb(120, 230, 120);
  margin: 2px;
  border-radius: 50%;
  z-index: 1;
  position: absolute;
}

.entry-scored {
  background-color: rgb(120, 230, 120);
  border-radius: 50%;
  margin-top: -5px;
}

.entry-unscored {
  background-color: #212529;
  color: #fff;
  border-radius: 50%;
  margin-top: -5px;
}

/*  New button color    */
.newbtntheme {
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: 600;
}

.newbtntheme:hover {
  color: #212529;
  background: #ebebeb;
}

.newbtntheme:focus {
  outline: none;
}

.newbtntheme-main {
  background: #1e2226;
  font-weight: 600;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 0.25rem;
}

.newbtntheme-main:hover {
  color: #212529;
  background: #ebebeb;
}

.newbtntheme-main:focus {
  outline: none;
}

.preview_content .preview_widget {
  padding-top: 10px;
  padding-bottom: 5px;
}

.preview_content .user_table {
  table-layout: fixed;
}

.table-layout-fixed {
  table-layout: fixed;
}

/* Invoice */
.receipt-icon {
  color: #1ec2f3;
  width: 50px;
  height: 50px;
}

.profile_number_invoices {
  color: #1e2226;
  font-size: 30px;
  line-height: .8;
  font-weight: 700;
}

.invoices_head_modal,
.invoice_info,
.tax_data {
  font-weight: 600;
}

.invoice_table_head {
  background: #1EC2F3;
  color: #fff;
}

.invoice_award_table th {
  border: none;
}

.invoice_header,
.invoice_modal_footer {
  background: #1EC2F3;
  border: 0;
}

.invoice_header h5 {
  color: #fff;
}

.shift_category_popup {
  width: 213px !important;
}

.shift_category_popup a {
  display: initial;
}

.category-prioritization-coordinator {
  position: absolute;
  right: 15px;
  top: 15px;
}

.admin-checklist-select select {
  max-width: 125px;
  display: inline-block !important;
  margin-left: 10px;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.icon.danger_status {
  background-color: rgb(255, 65, 65);
  margin: 2px;
  border-radius: 50%;
  z-index: 1;
  position: relative;
  // position: absolute;
  // cursor: pointer;
}

.icon.success_status {
  background-color: rgb(120, 230, 120);
  margin: 2px;
  border-radius: 50%;
  z-index: 1;
  position: relative;
  // cursor: pointer;
}

.icon.pending_status {
  background-color: rgb(255, 190, 70);
  margin: 2px;
  border-radius: 50%;
  z-index: 1;
  position: relative;
  // position: absolute;
  // cursor: pointer;
}

.conflict_status {
  background-color: rgb(255, 65, 65);
  margin: 2px;
  border-radius: 50%;
  z-index: 1;
  position: absolute;
  cursor: pointer;
}

.pending_status {
  background-color: rgb(255, 190, 70);
  margin: 2px;
  border-radius: 50%;
  z-index: 1;
  position: absolute;
}

.approved_status {
  background-color: rgb(120, 230, 120);
  margin: 2px;
  border-radius: 50%;
  z-index: 1;
  position: absolute;
}

.right-column {
  width: 120px;
}

.change-image-container {
  position: relative;
}

.change-photo {
  border-radius: 50%;
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}

.change-image-container:hover .change-photo {
  opacity: 0.8;
  box-shadow: 0px 0px 10px 0px #1ec2f3;
}

.notifications-popup.popover {
  // top: 75px !important;
  min-width: 400px !important;
  // transform: none !important;
  // right: 30px !important;
  left: 130px !important;
  max-height: 300px;
  overflow-y: scroll;
  box-shadow: none;
}

.chat-notifications-popup.popover {
  min-width: 380px !important;
  left: 130px !important;
  max-height: 300px;
  overflow-y: scroll;
  box-shadow: none;
}

.notification-single {
  cursor: pointer;
}

.logout-btn {
  background: #ef8484 !important;
  color: #fff !important;
  border-color: #ef8484 !important;
}

// Tooltip

.allocation-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  // border-bottom: 1px dotted black;
}

.allocation-tooltip .allocation-tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}

.allocation-tooltip .reallocation-tooltiptext.allocation-tooltiptext {
  width: 320px;
  left: -270%;
}

.allocation-tooltip .allocation-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.allocation-tooltip:hover .allocation-tooltiptext {
  visibility: visible;
}

.conflict {
  position: relative;
  // display: inline-block;
  // border-bottom: 1px dotted black;
}

.conflict .conflicttext {
  visibility: hidden;
  width: 180px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 10px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  font-size: 15px;
  margin-left: -85px;
  margin-bottom: 10px;
  transition: 500ms;
}

.bis-tooltip.table-tooltip {
  background: transparent;
  border: 0;
  padding: 0;
  font-weight: 400;
}

.bis-tooltip.table-tooltip .bis-tooltiptext {
  width: 250px;
  left: -20px;
  bottom: 105%;
}

.bis-tooltip {
  position: relative;
  display: inline-block;
  font-weight: 600;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 0.25rem;
  cursor: pointer;
  // border-bottom: 1px dotted black;
}

.bis-tooltip-gallery {
  position: relative;
  display: inline-block;
}

.bis-tooltip-gallery .tooltiptext {
  visibility: hidden;
  position: absolute;
  width: 235px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px;
  font-size: 12px;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity .6s;
}

.tooltip-top {
  bottom: 29%;
  left: 20%;
  margin-left: -60px;
}

.tooltip-top::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.bis-tooltip-gallery:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.bis-tooltip .bis-tooltiptext {
  visibility: hidden;
  width: 130px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -65px;
  font-weight: 600;
  font-size: 14px;
}

.bis-tooltip .bis-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.bis-tooltip:hover .bis-tooltiptext {
  visibility: visible;
}

.bis-tooltip-progress {
  position: relative;
  cursor: pointer;
  // border-bottom: 1px dotted black;
}

.bis-tooltip-progress .bis-tooltip-progresstext {
  visibility: hidden;
  width: 130px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 52%;
  margin-left: -65px;
  font-weight: 600;
  font-size: 14px;
}

.bis-tooltip-progress .bis-tooltip-progresstext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.bis-tooltip-progress:hover .bis-tooltip-progresstext {
  visibility: visible;
}

.bis-tooltip-newcoor {
  position: relative;
  display: inline-block;
  font-weight: 600;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 0.25rem;
  cursor: pointer;
  // border-bottom: 1px dotted black;
}

.bis-tooltip-newcoor .bis-tooltiptext-newcoor {
  visibility: hidden;
  width: 155px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -65px;
  font-weight: 600;
  font-size: 14px;
}

.bis-tooltip-newcoor .bis-tooltiptext-newcoor::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.bis-tooltip-newcoor:hover .bis-tooltiptext-newcoor {
  visibility: visible;
}

.allocation-name-tooltip {
  position: relative;
  display: inline-block;
}

.panel-percentage {
  // margin-top: 7px;
  margin-bottom: 20px !important;
}

.panel-percentage .badge {
  font-size: 14px;
  padding: 7px 10px;
  font-weight: 400;
}

.allocation-name-tooltip .allocation-name-tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 15px;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 50%;
  margin-left: -200px;
}

.allocation-name-tooltip .allocation-name-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.allocation-name-tooltip:hover .allocation-name-tooltiptext {
  visibility: visible;
}

/*  for table links */
a {
  color: #1e2226;
}

// a:link {
//   text-decoration: none;
// }

a:visited {
  text-decoration: none;
}

a:hover {
  // text-decoration: none;
  color: #1e2226;
}

// a:active {
//   text-decoration: none;
// }

.conflict .conflicttext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.conflict:hover .conflicttext {
  visibility: visible;
}

/* New Admins Modal*/
.admins-modal {
  max-width: 900px !important;
  width: 100%;
}

.admins_svg {
  color: #8C8888;
  width: 50px;
  height: 50px;
}

// Notification
.notification-list-unread {
  border-left: 2px solid #29B6F6;
  // height: 115px;
  width: auto;
  background-color: #ffffff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
}

.notification-list {
  // height: 115px;
  width: auto;
  background-color: #ffffff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
}

.notification-img {
  height: 48px;
  width: 48px;
  border-radius: 5px;
}

.font-style {
  font-family: Poppins, Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 13px;
}

.details-img_table {
  border: 1px solid #DEE2E6 !important;
  padding: 5px !important;
}

.forgot-password-card {
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.forgot-password-question {
  font-size: 28px;
  font-weight: 700;
  color: #1ec2f3;
}

.forgot-password-button {
  background: #1EC2F3 !important;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
}

.button-icon {
  max-width: 17px;
  margin-bottom: 3px;
  margin-right: 10px;
}

.award-button-icon {
  max-width: 35px;
  margin-bottom: 25px;
  margin-top: 15px;
}

.award-button-text {
  font-size: 20px;
}

// .award-apply-now {
//   background: #fbebb1;
//   border: none;
// }

.talk-to-us {
  color: #1EC2F3 !important;
  text-decoration: none;
}

.sponsors-logo {
  margin: 0 auto;
  width: 100%;
  max-width: 80px;
  display: block;
}

.sponsors-heading {
  text-align: center;
  color: #1ec2f3;
  margin-bottom: 20px;
}

.sponsors-card {
  height: 170px;
}

.irish-times-sponsor-logo {
  max-width: 120px !important;
  text-align: right !important;
  display: inline;
}

.award-buttons:hover {
  background: #1EC2F3;
  color: #fff;
}

.award-buttons:hover .award-button-icon {
  filter: brightness(0) invert(1);
}

.submit-entry-btn {
  background: #1ec2f3;
  color: #ffffff;
  text-decoration: none;
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  text-align: center;
  padding: 0.375rem 0.75rem;
}

.get-most-btn {
  background: #1ec2f3;
  color: #ffffff;
  text-decoration: none;
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  text-align: center;
  padding: 0.375rem 0.75rem;
  width: 345px;
}

.content-item {
  list-style: none;
  padding-left: 15px;
}

.content-item .content-item-list::before {
  content: "\2022";
  color: #FBD43B;
  font-weight: bold;
  display: inline-block;
  padding-right: 5px;
  // width: 1em;
}

#bis_award_tab .header-text {
  font-weight: 600 !important;
  font-size: 20px;
}

.list-icon {
  display: block;
  width: 100%;
  margin-left: auto;
  max-width: 70px;
  margin-right: auto;
  margin-bottom: 15px;
}

.checklist-item {
  // padding-left: 20px;
  list-style: none;
  display: flex;
  align-items: start;
  column-gap: 5px;
}

.check-icon {
  margin-top: 5px;
}

.blue-box {
  background-image: linear-gradient(#62CAF2, #1ec2f3);
  border: none;
  text-align: center;
  border-radius: 25px;
  color: #fff;
}

.blue-box-content {
  font-weight: 600;
  font-size: 30px;
  line-height: 1.3;
  max-width: 223px;
  margin: 0 auto;
}

.blue-box-content-two {
  font-weight: 600;
  font-size: 30px;
  line-height: 1.3;
  max-width: 280px;
  margin: 0 auto;
}

.blue-box-number {
  color: #1ec2f3;
  font-weight: 700;
  font-size: 30px;
}

.white-circle-bg {
  background: #fff;
  border-radius: 50%;
  max-width: 46px;
  margin: 0 auto;
}

.steps-image-icon {
  max-width: 60px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
  margin-bottom: 15px;
}

.arrow-down-button {
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  max-width: 45px;
  margin-top: 30px;
}

.view-all-btn {
  background: #1ec2f3;
  border: none;
  padding: 10px 100px;
  color: #fff;
  border-radius: 5px;
}

.info-award-header {
  background: #1ec2f3;
  color: #fff;
}

.flex-header-item {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
}

.info-header-icon {
  max-width: 35px;
  width: 100%;
}

.event-info-headtext {
  color: #1ec2f3;
  font-weight: 700;
}

.check-icon-event {
  width: 100%;
  max-width: 28px;
  margin-right: 10px;
}

.info-blue-box {
  background: #1ec2f3;
  border: none;
  border-radius: 10px;
  color: #fff;
  padding: 0px 15px;
}

.left-border {
  border-left: 4px solid #FBD43B;
  padding-left: 15px;
}

.form-card {
  background: #FAFAFA;
  border: none;
  margin-bottom: 15px;
}

.form-input {
  padding: 5px;
  border: 1px solid #C5C5C5;
  border-radius: 5px;
  width: 100%;
  outline: none;
}

.details-blue-card {
  background: #1ec2f3;
  border: none;
  color: #fff;
}

.details-blue-icon {
  width: 100%;
  max-width: 65px;
  margin: 0 auto;
  display: block;
}

.check-blue-icon {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.blue-left-border {
  border-left: 4px solid #1ec2f3;
}

.yellow-left-border {
  border-left: 4px solid #FFB400;
}

.profile_logo {
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
}

.profile-pencil svg {
  font-size: 14px;
  margin-top: 4px;
  margin-left: 10px;
}

.caution-icon {
  width: 25px;
  height: 24px;
  margin-right: 15px;
}

.white-bg-circle {
  position: absolute;
  bottom: 3px;
  left: 65px;
  right: 0;
  padding: 7px;
  background: #fff;
  max-width: 40px;
  border-radius: 50%;
  border: 1px solid #DEE2E6;
  cursor: pointer;
}

.white-bg-circle svg {
  font-size: 18px;
  margin-left: 3px;
}

.profile-blue-icon {
  width: 100%;
  max-width: 40px;
}

.profile-left-icon {
  width: 100%;
  max-width: 24px;
  margin-right: 7px;
  height: 24px;
}

.profile-left-btn:hover {
  background: #1ec2f3;
  color: #fff;
}

.payment-left-btn {
  background: #1ec2f3;
  color: #fff;
}

.payment-left-btn:hover {
  background: #fff;
  color: #000;
}

.profile-left-btn:hover .profile-left-icon {
  filter: invert(100%);
}

.profile-left-btn:hover svg {
  color: #fff;
}

.progress-bar-percentage {
  height: 10px;
  background: #05CC33;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  font-size: 10px;
  // padding-top: 2px;
  overflow: hidden;
}

.blue-line-horizontal {
  max-width: 4px;
  width: 100%;
  height: 100%;
  max-height: 77px;
  background: #1ec2f3;
  margin: 0 auto;
}

.blue-line-horizontal-second {
  max-width: 4px;
  width: 100%;
  height: 100%;
  max-height: 48px;
  background: #1ec2f3;
  margin: 0 auto;
}

.header-text {
  font-weight: 600 !important;
  font-size: 20px;
}

.circle-check-notify {
  border: 1px solid #1ec2f3;
  padding: 5px 1px;
  border-radius: 50%;
  max-width: 35px;
  margin: 0 auto;
}

.blue-highlight-text {
  padding: 3px 5px;
  background: #51D8FF;
  max-width: 183px;
  margin-top: 3px;
  border-radius: 5px;
  color: #fff;
}

.circle-caution-notify {
  border: 1px solid #1ec2f3;
  padding: 6px 5px;
  border-radius: 50%;
  max-width: 35px;
  margin: 0 auto;
}

.disabled-btn {
  cursor: not-allowed !important;
}

.terms-condition-btn {
  background: #C9C9C9;
  color: #fff;
}

.terms-condition-btn .profile-left-icon {
  filter: invert(100%);
}

.terms-condition-book {
  background: #C9C9C9;
  color: #ffffff;
  text-decoration: none;
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  cursor: not-allowed !important;
}

.refer-judge-btn {
  padding: 5px 20px !important;
}

.active .profile-left-btn {
  background: #1ec2f3;
  color: #fff;
}

.active .entrant-left-icon {
  filter: brightness(100) invert(0);
}

.active .profile-left-btn .profile-left-icon {
  filter: invert(100%);
}

.active.profile-left-btn {
  background: #1ec2f3;
  color: #fff;
}

.active.profile-left-btn .profile-left-icon {
  filter: invert(100%);
}

.blue-col-modal {
  background: #1ec2f3;
  border-radius: 5px 0px 0px 5px;
}

.image-white-bg {
  background: #fff;
  padding: 25px;
  max-width: 130px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 10px;
}

.divider-modal {
  margin-top: 7px;
  margin-bottom: 7px;
  border-width: 1px;
}

.checklist-list p {
  font-size: 0.8em;
}

.wrap-description {
  white-space: pre-wrap;
}

.profile-info .profile-data {
  padding: 0 !important;
  border: 0 !important;
  outline: none;
  font-weight: 700;
  display: inline-block;
}

.profile-info .profile-data:hover {
  text-decoration: underline;
}

#bis-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.entry-data .profile-data {
  width: 170px;
  margin-left: 31px;
  padding: 0;
  outline: none;
  border: 0;
  // font-weight: 700;
  margin-bottom: 20px;
}

.image-column .circle-white-bg {
  position: absolute;
  bottom: -10px;
  right: -5px;
  padding: 8px;
  background: #fff;
  max-width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #DEE2E6;
  cursor: pointer;
}

.image-column .circle-white-bg svg {
  font-size: 13px;
  margin-bottom: 8px;
}

.checklist-icon {
  width: 100%;
  max-width: 20px;
  margin-right: 10px;
  max-height: 20px;
}

.checklist {
  display: flex;
}

#Popover1 small {
  color: #fff !important;
}

.history .items {
  padding-left: 18px;
}

.history .items .items-list {
  margin-bottom: 10px;
}

// .history {
//   min-height: 305px;
// }

.message-date {
  color: #B9B9B9;
  font-size: 15px;
  background: #fff;
  margin-top: -28px;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.ticket-description,
.award-description {
  white-space: pre-line;
}

.comment-receive {
  width: fit-content !important;
  max-width: 230px !important;
  min-width: 230px !important;
  margin-bottom: 10px;
}

.comment-send {
  width: fit-content !important;
  max-width: 230px !important;
  min-width: 230px !important;
  background: #DEF7FF;
  border: 1px solid #BBECFB;
  margin-left: auto;
  margin-bottom: 10px;
}

.comment-send-name {
  max-width: 230px !important;
  float: right;
  width: 100%;
  font-size: 12px;
  margin-bottom: -14px !important;
  position: absolute;
  bottom: -10px;
  left: 0;
  text-align: right;
}

.comment-receive-name {
  max-width: 230px !important;
  float: right;
  width: 100%;
  font-size: 12px;
  margin-bottom: -14px !important;
  position: absolute;
  bottom: -10px;
  right: 0;
}

.user-icon-image {
  margin-left: auto;
  width: 100%;
  max-width: 35px;
  margin-right: auto;
  display: block;
  margin-bottom: 10px;
}

.history .comment-scroll {
  overflow-y: scroll;
  height: 400px;
  overflow-x: hidden
}

.judging-panel-name {
  color: #1ec2f3;
}

.judging-panel:hover .judging-panel-name {
  color: #fff;
}

// .judge-panel-img {
//   position: absolute;
//   border-radius: 50%;
//   border: 1px solid #ffffff;
//   left: 50px;
//   top: -70px;
// }

.judge-panel-img {
  /* position: absolute; */
  border-radius: 50%;
  border: 1px solid #ffffff;
  /* left: 50px; */
  /* top: -70px; */
  margin-top: -100px;
  position: relative;
  margin-bottom: 20px;
}

.entrant-judge-panel-img {
  border-radius: 50%;
  border: 1px solid #ffffff;
  margin-top: -100px;
  position: relative;
  margin-bottom: 20px;
  width: 150px;
  height: 150px;
  background-position: center;
  background-size: cover;
  margin-left: auto;
  margin-right: auto;
}

.judging-panel {
  background-color: #EDEDED;
}

.judging-panel:hover {
  color: #ffffff;
  background-color: #1ec2f3;
}

.entrant-award-subheader {
  color: #1ec2f3;
  font-size: 21px;
  font-weight: 700;
}

.entrant-entries {
  font-size: 70px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.entrant-icon {
  width: 60px;
  height: 60px;
}

.entrant-widget {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.entrant-category-check {
  position: absolute;
  z-index: 1;
  color: #05CC33;
  top: 8px;
  right: 22px;
}

// .text-secondary:hover .entrant-category-check{
//   color: #ffffff;
// }

#bis_award_tab .category-link:hover .entrant-category-check {
  color: #ffffff;
}

.slick-next {
  right: 0px !important;
}

.slick-prev {
  left: 0px !important;
}

.sliding-category svg {
  width: 30px !important;
  height: 30px !important;
  color: #fff !important;
  background: #000 !important;
  padding: 5px !important;
  border-radius: 50%;
}

.sliding-category svg:hover {
  background: #000 !important;
  opacity: 0.4;
}

.category-slider {
  max-width: 240px;
  margin: 0 auto;
  display: block !important;
}

.category-slider-card:hover {
  color: #ffffff;
  background-color: #1ec2f3;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0 auto !important;
  padding: 0;
  max-width: 750px;
}

.list-inline-item {
  background-image: url(../assets/img/icons/timer-bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 110px;
  height: 95px;
  font-family: 'DS-Digital', sans-serif;
  font-size: 60px;
  color: #fff;
  line-height: 0.4;
  font-weight: 600;
  border-radius: 10px;
  padding-top: 25px;
  align-items: center;
}

.list-inline-item {
  background-image: url(../assets/img/icons/timer-bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 110px;
  height: 95px;
  font-family: 'DS-Digital', sans-serif;
  font-size: 60px;
  color: #fff;
  line-height: 0.4;
  font-weight: 600;
  border-radius: 10px;
  padding-top: 25px;
  align-items: center;
}

.timer-text {
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  font-family: Poppins;
}

.list-inline-item-home {
  background-image: url(../assets/img/icons/timer-bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 220px;
  height: 95px;
  font-family: 'DS-Digital', sans-serif;
  font-size: 60px;
  color: #fff;
  line-height: 0.4;
  font-weight: 600;
  border-radius: 10px;
  padding-top: 25px;
  align-items: center;
  display: inline-block;
  margin: 0px 20px;
}

.card-grey {
  background: #F2F2F2;
}

#bis_award_tab .tab_btn_container {
  margin-top: 0px !important;
}

.custom-select {
  background: #fff;
  border-radius: 5px;
  color: #000;
  height: 40px;
  padding: 10px;
  width: 100%;
  border: 1px solid #D4D4D4;
}

#custom-select-content {
  float: left;
  margin-top: -2px;
}

#custom-select-icon {
  float: right;
}

#custom-select-icon svg {
  margin-bottom: 5px;
}

.custom-select-options {
  background: #fff;
  border-radius: 5px;
  padding: 20px 10px;
  position: absolute;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  transition: .5s;
  width: 100%;
  z-index: 5;
  border: 1px solid #D4D4D4;
  margin-top: -10px;
  box-shadow: 0px 1px 4px 1px #d4d4d4;
  overflow-y: scroll;
  max-height: 400px;
}

.custom-select-options ul {
  list-style: none;
  padding-left: 25px;
  margin-bottom: 0;
}

.custom-select-options ul li {
  margin-bottom: 10px;
  line-height: 1.3;
}

.custom-select-options [type='checkbox'] {
  margin-top: 3px;
}

.header-social-image {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
  max-width: 45px;
}

.header-social-total {
  text-align: center;
  font-size: 23px;
  font-weight: 700;
  margin-bottom: 0;
}

.header-social-text {
  text-align: center;
  margin-bottom: 0;
}

.header-card {
  height: 155px;
  /* max-height: 146px; */
}

.social-header {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
}

.search-bar {
  max-width: 300px;
  position: relative;
}

.search-gallery svg {
  position: absolute;
  z-index: 1;
  right: 125px;
  bottom: 22px;
  cursor: pointer;
}

.upload .card {
  max-width: 140px;
  margin-left: auto;
}

.gallery-image {
  width: 100%;
  max-width: 185px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.gallery-card-icon {
  color: #ababab;
}

.gallery-card-icon:hover {
  color: #000000;
}

.upload button {
  background: #fff;
  border: 1px solid #DFDFDF;
  padding: 4px 30px;
}

.header-modal-img {
  width: 100%;
  max-width: 40px;
  margin-right: 10px;
}

.modal-card-gal {
  border-top: none;
  border-radius: 0;
  height: 350px;
}

.upload-modal-img {
  width: 100%;
  max-width: 25px;
  margin-right: 10px;
}

.modal-card-gal .card-footer {
  background: #EBEBEB;
}

.modal-card-gal .card-footer button {
  background: #1ec2f3;
}

.modal-close {
  margin-top: -12px !important;
}

.gal-modal-text {
  text-align: center;
  font-size: 14px;
  margin-bottom: 10px !important;
}

.tag-image svg {
  position: absolute;
  z-index: 1;
  font-size: 25px;
  top: 7px;
  left: 27px;
}

.tag-grey-bg {
  background: #EBEBEB;
  margin-bottom: 10px;
}

.tag-grey-bg svg {
  font-size: 18px;
}

.done-button {
  background: #EBEBEB;
}

.days-select {
  width: 115px;
  margin-left: auto;
}

.social-graph-image {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.bar-card {
  width: 100%;
  height: 65px;
  border-radius: 0;
}

.bar-card .card-body {
  padding: 0;
}

.bar-graph-blue {
  border: 22px solid #1EC2F3;
  align-items: center;
  margin-top: 10px;
  width: auto;
  // max-width: 130px;
}

.graph-level {
  display: flex;
  column-gap: 13px;
  margin-left: 40px;
}

.social-percent-share {
  background: #F8F9FA;
  border: 0;
  border-radius: 0;
}

.social-name {
  font-weight: 700;
  margin-bottom: 0;
}

.share-count,
.share-percent {
  margin-bottom: 0;
}

.social-progress-bar-container {
  width: 100%;
  height: 10px;
  background: #fff;
  border-radius: 5px;
  position: relative;
}

.social-progress-bar-percent {
  height: 10px;
  background: #1ec2f3;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  font-size: 10px;
  overflow: hidden;
}

.percent-number-center {
  text-align: center;
  margin-top: -59px;
  font-weight: 700;
  margin-bottom: 45px;
}

.send-comment-button {
  border: 0;
  background: transparent;
  outline: none;
  margin: 0 auto;
  display: block;
}

.send-comment-button:focus {
  outline: none;
}

.social-lg-card {
  height: 566px;
}

.image-base-tags {
  display: flex;
  column-gap: 20px;
  align-items: center;
  justify-content: center;
}

// .version-scrollable {
//   overflow-y: scroll;
//   height: 230px;
//   overflow-x: hidden;
// }

.version-scrollable a {
  font-size: 0.8em;

}

.version-scrollable li {
  line-height: 1 !important;
  margin-bottom: 5px !important;
}

.version-scrollable.logs li {
  line-height: 1.5 !important;
  font-size: 12px;
}

.checklist-scrollable {
  // overflow-y: scroll;
  overflow-x: hidden;
}

.version-scrollable ul {
  padding-left: 20px;
  list-style-type: none;
  padding-left: 0;
}

.submittion-entries-card {
  height: 160px;
}

.card-popup-function {
  border: 0;
  border-radius: 0;
  background: transparent;
}

.card-popup-function-body {
  padding: 0;
}

.card-popup-funtion-footer {
  text-align: right;
  padding: 0px;
  visibility: hidden;
}

.card-popup-function:hover {
  border: 1px solid #DFDFDF;
  border-radius: 5px;
  background: #fff;
}

.card-popup-function-body:hover {
  padding: 5px;
}

.card-popup-funtion-footer:hover {
  visibility: visible;
  padding: 5px 10px;
}

.media-tabs .card {
  text-align: center;
  display: block;
  cursor: pointer;
  background: #ebebeb;
  border: none;
}

.media-tabs .active.card {
  color: #212529;
  background: #fff;
  border: 1px solid #DFDFDF;
}

.edit-max-score::-webkit-inner-spin-button {
  opacity: 1
}

.edit-max-score {
  font-size: 16px;
  padding: 0;
  border: 0;
  margin-top: -8px;
}

.edit-max-score:focus {
  outline: none;
}

.bis-text {
  // color: #1ec2f3;
  color: #1ec2f3;
  font-weight: 600;
}

.hr-divider {
  margin-top: 0px !important;
}

.custom-select-categories {
  background: #fff;
  border-radius: 5px;
  color: #000;
  height: 40px;
  padding: 10px;
  width: 100%;
  max-width: 300px;
  border: 1px solid #D4D4D4;
}

.custom-select-categories:focus {
  outline: none;
}

.custom-select-options-categories {
  background: #fff;
  border-radius: 5px;
  padding: 20px 10px;
  position: absolute;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  transition: .5s;
  width: 100%;
  max-width: 300px;
  z-index: 5;
  border: 1px solid #D4D4D4;
  margin-top: -10px;
  box-shadow: 0px 1px 4px 1px #d4d4d4;
}

.custom-select-options-categories ul {
  list-style: none;
  padding-left: 25px;
  margin-bottom: 0;
}

.custom-select-options-categories ul li {
  margin-bottom: 10px;
  line-height: 1.3;
}

.custom-select-options-categories [type='checkbox'] {
  margin-top: 3px;
}

#bis_award_tab .booking-nav .nav-item {
  width: 100%;
  max-width: 100% !important;
}

#bis_award_tab .booking-nav .active.card {
  color: #212529;
  background: #fff;
  border: 1px solid #DFDFDF;
}

.booking-nav .card {
  font-weight: 700;
  font-size: 17px;
  text-align: center;
  display: block;
  cursor: pointer;
  background: #ebebeb;
  padding: 15px;
}

.booking-nav .card:hover {
  color: #212529;
  background: #ebebeb;
}

.attendee-card .attendee_input {
  position: relative;
}

.attendee-card .pencil-svg {
  position: absolute;
  top: 61px;
  right: 25px;
  bottom: 0;
  font-size: 13px;
}

.attendee-card .person-icon {
  font-size: 25px;
  margin-bottom: 3px;
}

.attendee_input:focus+.pencil-svg {
  display: none;
  visibility: hidden;
}

#bis_award_tab .logo-img {
  width: 100%;
  max-width: 65px;
}

.update-document-button {
  width: 100%;
  // background: #267FC3;
  background: #1ec2f3;
  color: #fff;
  outline: none;
  border: none;
  // max-width: 200px;
  padding: 7px;
  border-radius: 5px;
  // margin-bottom: 15px;
}

.update-document-button:focus {
  outline: none;
}

.update-document-button:hover {
  background: #267FC3;
}

.text-xoverflow {
  max-width: auto;
  overflow-x: auto;
  white-space: nowrap;
}

////
.event-information-print-mgnt p {
  margin-bottom: 0px;
}

.event-information-print-mgnt svg {
  margin-bottom: 0px;
  margin-right: 7px;
}

// .event-information h3 {
//   color: #1ec2f3!important;
// }

.artwork-assigned-btn {
  background: gold;
  outline: none;
  border: 2px solid gold;
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff;
}

.artwork-design-revision-btn {
  background: #E8500D;
  outline: none;
  border: 2px solid #E8500D;
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff;
}

.artwork-design-review-btn {
  background: #8E7CC3;
  outline: none;
  border: 2px solid #8E7CC3;
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff;
}

.artwork-approved-btn {
  background: #1ec2f3;
  outline: none;
  border: 2px solid #1ec2f3;
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff;
}

.artwork-print-revision-btn {
  background: #a73807;
  outline: none;
  border: 2px solid #a73807;
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff;
}

.artwork-for-printing-btn,
.artwork-printing-in-progress-btn {
  background: #CF9905;
  outline: none;
  border: 2px solid #CF9905;
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff;
}

.artwork-done-btn {
  background: #049818;
  outline: none;
  border: 2px solid #049818;
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff;
}

.artwork-design-in-progress-btn {
  background: #A9D153;
  outline: none;
  border: 2px solid #A9D153;
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff;
}

.artwork-design-for-approval-btn {
  background: #D80BB6;
  outline: none;
  border: 2px solid #D80BB6;
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff;
}

.artwork-status-btn {
  min-width: 200px;
}

.artwork-profile-img {
  width: 100%;
  max-width: 40px;
  margin-left: 20px;
}

.artwork-upload-docs {
  background: #fff;
  border: 1px solid #DEE2E6;
  border-radius: 3px;
  padding: 6px 15px;
  margin: 15px 0px;
  outline: none;
}

.design-upload-container {
  // min-height: 196px;
  min-height: 144px;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
}

h6.comment-info-head {
  color: #8D8D8D;
  margin-bottom: 0px;
  font-size: 12px;
}

.comment-date-head {
  display: grid;
  grid-template-columns: auto auto auto;
  max-width: 280px;
  align-content: center;
  align-items: center;
  margin: 1px 0px;
}

.date-divider {
  width: 1px;
  background: #DEE2E6;
  height: 100%;
}

.comment-area {
  border: 0;
  outline: 0;
  background-color: transparent;
  resize: none;
  box-shadow: none !important;
}

.artwork-comment-body {
  background-color: #F2F2F2;
  border: none;
  border-radius: 0;
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  padding-top: 10px;
  padding-bottom: 10px;
}

.revision-alert {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}

.revision-alert-card {
  background: #FE240C66;
  color: #fff;
  border: 2px solid #EE786B;
  margin-bottom: 15px;
}

.header-alert {
  display: grid;
  grid-template-columns: auto auto auto;
  // max-width: 170px;
  justify-content: start;
  text-align: center;
}

.entry-support-icons {
  margin-bottom: 25px;
  max-width: 150px;
  width: 100%;
}

.entry-support-card:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.entry-support-card {
  box-shadow: 0px 0px 20px 0px #00000014;
  border: none;
  transition: all .3s ease;
  // height: 100%;
  height: 280px;
  width: 100%;
  margin-bottom: 20px;
}

.newbuttontheme {
  background-color: #1ec2f3;
  border: 1px solid #1ec2f3;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
}

.newbuttontheme:hover {
  color: #fff;
  background: #1ec2f3;
  border: 1px solid #1ec2f3;
}

.newbuttontheme:focus {
  outline: none;
}

.entrant-home-card {
  box-shadow: 0px 0px 20px 0px #00000014;
  border: none;
  transition: all .3s ease;
  height: 100%;
  width: 100%;
}

.entrant-home-card:hover {
  // left: 0;
  // padding: 0px 10px;
  // position: absolute;
  transform: scale(1.1);
  cursor: pointer;
}

.profile-left-btn:hover .entrant-left-icon {
  filter: brightness(0) invert(1);
}

.payment-left-btn:hover .entrant-left-icon {
  color: #1ec2f3 !important;
}

.profile-left-btn .entrant-left-icon {
  width: 100%;
  max-width: 24px;
  margin-right: 7px;
  height: 24px;
  color: #1ec2f3 !important;
}

.payment-left-btn .entrant-left-icon {
  width: 100%;
  max-width: 24px;
  margin-right: 7px;
  height: 24px;
  color: #fff !important;
}

.status-resubmit {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  column-gap: 10px;
}

.edit-entry-image {
  width: 100%;
  max-width: 160px;
}

.single-entry-forms {
  margin-left: 30px;
}

.single-entry-pop-up {
  padding: 6px 13px;
  border: 1px solid #CED4DA;
  margin-left: 30px;
  margin-bottom: 10px;
}

.white-background-circle {
  position: absolute;
  right: -10px;
  bottom: -16px;
  height: 35px;
  width: 35px;
  background: #fff;
  max-width: 50px;
  border-radius: 50%;
  border: 1px solid #DEE2E6;
  cursor: pointer;
}

.white-background-circle svg {
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 7px;
}

.sample-winning-head {
  color: #fff;
  text-align: center;
  max-width: 85px;
  margin-left: auto;
  margin-right: auto;
  padding: 12px;
  font-size: 40px;
  font-weight: 600;
  border-radius: 50%;
  margin-top: 10px;
  margin-bottom: 15px;
}

.sample-winning-entry-card {
  border-radius: 10px !important;
  height: 355px;
}

.sample-winning-entry-card .card-header {
  border-radius: 20px 20px 0px 0px !important;
}

.sample-winning-introduction {
  background-image: linear-gradient(#277DC2, #1ec2f3);
  border-radius: 0px 0px 10px 10px;
  border: none;
}

.sample-winning-mainbody {
  background-image: linear-gradient(#DEB51D, #E6C23C);
  border-radius: 0px 0px 10px 10px;
  border: none;
}

.sample-winning-support {
  background-image: linear-gradient(#50A546, #65CE59);
  border-radius: 0px 0px 10px 10px;
  border: none;
}

.sample-winning-materials {
  background-image: linear-gradient(#C2272E, #F31E1E);
  border-radius: 0px 0px 10px 10px;
  border: none;
}

.sample-winning-items {
  padding-left: 30px;
  color: #fff;
}

.entrant-home-modal-image {
  width: 100%;
  max-width: 250px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.award-profile {
  max-width: 20px;
  margin-right: 7px;
  height: 20px;
}

.notification-time {
  // background: #277FC2;
  background: #1ec2f3;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 5px;
  color: #fff;
  max-width: 220px;
  text-align: center;
}

.jucor-time {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  column-gap: 10px;
  align-items: center;
}

.jucor-time-card {
  display: grid;
  grid-template-columns: auto auto;
  justify-items: center;
}

.invitation-progress-bar-container {
  // width: 100%;
  width: 132px;
  height: 10px;
  background: #a5a5a5;
  border-radius: 5px;
  position: relative;
}

.invitation-progress-bar-percent {
  height: 10px;
  background: #1ec2f3;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  font-size: 10px;
  overflow: hidden;
}

.profile-link-buttons {
  margin-right: 7px;
  width: 100%;
  padding: 7px 10px;
  border: none;
  border-radius: 3px;
  color: #fff;
  font-weight: 500;
  max-width: 230px;
}

.profile-link-buttons:focus {
  outline: none;
}

.profile-link-icons {
  width: 100%;
  max-width: 17px;
  margin-top: -5px;
}

.accordion-head {
  display: flex;
  justify-content: space-between;
}

.accordion-radio {
  margin-right: 14px;
  display: inline-flex;
  column-gap: 10px;
}

.accordion-title {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 5px;
  align-items: center;
}

.accordion-drop {
  max-width: 335px;
  position: absolute;
  z-index: 1;
}

.reviewed-categories {
  background-color: #1ec2f3;
  border: 1px solid #1ec2f3;
  cursor: normal;
}

.time-header-judge {
  box-shadow: 0px 0px 20px 0px #00000014;
  border: none;
}

.comment-auto-scroll {
  overflow-y: scroll;
  height: 400px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column-reverse;
  bottom: 0;
  position: relative;
}

.connected-accounts {
  position: absolute;
  top: 18px;
  right: 55px;
}

.gallery-home-card {
  box-shadow: 0px 0px 20px 0px #00000014;
  border: none;
  transition: all .3s ease;
  height: 100%;
  width: 100%;
  max-width: 700px;
  margin: 30px auto;
}

.gallery-home-card:hover {
  transform: scale(1.1, 1.1);
  cursor: pointer;
}

.gallery-home-card img {
  width: 100%;
  max-width: 290px;
}

.gallery-checkbox {
  display: flex;
  justify-content: space-between;
}

.gallery-custom-select {
  background: #fff;
  // border-radius: 5px 0px 0px 5px;
  border-radius: 5px;
  color: #000;
  height: 38px;
  padding: 10px;
  width: 100%;
  border: 1px solid #D4D4D4;
  max-width: 140px;
  // border-right: none;
}

.gallery-custom-select:focus {
  outline: none;
}

#gallery-custom-select-content {
  float: left;
  margin-top: -2px;
}

#gallery-custom-select-icon {
  float: right;
}

#gallery-custom-select-icon svg {
  margin-bottom: 8px;
}

.gallery-custom-select-options {
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  transition: .5s;
  width: 100%;
  max-width: 129px;
  z-index: 9999;
  border: 1px solid #D4D4D4;
  margin-top: -12px;
  box-shadow: 0px 1px 4px 1px #d4d4d4;
  max-height: 400px;
}

.gallery-custom-select-options ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0;
}

.gallery-custom-select-options ul li {
  // margin-bottom: 10px;
  line-height: 1.4;
}

.gallery-custom-select-options svg {
  color: #1ec2f3;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 20px;
}

.gallery-custom-select .block-icon {
  color: #1ec2f3;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 20px;
}

.gallery-search-bar svg {
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 20px;
}

.gallery-image {
  width: 100%;
}

.button-theme-hallow {
  // width: 100%;
  border-radius: 5px;
  border: 1px solid #DEE2E6;
  padding: 0.375rem 0.75rem;
  background: #fff;
}

.button-theme-hallow:hover {
  background: #1ec2f3;
  color: #fff;
}

.button-theme-hallow:focus {
  outline: none;
}

.button-theme-hallow:hover svg {
  color: #fff;
}

.button-theme-hallow .seat-veiw-edit-icon {
  color: #000;
}

.button-theme-hallow svg {
  font-size: 20px;
  // color: #1ec2f3;
  margin-right: 5px;
}

.navigation-arrow {
  place-self: center;
  text-align: center;
}

.gallery-image-preview.modal-dialog {
  max-width: 500px;
}

.gallery-background-image {
  background-size: cover;
  background-position: center;
  min-height: 145px;
  width: 100%;
  position: relative;
}

.gallery-background-image-large {
  background-size: cover;
  background-position: center;
  min-height: 250px;
  width: 100%;
}

.navigation-arrow-button {
  background: transparent;
  border: none;
  outline: none;
  width: 80px;
  text-align: center;
}

.navigation-arrow-button:focus {
  outline: none;
}

.navigation-arrow-button svg {
  font-size: 70px;
  opacity: 0.24;
}

.social-share {
  box-shadow: 0px 0px 20px 0px #00000014;
  border: none;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.social-share img {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  max-width: 170px;
}

.remove-icon {
  position: absolute;
  right: 25px;
  top: 25px;
  color: red;
  background: #fff;
  border-radius: 50%;
  font-size: 20px;
}

.email-badge {
  background: #E4E4E4;
  color: #6E6E6E;
  width: auto;
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px !important;
  border-radius: 6px;
  padding: 4px 8px;
}

.email-badge svg {
  margin-top: 4px;
  color: #fff;
  margin-bottom: 2px;
  float: right;
  background: red;
  border-radius: 50px;
  cursor: pointer;
}

.social-media-icons svg {
  margin: 10px 20px;
  font-size: 25px;
  color: #C4C5C5;
}

.social-media-icons svg:hover {
  background-color: #EEEEEE;
  border-radius: 5px;
  padding: 4px;
  transform: scale(1.5);
  font-size: 25px;
  color: #858583;
  cursor: pointer;
}

.add-social-account {
  // max-width: 180px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  border-radius: 10px;
  // margin-bottom: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}

.social-account {
  // max-width: 180px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  border-radius: 10px;
  // margin-bottom: 10px;
  margin-bottom: 20px;
  // min-height: 148px;
}

.social-account:hover {
  background: #EBEBEB;
}

.active .social-account {
  background: #EBEBEB;
}

.social-account img {
  width: auto;
  height: 50px;
  border-radius: 100px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.social-account.card p,
.add-social-account.card p {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.social-account-checkbox {
  position: absolute;
  right: 15px;
  left: auto;
  top: 15px;
  // z-index: 999;
}

// .social-account p {
//   font-size: 14px;
// }

.add-social-account:hover {
  background: #EBEBEB;
}

.add-social-account svg {
  background: #F2F2F2;
  padding: 10px;
  font-size: 50px;
  border-radius: 50%;
  color: #858583;
}

.add-social-account:hover svg {
  background: #D8D8D8;
  color: #3E3F3E;
}

.select-all-icon {
  margin-right: 10px;
  font-size: 20px;
  border-radius: 50%;
  transform: scale(1.4);
  padding: 3px;
  color: #fff;
}

.image-checkbox {
  position: absolute;
  z-index: 999;
  top: 5px;
  left: 20px;
}

.button-icon-image {
  position: absolute;
  z-index: 9;
  bottom: 5px;
  right: 5px;
}

// .image-overlay-container {
//   position: relative;
//   width: 100%;
//   max-height: 185px;
//   // max-width: 300px;
// }

.play-btn {
  width: 50px;
  height: 50px;
  /* opacity: 0.5; */
  position: absolute;
  top: 65px;
  left: 65px;
  color: #fff;
  cursor: pointer;
}

.newbuttontheme.gallery-filter-btn {
  background: #fff;
  border: 1px solid #dfdfdf;
  color: #212529;
}

.newbuttontheme.gallery-filter-btn.active {
  background-color: #1ec2f3;
  border: 1px solid #1ec2f3;
  color: #ffffff;
}

.preview-media-image,
.preview-media-video {
  width: 100%;
  height: auto;
}

.image-overlay-container {
  position: relative;
  width: 100%;
  max-height: 185px;
  min-height: 185px;
  background-size: cover;
  background-position: center;
  margin-bottom: 10px;
}

.create-award-logo {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 200px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
}

.image-overlay-container .overlay {
  position: absolute;
  top: 0;
  // background: rgb(0, 0, 0);
  // background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}

.image-overlay-container:hover .overlay {
  opacity: 1;
}

.add-more-gallery .active p {
  color: #1c2023 !important;
}

.sm-account-thumb:hover .social-small-icon-close {
  display: inline-block;
}

.sm-account-thumb:hover .social-small-icon {
  display: none;
}

.social-profile-image-header {
  border-radius: 50%;
  // width: 100%;
  // max-width: 60px;
  max-width: 50px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.add-more-account {
  // height: 60px;
  height: 50px;
  color: #BFBFBF;
  padding: 12px;
  // width: 60px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid #BFBFBF;
  cursor: pointer;
  position: static;
  min-width: 50px;
}

.social-media-ellipsis {
  max-width: 313px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.social-media-ellipsis a {
  text-decoration: none;
}

.sm-account-thumb-controller {
  max-width: 640px;
  overflow: hidden;
  overflow-x: auto;
  padding-bottom: 10px;
}

.sm-account-thumb-controller::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.add-more-account-pop-up::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.sm-account-thumb-controller::-webkit-scrollbar-track {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

.add-more-account-pop-up::-webkit-scrollbar-track {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

.sm-account-thumb-controller::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #1ec2f3;
  outline: 1px solid rgba(0, 0, 0, 0.2);
}

.add-more-account-pop-up::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #1ec2f3;
  outline: 1px solid rgba(0, 0, 0, 0.2);
}

.sm-account-thumb-controller::-webkit-scrollbar:vertical {
  display: none;
}

.add-more-account-pop-up::-webkit-scrollbar:vertical {
  display: none;
}

.comment-upload {
  border: none;
  margin-bottom: 10px;
  margin-top: 10px;
}

.comment-upload:focus {
  outline: none !important;
}

.social-small-icon {
  position: absolute;
  right: 10px;
  padding: 3px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #fff;
  z-index: 9;
}

.light-spinner-btn .spinner-border {
  max-width: 24px;
  max-height: 24px;
}

.social-small-icon-close {
  position: absolute;
  right: 10px;
  width: 20px;
  display: none;
  height: 20px;
  border-radius: 50%;
  color: red;
  background: #fff;
  cursor: pointer;
  z-index: 9;

}


// .social-media-publish {
//   display: flex;
//   column-gap: 10px;
// }

.social-media-publish .card {
  width: 100%;
  max-width: 200px;
  align-items: center;
  align-content: center;
  display: grid;
  justify-content: center;
  cursor: pointer;
  background: #F2F2F2;
}

.social-media-publish svg.small-icon-close,
.gallery-background-image-large svg.small-icon-close {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 20px;
  display: inline-block;
  height: 20px;
  padding: 0;
  border-radius: 50%;
  color: red;
  background: #fff;
  cursor: pointer;
  z-index: 1;
  border: none;
}

.social-media-publish .card:hover svg,
.social-media-publish .card:hover p {
  color: #000;
}

.social-media-publish svg {
  font-size: 35px;
  color: #999898;
  border: 2px dashed;
  padding: 16px;
  width: 80px;
  height: 80px;
}

.social-media-publish p {
  color: #999898;
}

.social-image-option {
  margin-top: 15px;
}

.social-image-option svg {
  color: #A2A2A3;
  font-size: 25px;
  // margin-top: 15px;
  // margin-right: 20px;
}

.social-image-option svg:hover {
  color: #313031;
}

.schedule-post.card {
  position: absolute;
  top: -155px;
  left: 10px;
  text-align: center;
  width: 100%;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #00000047;
  z-index: 1;
}

.remove-media-icon {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 1;
}

.custom-video-play {
  color: #1ec2f3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0 !important;
}

.selection-div:hover .bis-tooltip {
  display: block;
}

.selection-div .bis-tooltip {
  z-index: 1;
  right: 8px;
  padding: 0;
  background: none;
  border: 0;
  top: 8px;
  display: none;
  position: absolute;
  transition: 0.3s;
}

#edit-profile-page.preview .award_entry_content:hover {
  color: #495057;
}

.selection-add-more {
  cursor: pointer;
  background: #F2F2F2;
}

.selection-add-more svg {
  border: 2px dashed;
  padding: 16px;
  margin-top: 5px;
}

.profile-picture-container {
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 500px;
}

.gif-loading {
  width: 100%;
  max-width: 100px;
  margin-top: 15px;
}

.custom-radio {
  background: #fff;
  text-align: center;
  border-radius: 50px;
  cursor: pointer;
  max-height: 14px;
  min-height: 14px;
  min-width: 14px;
  max-width: 14px;
  display: inline-block;
  vertical-align: middle;
  border: 2px solid #1ec2f3;
  padding: 1px;
  margin-right: 5px;
}

.custom-radio.active .custom-tick {
  width: 8px;
  height: 8px;
  background: #1ec2f3;
  border-radius: 10px;
}

.custom-checkbox {
  background: #fff;
  padding: 1px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  max-height: 16px;
  min-height: 16px;
  min-width: 16px;
  max-width: 16px;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.custom-checkbox.small {
  max-height: 14px;
  min-height: 14px;
  min-width: 14px;
  max-width: 14px;
}

.custom-checkbox.small svg {
  width: 10px;
  height: 10px;
}

.custom-checkbox.thumbnail-checkbox {
  position: absolute;
  z-index: 1;
  top: 5px;
  left: 5px;
}

.custom-checkbox.active {
  background: #1ec2f3;
}

.custom-checkbox .custom-check {
  line-height: 1.2;
  display: none
}

.custom-checkbox.active .custom-check {
  display: block;
}

.add-more-account-pop-up {
  text-align: center;
  position: absolute;
  z-index: 9;
  top: 70px;
  // right: 340px;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 0px 10px 0px #00000047;
  // min-width: 300px;
  max-width: 300px;
  min-width: 300px;
  overflow-x: auto;
}

.select-image-box svg {
  color: #1ec2f3;
}

.select-image-box {
  margin-top: 75px;
}

.bis-needs-action-link {
  color: #267FC3;
  text-decoration: underline;
  cursor: pointer;
}

.bis-needs-action-link:hover {
  color: #1ec2f3;
}

.priority-card {
  margin-bottom: 20px;
  position: relative;
}

.allocation-name {
  font-weight: 700;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
  display: block;
  cursor: pointer;
}

.social-media-add-container {
  display: flex;
}

.badge-big {
  font-size: 16px;
  padding: 7px 10px;
  font-weight: 400;
}

.priority-number {
  color: #ffffff;
  height: 30px;
  width: 30px;
  padding-top: 3px;
  text-align: center;
  border-radius: 50px;
  display: inline-block;
}

.priority-number-percent {
  height: 40px;
  width: 40px;
  font-size: 14px;
  padding-top: 10px;
  font-weight: 400;
  vertical-align: middle;
}

.priority-number.priority-number-big {
  color: #ffffff;
  height: 40px;
  width: 40px;
  // padding-top: 3px;
  text-align: center;
  border-radius: 50px;
  padding-top: 8px;
  display: inline-block;
}


.priority-number.priority-number-small {
  font-size: 12px;
  padding-top: 7px;
}

.judge-allocation-row {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-none {
  cursor: auto !important;
}

.gallery-video {
  object-fit: cover;
  background-color: #212529;
  border: 0;
}

.video-controller {
  max-height: 185px;
  min-height: 185px;
}

.media-description {
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  padding: 5px 10px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 12px;
}

.media-category {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.media-div-container:hover .media-edit,
.media-div-container:hover .media-delete, 
.media-div-container:hover .media-copy {
  display: block;
}

.video-controller:hover .media-edit,
.video-controller:hover .media-delete,
.video-controller:hover .media-copy {
  display: block;
}

.active-logo-container {
  min-height: 135px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain, cover;
  position: relative;
  border: 2px solid #dfdfdf;
}

.logo-div-container:hover .logo-download,
.logo-div-container:hover .logo-delete {
  display: block;
}

.logo-delete {
  position: absolute;
  padding: 7px;
  border-radius: 50px;
  background: #ef2b08;
  padding-top: 4px;
  right: 19px;
  top: 14px;
  z-index: 2;
  line-height: 1;
  display: none;
  cursor: pointer;
}

.logo-delete:hover {
  background: #fff;
}

.logo-delete:hover svg {
  color: #ef2b08 !important;
}

.logo-download {
  position: absolute;
  padding: 7px;
  border-radius: 50px;
  background: rgb(100, 206, 88);
  padding-top: 4px;
  right: 50px;
  top: 14px;
  z-index: 2;
  line-height: 1;
  display: none;
  cursor: pointer;
}

.logo-download:hover {
  background: #fff;
}

.logo-download:hover svg {
  color: rgb(100, 206, 88) !important;
}

.media-copy {
  position: absolute;
  padding: 7px;
  border-radius: 50px;
  background: #008000;
  padding-top: 4px;
  right: 78px;
  top: 8px;
  z-index: 2;
  line-height: 1;
  display: none;
  cursor: pointer;
}

.media-copy:hover {
  background: #fff;
}

.media-copy:hover svg {
  color: #008000 !important;
}

.media-edit {
  position: absolute;
  padding: 7px;
  border-radius: 50px;
  background: #1ec2f3;
  padding-top: 4px;
  right: 43px;
  top: 8px;
  z-index: 2;
  line-height: 1;
  display: none;
  cursor: pointer;
}

.media-edit:hover {
  background: #fff;
}

.media-edit:hover svg {
  color: #1ec2f3 !important;
}

.media-delete {
  position: absolute;
  padding: 7px;
  border-radius: 50px;
  background: #ef2b08;
  padding-top: 4px;
  right: 8px;
  top: 8px;
  z-index: 2;
  line-height: 1;
  display: none;
  cursor: pointer;
}

.media-delete:hover {
  background: #fff;
}

.media-delete:hover svg {
  color: #ef2b08 !important;
}

.media-delete.copy{
  background: #008000;
}

.media-delete.copy:hover {
  background: #fff !important;
}

.media-delete.copy:hover svg {
  color: #008000 !important;
}

.gallery-video.gallery-video-big {
  width: 100%;
  height: auto;
}

.main-gallery-bg {
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 185px;
  max-height: 185px;
  cursor: pointer;
}

.main-gallery-bg-small {
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100px;
  max-height: 100px;
  // cursor: pointer;
}

.allocation-button {
  cursor: pointer;
}

.reallocated-icon {
  max-width: 20px;
  margin-left: 10px;
}

.panel-approved {
  color: rgb(69, 182, 73);
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 30px;
}

.priority-edit {
  position: absolute;
  right: 20px;
  // top: 5px;
  top: 4px;
}

.priority-1 {
  // background: #3BE500; 
  background: #45b649;
}

.priority-2 {
  background: #A9E500;
}

.priority-3 {
  background: #E5B000;
}

.priority-4 {
  background: #D67830;
}

.priority-5 {
  background: #D63030;
}

.priority-name {
  display: inline-block;
  margin-left: 10px;
  border: 1px solid #CCD5E0;
  width: 90%;
  // width: 85%;
  padding: 5px 10px;
  border-radius: 5px;
}

.title {
  position: relative;
  margin-top: 30px;
  width: 100%;
  text-align: center;
}

.timeline {
  position: relative;
  width: 100%;
  // padding: 30px 0;
}

.timeline .timeline-container {
  position: relative;
  width: 100%;
}

.timeline .timeline-continue {
  position: relative;
  width: 100%;
  // padding: 60px 0;
}

.timeline .timeline-continue::after {
  position: absolute;
  content: "";
  width: 1px;
  // height: 98%;
  height: 95%;
  top: 0;
  left: 128px;
  margin-left: -1px;
  background: #1ec2f3;
}

.timeline .row.timeline-right,
.timeline .row.timeline-left .timeline-date {
  text-align: left;
}

.timeline .timeline-date {
  font-size: 14px;
  font-weight: 600;
  margin: 57px 0 0 0;
}

.header-notification {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.timeline .timeline-date::after {
  content: '';
  display: block;
  position: absolute;
  width: 14px;
  height: 14px;
  top: 60px;
  background: #1ec2f3;
  box-shadow: 0 0 5px rgba(0, 0, 0, .4);
  border-radius: 15px;
  z-index: 1;
}

.form-control:disabled,
.form-control[readonly] {
  cursor: not-allowed;
}

.timeline .row.timeline-left .timeline-date::after {
  left: -7px;
}

.timeline .row.timeline-right .timeline-date::after {
  right: -7px;
}

.timeline .timeline-box,
.timeline .timeline-launch {
  position: relative;
  display: inline-block;
  // margin: 0px 20px;
  width: 100%;
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 6px;
  background: #ffffff;
}

.timeline .timeline-launch {
  width: 100%;
  margin: 15px 0;
  padding: 0;
  border: none;
  text-align: center;
  background: transparent;
}

.timeline .row.timeline-left .timeline-box::after,
.timeline .row.timeline-left .timeline-box::before {
  left: 100%;
}

.timeline .row.timeline-right .timeline-box::after,
.timeline .row.timeline-right .timeline-box::before {
  right: 100%;
}

.timeline .timeline-launch .timeline-box::after,
.timeline .timeline-launch .timeline-box::before {
  left: 50%;
  margin-left: -10px;
}

.timeline .timeline-box::after {
  top: 26px;
  border-color: transparent transparent transparent #ffffff;
  border-width: 10px;
}

.timeline .timeline-box::before {
  top: 25px;
  border-color: transparent transparent transparent #dddddd;
  border-width: 11px;
}

.timeline .row.timeline-right .timeline-box::after {
  border-color: transparent #ffffff transparent transparent;
}

.timeline .row.timeline-right .timeline-box::before {
  border-color: transparent #dddddd transparent transparent;
}

.timeline .timeline-launch .timeline-box::after {
  top: -20px;
  border-color: transparent transparent #dddddd transparent;
}

.timeline .timeline-launch .timeline-box::before {
  top: -19px;
  border-color: transparent transparent #ffffff transparent;
  border-width: 10px;
  z-index: 1;
}

.timeline .timeline-box .timeline-icon {
  position: relative;
  width: 40px;
  height: auto;
  float: left;
}

.social-shared-photo {
  width: 100%;
}

.social-media-publish-timeline {
  display: flex;
  column-gap: 10px;
  overflow: hidden;
  height: 150px;
  overflow-x: scroll;
}

.timeline-image-close img {
  position: relative;
}

.timeline-image-close svg {
  position: absolute;
  z-index: 1;
  margin-top: -8px;
  background: #fff;
  width: 20px;
  height: 20px;
  color: #E04E5E;
  border-radius: 50%;
  right: -8px;
}

.timeline-image-profile img {
  width: 100%;
  border-radius: 50%;
}

.social-media-pop-up-linkedin {
  padding: 7px;
  max-width: 50px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  background: #0076B4;
  position: relative;
}

.social-media-pop-up .linkedIn-icon {
  // background: #0076B4;
  color: #fff;
  cursor: pointer;
  width: 35px;
  height: 35px;
}

.social-media-pop-up-facebook {
  padding: 7px;
  max-width: 50px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  background: #1977D3;
  position: relative;
}

.social-media-pop-up .facebook {
  // background: #1977D3;
  color: #fff;
  cursor: pointer;
  width: 35px;
  height: 35px;
}

.social-media-pop-up-instagram {
  padding: 7px;
  max-width: 50px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  background: #F6248D;
  position: relative;
}

.social-media-pop-up .instagram {
  // background: #F6248D;
  color: #fff;
  cursor: pointer;
  width: 35px;
  height: 35px;
}

.social-media-pop-up-twitter {
  padding: 7px;
  max-width: 50px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  background: #00BEF4;
  position: relative;
}

.social-media-pop-up .twitter {
  // background: #00BEF4;
  color: #fff;
  cursor: pointer;
  width: 35px;
  height: 35px;
}

.social-small-pop-up {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 15px;
}

.add-more-account-pop-up p {
  font-size: 14px;
}

.schedule-pop-up {
  position: absolute;
  top: 215px;
  border: none;
  transition: ease 1s;
  text-align: center;
  right: 0;
  left: 0;
  box-shadow: 0px 0px 10px 0px #00000047;
}

.timeline-image-close {
  position: relative;
  height: 100%;
  margin-top: 10px;
}

//ToolTip-gallery
.gallery-modal-tooltip {
  position: relative;
  display: inline-block;
  font-weight: 600;
  background-color: #ffffff;
  border-radius: 0.25rem;
  cursor: pointer;
  margin-right: 15px;
}

.gallery-modal-tooltip .gallery-modal-tooltiptext {
  visibility: hidden;
  width: 180px;
  background-color: black;
  opacity: 0.8;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -89px;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
}

.gallery-modal-tooltip .gallery-modal-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;

}

.gallery-modal-tooltip:hover .gallery-modal-tooltiptext {
  visibility: visible;
}

.time-schedule {
  color: #A2A2A3;
  max-width: 98px;
}

.time-schedule:hover {
  color: #313031;
}

.prio-categories {
  display: flex;
  border-radius: 50%;
  border: 10px solid #1ec2f3;
  color: #ffffff;
  width: 20px;
  height: 20px;
  font-size: 12px;
  justify-content: center;
  align-items: center;
}

.prio-div {
  display: inline-block;
}

.check-connected {
  position: absolute;
  right: 0;
  color: #05CC33;
  top: 0;
  background: #fff;
  border-radius: 50%;
}



/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 16px;
  cursor: pointer;
  font-size: 22px;
  margin-bottom: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid #838383;
  border-radius: 2px;
  background: #fff;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkmark {
  background-color: #1EC2F3;
  border: 1px solid #1EC2F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 5px;
  top: 0px;
  width: 0px;
  height: 10px;
  border-radius: 21px;
  border: solid white;
  border-width: 13px 3px 0px 0;
  transform: rotate(45deg);
}

.ant-divider {
  display: none !important;
}

.ant-card-body .ant-row:nth-child(2) {
  display: none !important;
}

///
.info-badge {
  width: 100%;
  max-width: 200px;
  text-align: center;
  color: #fff;
}

.stakeholder-head-image {
  display: flex;
}

.stakeholder-dropdown-option {
  position: absolute;
  top: 56px;
  width: 100%;
  z-index: 1;
  max-width: 222px;
}

.stakeholder-dropdown-option p {
  text-transform: capitalize;
}

.dropdown-option-assigned {
  background: #013AF5;
  width: 5px;
  height: 5px;
  margin-top: 2px;
  margin-right: 7px;
  padding: 9px;
  border-radius: 5px;
}

.dropdown-option-revision {
  background: #E8500D;
  width: 5px;
  height: 5px;
  margin-top: 2px;
  margin-right: 7px;
  padding: 9px;
  border-radius: 5px;
}

.dropdown-option-printing {
  background: #CF9905;
  width: 5px;
  height: 5px;
  margin-top: 2px;
  margin-right: 7px;
  padding: 9px;
  border-radius: 5px;
}

.dropdown-option-done {
  background: #049818;
  width: 5px;
  height: 5px;
  margin-top: 2px;
  margin-right: 7px;
  padding: 9px;
  border-radius: 5px;
}

.stakeholder-dropdown-option p:hover {
  color: #1EC2F3;
  cursor: pointer;
}

.stakeholder-dropdown-assigned {
  background: #013AF5;
}

.stakeholder-dropdown-revision {
  background: #E8500D;
}

.stakeholder-dropdown-printing {
  background: #CF9905;
}

.stakeholder-dropdown-done {
  background: #049818;
}

.stakeholder-dropdown {
  background: #1EC2F3;
}

.stakeholder-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.stakeholder-tooltip .stakeholder-tooltiptext {
  visibility: hidden;
  width: 305px;
  background-color: black;
  opacity: 0.8;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 0%;
  margin-left: -112px;
  font-weight: 600;
  font-size: 14px;
}

.stakeholder-tooltip .stakeholder-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.stakeholder-tooltip:hover .stakeholder-tooltiptext {
  visibility: visible;
}

.stakeholder-image {
  width: 100%;
  max-width: 124px;
  margin-right: 14px;
}

.stakeholder-card-page {
  height: 150px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.artwork-overlay {
  display: flex;
  position: absolute;
  width: 113px;
  max-width: 100%;
  color: #ffffff;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  justify-content: space-around;
  align-items: center;
  font-size: 24px;
}

.floorplan-checkbox {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: start;
  column-gap: 20px;
  margin-bottom: 15px;
  margin-top: 20px;
}

.seats-dnd {
  min-height: 500px;
}

//
.seat-custom-select {
  background: #fff;
  border-radius: 5px;
  color: #000;
  height: 40px;
  padding: 10px;
  width: 100%;
  max-width: 300px;
  border: 1px solid #D4D4D4;
}

.seat-custom-select:focus {
  outline: none;
}

#seat-custom-select-content {
  float: left;
  margin-top: -2px;
}

#seat-custom-select-icon {
  float: right;
}

#seat-custom-select-icon svg {
  margin-bottom: 5px;
}

.seat-custom-select-options {
  background: #fff;
  border-radius: 5px;
  padding: 20px 10px;
  position: absolute;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  transition: .5s;
  width: 100%;
  max-width: 250px;
  z-index: 5;
  border: 1px solid #D4D4D4;
  margin-top: -10px;
  box-shadow: 0px 1px 4px 1px #d4d4d4;
  // overflow-y: scroll;
  // max-height: 400px;
}

.seat-custom-select-options ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0;
}

.seat-custom-select-options ul li {
  margin-bottom: 10px;
  line-height: 1.3;
}

.seat-custom-select-options [type='checkbox'] {
  margin-top: 3px;
  margin-left: 9px;
}

.seat-view-legend {
  display: flex;
  column-gap: 30px;
  justify-content: flex-start;
}

.seat-view-legend .taken {
  border-left: 25px solid #28C342;
  padding-left: 12px;
}

.seat-view-legend .available {
  border-left: 25px solid #CFD2D5;
  padding-left: 12px;
}

.attendee-container.available {
  border-left: 25px solid #CFD2D5;
  padding-left: 12px;
}

.attendee-container.available.small {
  border-left: 16px solid #CFD2D5;
  font-size: 16px;
  padding-left: 5px;
  height: 17px;
  line-height: 1;
}

.attendee-container.taken {
  border-left: 25px solid #28C342;
  padding-left: 12px;
}

.attendee-box {
  font-size: 50px;
  float: left;
  height: 25px;
  width: 25px;
  /* margin-bottom: 15px; */
  /* border: 1px solid black; */
  clear: both;
}

.attendee-box.taken {
  background-color: #1ec2f3;
}

.attendee-box.available {
  background-color: #CFD2D5;
}

.seats-dnd-card {
  border: 2px solid #CFD2D5;
  height: 100%;
}

.seats-dnd-card .card-body {
  padding: 15px 5px;
}

.seat-dnd-table-list img {
  width: 100%;
  max-width: 30px;
  align-self: center;
  height: 100%;
}

.seat-dnd-table-list {
  padding: 3px;
  align-items: center;
}

.seat-dnd-table-list .taken {
  width: 20px;
  height: 20px;
  background: #28C342;
}

.seat-dnd-table-list .available {
  width: 20px;
  height: 20px;
  background: #CFD2D5;
}

.seat-dnd-table-list .info {
  display: flex;
  column-gap: 4px;
  align-items: center;
  place-self: flex-start;
}

.seat-dnd-table-list svg {
  color: #E7BA4C;
}

.seats-search-bar svg {
  position: absolute;
  top: 10px;
  right: 23px;
  font-size: 20px;
}

.bis-link-text {
  color: #1ec2f3;
}

.seat-table-image {
  width: 100%;
  max-width: 100px;
}

.seats-modal-image {
  width: 100%;
  max-width: 200px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 25px;
  margin-bottom: 40px;
}

.seat-profile-icon {
  font-size: 35px;
  color: #1ec2f3;
}

.seat-trophy-icon {
  color: #E7BA4C;
  margin-right: 2px;
}

.seat-navigation {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 20px;
  margin: 0px 15px;
}

//
.tables-arrangement .table-number-container {
  border: none;
  // border-radius: 50%;
  // width: 50px;
  // height: 50px;
  text-align: center;
  line-height: 1;
  padding: 5px;
  margin-bottom: 10px;
  background: #eee;
  border-radius: 3px;

  min-width: 90px;
  min-height: 90px;
  // max-width: 90px;  
  // max-height: 90px;

  max-width: 130px;
  // max-height: 90px;
}

.table-Round .table-number-container {
  border-radius: 100px;
}

.table-number-text {
  margin-top: 20px;
}

.react-draggable {
  cursor: grab;
}

.average-priority-name-tooltip {
  position: relative;
  display: inline-block;
}

.average-priority-name-tooltip .average-priority-name-tooltiptext {
  visibility: hidden;
  width: 270px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  // padding: 5px 15px;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 50%;
  margin-left: -135px;
  padding: 5px 15px;
}

.average-priority-name-tooltip .average-priority-name-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.average-priority-name-tooltip:hover .average-priority-name-tooltiptext {
  visibility: visible;
}

.overall-average-priority-name-tooltip {
  position: relative;
  display: inline-block;
}

.overall-average-priority-name-tooltip .overall-average-priority-name-tooltiptext {
  visibility: hidden;
  width: 340px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 15px;
  position: absolute;
  z-index: 1;
  bottom: 200%;
  left: 50%;
  margin-left: -170px;
}

.overall-average-priority-name-tooltip .overall-average-priority-name-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.overall-average-priority-name-tooltip:hover .overall-average-priority-name-tooltiptext {
  visibility: visible;
}


.history-name-tooltip {
  position: relative;
  display: inline-block;
}

.history-name-tooltip .history-name-tooltiptext {
  visibility: hidden;
  width: 115px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 15px;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 50%;
  margin-left: -53px;
}

.history-name-tooltip .history-name-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.history-name-tooltip:hover .history-name-tooltiptext {
  visibility: visible;
}

.edit-name-tooltip {
  position: relative;
  display: inline-block;
}

.edit-name-tooltip .edit-name-tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 15px;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 50%;
  margin-left: -48px;
}

.edit-name-tooltip .edit-name-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.edit-name-tooltip:hover .edit-name-tooltiptext {
  visibility: visible;
}

.newbuttontheme-cancel {
  background-color: red;
  border: 1px solid red;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
}

///
.custom-select-invoices {
  background: #fff;
  border-radius: 5px;
  color: #000;
  height: 38px;
  padding: 10px;
  width: 240px;
  margin-left: 10px;
  border: 1px solid #D4D4D4;
  outline: none;
}

.custom-select-invoices:focus {
  outline: none;
}

.search-icon-invoices {
  position: absolute;
  top: 8px;
  margin-left: 170px;
  font-size: 20px;
}

.search-input-invoices {
  position: relative;
  width: 350px;
  margin-right: 10px;
}

.bis-tooltip-download {
  position: relative;
  display: inline-block;
  font-weight: 600;
  cursor: pointer;
}

.bis-tooltip-download .bis-tooltiptext-download {
  visibility: hidden;
  width: 130px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -65px;
  font-weight: 600;
  font-size: 14px;
}

.bis-tooltip-download .bis-tooltiptext-download::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.bis-tooltip-download:hover .bis-tooltiptext-download {
  visibility: visible;
}

.entry-feedback {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.entry-feedback svg {
  font-size: 20px;
}

.script-container {
  height: 766px;
  //overflow: hidden;
  max-width: 100%;
}

.script-container-body {
  overflow-y: scroll;
}

.embed-container {
  // overflow-y: scroll;
  position: relative;
  padding-bottom: 56.25%;
  height: 766px;
  //overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.panel-divider {
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 1px;
  background: #1EC2F3;
}

.judging-panel:hover .panel-divider {
  background: #fff;
}


.colored-bullets {
  min-width: 15px;
  min-height: 15px;
  border-radius: 50%;
  margin-top: 5px;
}

.entry-list {
  display: flex;
  align-content: center;
  align-items: flex-start;
  column-gap: 8px;
}

.submit-disabled {
  background-color: #6cddff;
  border: 1px solid #1ec2f3;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
  pointer-events: auto ! important;
  cursor: not-allowed ! important;
}

.action_container span {
  color: #212529;
  font-size: 14px;
  font-weight: 600;
  padding-left: 12px;
  display: inline-block;
  width: 115px;
}

.scores-input::-webkit-outer-spin-button,
.scores-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.scores-input {
  width: 80px;
  margin-left: auto;
  margin-right: auto;
}

.radio-invoice-selection {
  display: flex;
  column-gap: 10px;
}

.radio-invoice {
  display: flex;
  column-gap: 30px;
}

.banktransfer-textarea {
  min-height: 100px;
}

.bank_transfer_number::-webkit-outer-spin-button,
.bank_transfer_number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-arrow-number::-webkit-outer-spin-button,
.no-arrow-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.terms-po-number {
  width: 200px !important;
  display: inline-block !important;
  margin-left: 10px;
}

canvas.sigCanvas {
  border: 1px solid #CED4DA;
  width: 100%;
}

.file-upload-image {
  width: 100%;
  max-width: 80px;
}

.add-upload-file .card {
  border: 1px solid #DADADA;
  box-shadow: none;
}

.file-upload {
  text-align: center;
  box-shadow: none;
  display: flex;
  /* justify-content: center;
  align-items: center;
  align-content: center; */
  border: none;
  width: 100%;
  min-height: 350px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='1' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.upload_label {
  margin-top: 90px;
  cursor: pointer;
}

.product-image-bg {
  width: 100%;
  height: 100%;
  min-height: 163px;
  background-position: center;
  /* background-size: cover; */
  background-size: contain;
  max-height: 163px;
  position: relative;
  background-repeat: no-repeat;
}

.media-library-image-bg {
  width: 100%;
  height: 400px;
  min-height: 200px;
  background-position: center;
  /* background-size: cover; */
  background-size: contain;
  position: relative;
  background-repeat: no-repeat;
}

.ticket-image-bg {
  width: 100%;
  height: 100%;
  min-height: 30px;
  background-position: center;
  /* background-size: cover; */
  background-size: contain;
  max-height: 30px;
  position: relative;
  background-repeat: no-repeat;
}

#admin-gallery-upload {
  max-width: 900px;
}

.product-image-bg.admin {
  min-height: 189px;
  max-height: 189px;
  background-size: cover;
}

.image-item {
  // max-height: 163px;
  max-height: 243px;
}

.remove-product-image {
  right: 0px;
  top: 0px;
  color: #fff;
  background: red;
  border-radius: 50px;
  width: 25px;
  height: 25px;
  padding: 5px;
}

.remove-vimeo-field {
  position: absolute;
  right: 5px;
  margin-top: -45px;
  z-index: 1;
  cursor: pointer;
}

.bis-tooltip-vimeo.table-tooltip .bis-tooltiptext-vimeo {
  width: 250px;
  left: -20px;
  bottom: 105%;
}

.bis-tooltip-vimeo {
  position: relative;
  display: inline-block;
  font-weight: 600;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 0.25rem;
  cursor: pointer;
  // border-bottom: 1px dotted black;
}

.bis-tooltip-vimeo .bis-tooltiptext-vimeo {
  visibility: hidden;
  width: 160px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
  font-weight: 600;
  font-size: 14px;
}

.bis-tooltip-vimeo .bis-tooltiptext-vimeo::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.bis-tooltip-vimeo:hover .bis-tooltiptext-vimeo {
  visibility: visible;
}

.action_table_container {
  position: absolute;
  top: 15px;
  right: 15px;
  width: auto;
}

.profile-picture-container {
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 500px;
}

.divider-small {
  width: 50px;

  background: #1ec2f3;
  margin-bottom: 15px;
  margin-top: 10px;
}

.iframe-card {
  height: 1000px;
}

.collateral-folder {
  width: 100%;
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.collateral-pdf {
  width: 100%;
  max-width: 90px;
  margin-left: auto;
  margin-right: auto;
}

.collateral-folder-container {
  max-width: 54rem;
  min-height: 3rem;
}

.collaterals {
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 115px;
  max-height: 115px;
  cursor: pointer;
}

.collaterals-overlay-container {
  position: relative;
  width: 100%;
  max-height: 170px;
  min-height: 170px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.collateral-label {
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  padding: 5px 10px;
  color: #000;
  font-size: 12px;
}

.collateral-pdf-iframe {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  height: 500px;
  /* 16:9 Aspect Ratio */
}

.collateral-image-iframe {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio */
}

.modal .artwork-add-button {
  background: #fff !important;
  color: #000;
  border-radius: 0.25rem;
}

.card-body .artwork-add-button {
  background: #fff !important;
  color: #000;
  border-radius: 0.25rem;
}

.artwork-add-icon {
  font-size: 25px;
}

.artwork-title {
  color: #287EC3;
}

// .artwork-table-data {
//   margin-top: -0.75rem !important;
// }

.add-artwork-modal label {
  font-weight: bold;
}

.edit-artwork label {
  font-weight: bold;
}

.artwork-type-submit-buttons {
  color: #fff;
  background-color: #1ec2f3;
  border: 1px solid #dfdfdf;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
}

//
.artwork-head-divider {
  border: 1px solid #DFDFDF;
  position: absolute;
  left: 50%;
  right: 50%;
  top: 0;
  width: 1px;
  height: 100%;
  border-right: 0;
}

.timeline-artwork {
  position: relative;
}

.timeline-artwork::after {
  content: '';
  position: absolute;
  width: 2px;
  background-color: #b5aaaa;
  top: 0;
  bottom: 11px;
  left: 6px;
}

.timeline-artwork .timeline-container {
  padding-left: 5px;
  position: relative;
  background-color: inherit;
}

.timeline-artwork .timeline-container::after {
  content: '';
  position: absolute;
  width: 11px;
  height: 11px;
  right: -16px;
  // background-color: #000;
  background-color: #b5aaaa;
  // top: 10px;
  top: 6px;
  border-radius: 50%;
  z-index: 1;
}

.timeline-artwork .right {
  left: 5%;
  margin-bottom: 12px;
  width: 95%;
}

.collateral-logs-btn {
  float: right;
  position: absolute;
  top: 13px;
  right: 50px;
}

.timeline-artwork .right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

.timeline-artwork .right::after {
  // left: -17px;
  left: -5%;
}

.timeline-artwork .content {
  background-color: white;
  padding-top: 3px;
  padding-bottom: 3px;
}

.timeline-card {
  padding: 5px 10px;
  z-index: 9;
  margin-bottom: 12px;
  background-color: #efefef;
}

.status-artwork-show {
  min-width: 201px;
  position: absolute;
  z-index: 9;
  top: 40px;
  text-align: left;
}

.status-artwork-show p:last-child {
  margin-bottom: 0;
}

.status-artwork-show p {
  margin-bottom: 5px;
}


.artwork-assigned-box {
  background: gold;
  outline: none;
  border-radius: 3px;
  width: 20px;
  height: 20px;
}

.artwork-design-revision-box {
  background: #E8500D;
  outline: none;
  border-radius: 3px;
  width: 20px;
  height: 20px;
}

.artwork-design-review-box {
  background: #8E7CC3;
  outline: none;
  border-radius: 3px;
  width: 20px;
  height: 20px;
}

.artwork-approved-box {
  background: #1ec2f3;
  outline: none;
  border-radius: 3px;
  width: 20px;
  height: 20px;
}

.artwork-print-revision-box {
  background: #a73807;
  outline: none;
  border-radius: 3px;
  width: 20px;
  height: 20px;
}

.artwork-for-printing-box,
.artwork-printing-in-progress-box {
  background: #CF9905;
  outline: none;
  border-radius: 3px;
  width: 20px;
  height: 20px;
}

.artwork-done-box {
  background: #049818;
  outline: none;
  border-radius: 3px;
  width: 20px;
  height: 20px;
}

.artwork-for-approval-box {
  background: #D80BB6;
  outline: none;
  border-radius: 3px;
  width: 20px;
  height: 20px;
}


.artwork-design-in-progress-box {
  background: #A9D153;
  outline: none;
  border-radius: 3px;
  width: 20px;
  height: 20px;
}

.page-plan-item {
  //width: 100px;
  //height: 100px;
  max-width: 100%;
  height: 150px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.modal-select-collateral {
  min-height: 150px;
}

.artwork-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.artwork-tooltip .artwork-tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}

.artwork-tooltip .artwork-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.artwork-tooltip:hover .artwork-tooltiptext {
  visibility: visible;
}

.more-designs {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  text-align: center;
  font-size: 40px;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  padding-top: 45px;
}

.collateral-image-item {
  max-height: 235px;
}

.page-plan-card .card {
  width: 100%;
  user-select: none;
  height: 220px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  background-color: #ffffff;
  border-radius: 5px;
  z-index: 1;
  overflow: hidden;
}

.page-plan-card .card .card_header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.page-plan-card .card .card_header h1 {
  font-size: 22px;
  color: #0a253f;
}

.page-plan-card .card .card_header p {
  font-size: 16px;
  padding: 10px 20px 0 0;
  color: #4a4970;
}

.page-plan-card .card .content-image .fa {
  padding: 7px 10px;
  font-size: 25px;
  border-radius: 50px;
  color: #ffffff;
  background-color: #025aa5;
}

.page-plan-card .card_info {
  color: #0a253f;
  font-size: 14px;
  font-weight: 500;
  display: flex;

}

.page-plan-card .card_info p {
  margin: 15px 10px;
}

.page-plan-card .card_info span {
  color: #4a4970;
  margin-right: 10px;
}

.page-plan-card .card .card_footer {
  padding: 10px 20px;
  align-items: center;
  background-color: #F0F4F7;
  display: flex;
  justify-content: space-between;
  border-top: 3px solid #025aa5;

}

.page-plan-card .card .card_footer .btn {
  width: 100px;
  font-size: 16px;
  height: 30px;
  padding: 0 10px;
  border: none;
  outline: none;
  color: #fff;
  background: #025aa5;
  cursor: pointer;
  border-radius: 5px;
}

.page-plan-card .card_footer button span {
  margin-left: 10px;
}

.page-plan-names {
  font-size: 17px;
}

.page-plan-divider {
  width: 1px;
  max-height: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 1px;
  background: #EFEFEF;
}

.collateral-upload-container {
  min-height: 60px;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
}

.remove-page-collateral {
  right: 1px;
  top: 1px;
  color: #fff;
  background: red;
  border-radius: 50px;
  width: 15px;
  height: 15px;
  padding: 3px;
}

.add-page-collateral-btn {
  display: flex;
  align-items: center;
  padding-bottom: 21px;
}

.page-collateral-container {
  min-height: 135px;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
}

.collateral-document-file {
  min-height: 125px;
  max-height: 125px;
  margin: 10px 0;
}

.design-document-file {
  min-height: 153px;
  max-height: 153px;
  // margin: 10px 0;
}

.collateral-video {
  width: 100%;
  object-fit: cover;
}

.remove-collateral-icon {
  right: -10px;
  top: -6px;
  color: #fff;
  background: red;
  border-radius: 50px;
  width: 25px;
  height: 25px;
  padding: 5px;
}

.page-plan-assignee {
  display: flex;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
}

.script-header {
  background-color: #F8F8F8;
  font-size: 17px;
}

.active-element {
  background-color: #1EC2F3;
  color: #fff;
}

.inactive-element {
  background-color: #F8F8F8;
  color: #000;
}

.event-container {
  position: relative;
}

.event-card {
  display: block;
  width: 100%;
}

.event-body {
  height: 118px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.event-script {
  height: 125px;
}

.event-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: auto;
  opacity: 0;
  transition: 0.5s ease;
  // background-color: #DFDFDF;
  background-color: #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.event-container:hover .event-overlay {
  opacity: 1;
}

.event-container:hover .newsletter-overlay-button {
  opacity: 1;
}

.event-choose-btn {
  background: #1EC2F3 0% 0% no-repeat padding-box !important;
  border-radius: 3px;
  opacity: 1;
  width: 160px;
}

.event-preview-btn {
  background: none !important;
  border: 1px solid #1EC2F3 !important;
  border-radius: 3px;
  opacity: 1;
  width: 160px;
  color: #1EC2F3 !important;
}

.faq-add-button {
  background: #dfdfdf !important;
  color: #000;
  border-radius: 0.25rem;
}

.script-editor {
  max-height: 672px;
  min-height: 672px;
  background: #fff;
  border: 1px solid #dfdfdf;
  padding: 0 25px;
}

.blocked-mail-editor {
  max-height: 280px;
  min-height: 280px;
  background: #fff;
  border: 1px solid #dfdfdf;
  padding: 0 25px;
}

.entry-guide-editor {
  max-height: 400px;
  min-height: 400px;
  background: #fff;
  border: 1px solid #dfdfdf;
  padding: 0 25px;
}

.event-guide-editor {
  max-height: 400px;
  min-height: 400px;
  background: #fff;
  border: 1px solid #dfdfdf;
  padding: 0 25px;
}

.cms-editor {
  max-height: 300px;
  min-height: 300px;
  background: #fff;
  border: 1px solid #dfdfdf;
  padding: 0 25px;
}

.cms-menu-list {
  list-style-type: none;
}

.cms-menu-card {
  background-color: #f9f9f9;
}

.cms-menu-card .p {
  font-weight: 600;
}

.delete-feature-input {
  position: absolute;
  top: 10px;
  right: 25px;
  background: red;
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 3px;
}

.delete-feature-spinner {
  position: absolute;
  top: 10px;
  right: 25px;
  background: #fff;
  color: red;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 3px;
}

.featured-list-paragraph {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.featured-list-paragraph svg {
  color: #02B902;
}

.delete-list-paragraph svg {
  color: red;
}

.newbtntheme-grey {
  background-color: #ECEDEF;
  border: 1px solid #dfdfdf;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
}

.newbtntheme-grey:hover {
  color: #212529;
  background: #fff;
}

.newbtntheme-grey:focus {
  outline: none;
}

.sponsor-hand-icon {
  width: 100%;
  max-width: 24px;
  margin-right: 7px;
  height: 24px;
  color: #1ec2f3 !important;
}

.sponsor-event {
  display: flex;
  padding: 10px;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
}

.sponsor-event svg {
  color: #1ec2f3;
}

.sponsor-search-wrapper {
  position: relative;
}

.sponsor-search-icon {
  padding: 4px;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 93%;
  color: #495057;
  transform: translateY(-50%);
}

.sponsor-search-input {
  box-sizing: border-box;
}

.sponsor-name {
  color: #1ec2f3;
}

.sponsors-logo {
  min-height: 80px;
  width: 80px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.sponsor-type {
  color: #f0a728;
  font-weight: 700;
  font-size: 20px;
}

.agreement-list {
  padding-left: 25px;
}

.agreement-list li {
  font-size: 16px;
  // list-style-image: url(../assets/img/icons/check-green-icon.png);
  padding-left: 10px;

}

.agreement-list-header {
  font-weight: 600;
  margin-bottom: 15px;
}

.agreement-list li::marker {
  content: url(../assets/img/icons/check-green-icon-resizes.png);
  // content: '';
  display: inline-block;
  height: 12px;
  width: auto;
  margin-right: 10px;
}

.upgrade-package {
  background-image: url(../assets/img/icons/your-sponsorship-agreement.png);
  background-position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
}

.upgrade-package-text {
  font-size: 18px;
}

.upgarade-package-button {
  background-color: #1EC2F3;
  color: #fff;
  border: none;
  padding: 5px;
  border-radius: 5px;
  width: 50%;
  font-size: 18px;
}

.upgarade-package-button:hover {
  background-color: #01bef5;
}

.sponsor-person {
  font-weight: 600;
  margin-bottom: 0px;
  font-size: 14px;
}

.sponsor-position {
  color: #287FC1;
  margin-bottom: 0px;
  font-size: 14px;
}

.sponsor-contact {
  margin-bottom: 0px;
  // display: flex;
  // align-items: center;
}

.sponsor-contact img {
  height: 16px;

}

.sponsor-phone {
  margin-bottom: 0px;
}

.sponsor-phone a {
  font-size: 14px;
}

.sponsor-phone a:hover {
  text-decoration: none;
}

.sponsor-phone img {
  margin-right: 8px;
}

.sponsor-email {
  margin-bottom: 0px;
}

.sponsor-email a {
  font-size: 14px;
}

.sponsor-email a:hover {
  text-decoration: none;
}

.sponsor-email img {
  margin-right: 8px;
}

.sponsorship-management p {
  font-size: 16px;
}

.management-list {
  display: flex;
  align-items: center;
}

.management-list img {
  width: 40px;
  margin-right: 15px;

}

.management-list p {
  font-size: 16px;
}

.list-image img {
  width: 200px;
  height: auto;
}

.activation-items {
  font-weight: 700 !important;
  font-size: 18px;
  color: #1ec2f3;
}

.sponsor-icon img {
  border-radius: 100%;
  width: 64px;
}

.sponsor-profile p,
label {
  font-weight: 600;
}

.sponsored-categories {
  min-height: 36px;
}

.newsletter-button {
  background-color: #277dc2;
  padding: 7px;
  color: #fff;
  border: none;
  border-radius: 5px;
  width: 75%;
}

.management-sponsor-save-btn {
  background-color: #277dc2;
  padding: 7px;
  color: #fff;
  border: none;
  border-radius: 5px;
  width: 75%;
}

.newsletter h2 {
  font-size: 18px;
  font-weight: 600;
}

.sponsored-categories-dropdown {
  position: absolute;
  z-index: 9;
  top: 100%;
  text-align: left;
  overflow-y: scroll;
  height: 150px;
}

.contact-bg {
  background-color: #f2f2f2;
}

.contact-details img {
  height: 16px;
}

.sponsorship-management h2 {
  font-size: 16px;
}

.newsletter-area {
  height: 400px;
}

.event-info-collaterals h2 {
  font-weight: 600;
  color: #1ec2f3;
}

.tab-header-active {
  background-color: #fff;
  height: 50px;
  width: 100%;
}

.tab-header {
  background-color: #fff;
  height: 50px;
  width: 100%;

}

.tab-contents {
  height: 300px;
}

.list-image img {
  width: 250px;
  height: auto;
}

.contact-details {
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}

.contact-bg {
  background-color: #f2f2f2;
}

.contact-details img {
  height: 16px;
}

.sponsor-contact {
  margin-left: 15px;
}

.tab-icon {
  width: 18px;
  margin-right: 10px;
}

.tab-blank {
  height: 300px;
  width: 100%;
  background-color: #fff;
}

.sponsor-navigations .card {
  font-weight: 700;
  font-size: 17px;
  text-align: center;
  display: block;
  //cursor: pointer;
  background: #fff;
  padding: 13px;
}

.sponsor-navigations .active-tab {
  background-color: #fff;
}

.sponsor-navigations .inactive-tab {
  background-color: #ebebeb;
}

.attendants {
  background-color: #fff !important;
}

.dropdown {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.activity-comment-body {
  border-radius: 0px 3px 3px 0px;
  border-top: 0px;
  border-right: 0px;
  border-bottom: 0px;
  height: 100%;
}

.newsletter-image {
  flex: 1 1 auto;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.newsletter-image-url {
  min-height: 63px;
  min-width: 113px;
  max-height: 63px;
  max-width: 113px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.newsletter-overlay-button {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: auto;
  opacity: 0;
  transition: 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.company-logo {
  position: relative;
  max-height: 95px;
  min-height: 95px;
  min-width: 95px;
  max-width: 95px;
  //border-radius: 50%;
  background-size: cover;
  background-position: center;
}

.sponsor-home-logo {
  position: relative;
  height: 150px;
  width: auto;
  //border-radius: 50%;
  background-size: cover;
  background-position: center;
}

.judge-profile-image {
  width: 200px;
  height: 200px;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.delete-stakeholders {
  background: red;
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 3px;
}

.copy-to-clipboard:hover {
  color: #1ec2f3;
}

.sponsor-tabs-svg {
  filter: brightness(0);
}

.select-test {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.select-test:focus {
  color: #495057;
  background-color: #fff;
  border-color: #97e3f9;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #C7F0FC;
}

.remove-table {
  right: 18px;
  top: 3px;
  color: #fff;
  background: red;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  padding: 5px;
}

.floor-plan-font {
  // font-family: 'Dancing Script', cursive;
  // font-size: 100px;
}

.floor-plan-font-divider {
  border-bottom: 3px solid #000;
  width: 100%;
  height: 3px;
}

.floor-plan-subfont {
  font-size: 30px;
  margin-top: 12px;
}

.floor-plan-font span {
  font-size: 60px;
  font-weight: 700;
}

/* for survey form tooltip */

.tooltip-survey {
  position: relative;
  display: inline-block;
}

.tooltip-survey .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 110%;
  left: 47%;
  margin-left: -60px;
}

.tooltip-survey .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip-survey:hover .tooltiptext {
  visibility: visible;
}

/* end of for survey form tooltip */

.comment-data {
  white-space: pre-line;
}

.comment-area {
  resize: auto;
  max-width: 100%;
  min-width: 100%;
}

.alert-warning.bg-lightgray {
  background: #f2f2f2;
  color: #212529;
  border-color: #ddd;
}

.alert-warning.bg-lightgray hr {
  border-top-color: #ddd !important;
}

.trophy-image {
  height: auto;
  width: 100%;
  background-size: cover;
  background-position: 50%;
  position: relative;
  border-color: #dfdfdf;
  border-style: solid;
}

.quill .ql-container {
  height: 146px;
}

.ql-align-center {
  text-align: center;
}

.part-content-preview {
  max-height: 400px;
  overflow-y: auto;
}

/* for notifications page */
.notification-options-container {
  display: flex;
  justify-content: end;
  column-gap: 10px;
}

.test-container {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
/* end of for notifications page */

/* Icons */
/* Define the keyframes for the rotating animation */
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Apply the rotating animation to the icon */
.icon-loading {
  animation: rotate 1s linear infinite;
}

.invoice-logs ul {
  margin-bottom: 0;
}

.rotate {
  animation: rotate 1.5s linear infinite;
}

.award-cms-color {
  width: 100%;
  height: 30px;
  border-radius: 2px;
  border: 1px solid rgba(221, 221, 221, 0.867);
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.entrant-chat-widget {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #1ec2f3;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 50%;
  width: 75px;
  height: 75px;
}

.entrant-chat-widget-icon {
  font-size: 40px;
  position: relative;
  top: 18px;
  left: 18px;
  color: #fff;

  &.new-message {
    left: -2px !important;
  }
}

.entrant-chat-widget:hover {
  animation-name: background-color;
  animation-duration: 2000ms;
  background-color: #fff !important;
}

.entrant-chat-widget:hover .entrant-chat-widget-icon{
  color: #1ec2f3 !important;
}
.entrant-chat-widget-container {
  position: fixed;
  z-index: 1;
  bottom: 100px;
  right: 20px;
  opacity: 0;
  transition: all .2s;
  visibility: hidden;
  width: 372px;
  height: calc(100% - 143px);
  max-height: 520px;

  &.chat-container-show {
    opacity: 1;
    visibility: visible;
  }
}

.entrant-chat-widget-container .comment-area {
  height: auto !important;
  resize: none !important;
}

.entrant-chat-widget-container .entrant-chat-widget-container-card .chat-container {
  overflow-y: scroll;
}

.chat-widget-notif {
  bottom: -3px;
  left: 55px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid rgb(255, 255, 255);
  top: -10px;
  display: none;
  justify-content: center;
  align-items: center;

  &.new-message {
    display: inline-flex;
  }
}

.rdw-embedded-modal, .rdw-link-modal {
  height: 300px !important;
}

.dot-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot-admin {
  background-color: #1ec2f3 !important;
}

.dot-stakeholder {
  background-color: #ffd700 !important;
}

.bg-new-gray {
  background-color: #f9f8fa !important;
}

.wizard-cont {
  width: 100%;
}

.progress-vertical-lines {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  // margin-top: 5px;
}

// .progress-vertical-lines .top-line {
//   margin-top: 5px;
// }

.progress-vertical-line {
  margin-top: 11px;
  width: 5px;
  height: 38px;
  max-height: 70px !important;
  background-color: #eee;
  position: relative;
  z-index: 1;
  margin-left: 9px;
}

.progress-line-active {
  background-color: #1ec2f3 !important;
}

.project-progress-container {
  margin: 0 0 24px 0;
}

.project-progress-circle {
  width: 23px;
  height: 23px;
  border: 2px solid #eee;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.project-progress-check {
  border: 2px solid #1ec2f3 !important;
  background-color: #1ec2f3 !important;
}

.project-progress-active {
  border: 2px solid #1ec2f3 !important;
  background-color: #ffffff !important;
}

.project-progress-solid {
  width: 16px !important;
  height: 16px !important;
  border-radius: 50% !important;
  background-color: #1ec2f3 !important;
}

.project-progress-number {
  font-size: 12px !important;
  color: #9c9c9c;
  font-weight: bold;
}

.project-progress-contents {
  position: absolute;
  top: 1;
  width: 100%;
  z-index: 999;
  // display: none;
}

.project-status-inactive {
  color: #9c9c9c;
}

.project-status-check {
  color: #000000;
}

.project-status-active {
  color: #000000;
  font-weight: bold;
}

.project-card-head-border {
  border-top: 5px solid #1ec2f3;
}

.text-transform-none {
  text-transform: none !important;
}

.border-success {
  border: 1px solid #45b64985 !important;
}

.uploaded-project-file {
  // border: 1px solid #9c9c9c;
  border-radius: 10px;
  width: 105px;
  height: 105px;
  max-width: 190px;
  max-height: 190px;
  background-position: center;
  background-size: cover;
}

.uploaded-project-file-add {
  border: 1px solid #9c9c9c;
  border-radius: 10px;
  width: 105px;
  height: 105px;
  max-width: 190px;
  max-height: 190px;
  background-position: center;
  background-size: cover;
}

.uploaded-project-video {
  // border: 1px solid #9c9c9c;
  // border-radius: 10px;
  width: 105px;
  height: 105px;
  max-width: 190px;
  max-height: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.video-x-icon {
  position: absolute;
  margin-bottom: 80px !important;
}

.file-x-icon {
  position: absolute;
  margin-bottom: 80px !important;
  margin-left: -15px !important;
}

.border-dotted {
  border: 1px dashed #9c9c9c;
}

.project-file-upload {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #9c9c9c;
  cursor: pointer;
}

.project-fs-10 {
  font-size: 10px;
}

.project-fs-12 {
  font-size: 12px;
}

.project-fs-14 {
  font-size: 14px;
}

/* Define a keyframe animation for spinning */
@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

/* Apply the animation to the .loading class */
.upload-file-loading {
  animation: spin 4s linear infinite;
}

.equal-height-col {
  display: flex !important;
  flex-direction: column !important;
}

/* Project invoice file upload */
.invoice-file-uploader {
  width: 100% !important;
  height: 100% !important;
  border: 1px dashed #6c757da6 !important;
  border-radius: 5px !important;
}

.text-gray-new {
  color: #9c9c9c !important;
}

/* Project materials */ 
.project-material-links {
  font-size: 14px;
}

.project-material-links:hover {
  color: #00BEF4;
}

.tr-no-hover:hover {
  background-color: #ffffff !important;
}

.action_table_filter {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.action_table_filter .action_content_filter {
  // visibility: hidden;
  // width: 94px;
  width: 250px;
  padding: 0;
  right: 5px;
  top: 23px;
  position: absolute;
  z-index: 1;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.39);
}

.filter-selector-hover:hover {
  background-color: #eee !important;
}

.bg-white-bordered {
  border: 0.5px solid #dfdfdf !important;
  border-radius: 4px !important;
}

#sponsorship-agreements .table-container {
  overflow-x: scroll;
  overflow-y: hidden;
}

.ai-generator-container-bg {
  background: #f1f1f1;
}

.custom-gray-alert {
  padding: 0.45rem 1.25rem !important;
  background-color: #e5e5e5 !important;
  color: #4a4a4a !important;
}

.action-tooltip {
  position: relative;
}

.action-tooltip .action-tooltip-text {
  visibility: hidden;
  position: absolute;
  width: 127px;
  background-color: #555;
  bottom: 33px;
  left: -53px;
  color: #fff;
  text-align: center;
  padding: 5px;
  font-size: 16px;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity .6s;
}

.action-tooltip:hover .action-tooltip-text {
  visibility: visible;
  opacity: 1;
}

.bookings-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: fit-content;
}

.bookings-header div {
  text-transform: none;
}

.booking-header-table-option {
  text-align-last: center;
  font-weight: 700;
  color: #212529;
}

.steps-progress .feedback-form-steps-container-size {
  width: 60px;
  height: 60px;
}

.steps-progress .feedback-form-steps-number-size {
  font-size: 38px;
}

.upsell-item-description p:last-of-type {
  margin-bottom: 0px;
}

.sponsor-logo-row {
  vertical-align: middle;
  align-items: center;
  justify-content: center;
}

.sponsor-logo-container {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  min-height: 100px;
  // box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.35);
  // -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.35);
  // -moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.35);
  border: 1px solid rgba(0,0,0,0.35);
  border-radius: 10px;
  margin-bottom: 30px;
  width: 100%;

}

.sponsor-logo-container .sponsor-img {
  padding: 10px;
}

.email-signature-logo {
  max-width: 300px;
}

.email-signature-header {
  margin-bottom: 30px;
}

#bis_award_tab .span-percent {
  position: absolute;
  right: 0px;
  top: -16px;
  left: 0px;
  color: rgb(0, 0, 0);
  font-size: 12px !important;
}

#bis_award_tab #sponsorship-agreements .table th {
  vertical-align: top;
}

#bis_award_tab #sponsorship-agreements .bis-tooltip .bis-tooltiptext {
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -100px;
  font-weight: 600;
  font-size: 14px;
}

#bis_award_tab #progress_bar_judging .dashboard-progress-bar {
  position: relative; 
  margin-top: 19px; 
  overflow: visible !important;
}

#bis_award_tab .action_table .action_content_sponsor_dashboard_company_name {
  // visibility: hidden;
  // visibility: visible;
  width: 255px;
  padding: 0;
  right: 15px;
  top: 5px;
  position: absolute;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.39);
}

#bis_award_tab .action_table .action_content_sponsor_dashboard_package {
  width: 255px;
  padding: 0;
  right: 15px;
  top: 5px;
  position: absolute;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.39);
}

#bis_award_tab .action_table .action_content_sponsor_dashboard_registered_date {
  width: 270px;
  padding: 0;
  right: 15px;
  top: 5px;
  position: absolute;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.39);
  z-index: 1;
}

#bis_award_tab .action_table .action_content_sponsor_dashboard_package td:hover {
  background-color: rgba(0, 0, 0, .075);
}

#bis_award_tab .sponsorship_pagination {
  position: absolute;
  right: 22px;
  bottom: 33px;
}

.sponsorship_image {
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: 50px;
  padding-bottom: 50px;
  min-height: 400px;
}

.venue-plan-background {
  position: absolute; /* Position the image absolutely within the container */
  top: 0;
  left: 0;
  width: 100%; /* Make the image cover the full width of the container */
  height: 100%; /* Make the image cover the full height of the container */
  object-fit: cover; /* Ensure the image covers the container while preserving aspect ratio */
}

.switch-category {
  position: relative;
  display: inline-block;
  width: 41px;
  height: 18px;
  border: none !important;
}

.switch-category input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-category {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider-category:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 5px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider-category {
  background-color: #5ED55E;
}

input:checked + .question-category {
  background-color: #1EC2F3 !important;
}

input:focus + .slider-category {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider-category:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(20px);
}

.slider-category.round-category {
  border-radius: 34px;
}

.slider-category.round-category:before {
  border-radius: 50%;
}

.loading-category {
  opacity: 0.5;
  pointer-events: none;
}

.booking-pricing-settings-nav {
  font-size: 16px;
  border-radius: 5px 5px 0 0;
  border-bottom: 0px !important;
  position: relative;
  z-index: 1;
}

.font-weight-medium {
  font-weight: 500;
}

#hero-bussiness-river .bis_login_form .booking-confirmation-h1 {
  color: #1EC2F3;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.6;
  margin-top: 10px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

#hero-bussiness-river .bis_login_form .bis-link {
  color: #1ec2f3;
  text-decoration: none;
}

#hero-bussiness-river .bis_login_form .btn-secondary {
  background: #1ec2f3 !important;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  max-width: 500px;
}

#hero-bussiness-river .bis_signup_form .booking-confirmation-h1 {
  color: #1EC2F3;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.6;
  margin-top: 10px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

#hero-bussiness-river .bis_signup_form input {
  max-width: 100% !important;
}

#hero-bussiness-river .bis_signup_form .btn-secondary {
  background: #1ec2f3 !important;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  max-width: 100%;
}
#hero-bussiness-river .bis_signup_form.container {
  max-width: 700px;
}

#hero-bussiness-river .card_bg_white {
  background: #ffffff;
  padding: 30px 10px 30px 10px;
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-left: 0;
  margin-right: 0;
}

#hero-bussiness-river .bis_signup_form h3 {
  font-weight: 700;
  font-size: 17px;
  margin-top: 30px;
  margin-left: 0;
}

.remove-custom-logo {
  position: absolute;
  right: 10px;
  top: 10px;
  color: red;
  cursor: pointer;
  z-index: 9;
}

.btn-outline-dark-prev,
.btn-outline-dark-prev:hover,
.btn-outline-dark-prev:focus {
    color: #393C41;
    border-color: #393C41;
    background-color: #ffffff;
}

.btn-blue-new,
.btn-blue-new:hover,
.btn-blue-new:focus-visible
.btn-blue-new:active {
    background-color: #1193ba !important;
    border-color: #1193ba !important;
    color: #ffffff !important;
}

.circle-icon {
  background-color: #FFFFFF;
  border-radius: 50%;
}

// for displaying of entries questions and answers in view entrant submission page.

.questions-answers-list {
  padding-left: 20px;
}

.questions-answers-list li {
  margin-bottom: 5px;
}