@charset "UTF-8";
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import url(https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i);
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap");
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #1ec2f3;
  --secondary: #212529;
  --success: #45b649;
  --info: #00c9ff;
  --warning: #ffd700;
  --danger: #f85032;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #1ec2f3;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0a92bb;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c0eefc; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #8adff9; }

.table-hover .table-primary:hover {
  background-color: #a8e7fb; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a8e7fb; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c1c2c3; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #8c8e90; }

.table-hover .table-secondary:hover {
  background-color: #b4b5b6; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #b4b5b6; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cbebcc; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #9ed9a0; }

.table-hover .table-success:hover {
  background-color: #b9e4ba; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b9e4ba; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8f0ff; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #7ae3ff; }

.table-hover .table-info:hover {
  background-color: #9febff; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #9febff; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff4b8; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffea7a; }

.table-hover .table-warning:hover {
  background-color: #fff09f; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fff09f; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fdcec6; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #fba494; }

.table-hover .table-danger:hover {
  background-color: #fcb9ad; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #fcb9ad; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #97e3f9;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(30, 194, 243, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #45b649; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(69, 182, 73, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #45b649;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2345b649' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #45b649;
    box-shadow: 0 0 0 0.2rem rgba(69, 182, 73, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #45b649;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2345b649' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #45b649;
    box-shadow: 0 0 0 0.2rem rgba(69, 182, 73, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #45b649; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #45b649; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #45b649; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #68c66b;
  background: #68c66b linear-gradient(180deg, #7fce82, #68c66b) repeat-x; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(69, 182, 73, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #45b649; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #45b649; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #45b649;
  box-shadow: 0 0 0 0.2rem rgba(69, 182, 73, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f85032; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(248, 80, 50, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #f85032;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f85032' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f85032' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #f85032;
    box-shadow: 0 0 0 0.2rem rgba(248, 80, 50, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #f85032;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f85032' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f85032' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #f85032;
    box-shadow: 0 0 0 0.2rem rgba(248, 80, 50, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #f85032; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #f85032; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #f85032; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #fa7a63;
  background: #fa7a63 linear-gradient(180deg, #fa8e7b, #fa7a63) repeat-x; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(248, 80, 50, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f85032; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #f85032; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #f85032;
  box-shadow: 0 0 0 0.2rem rgba(248, 80, 50, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(30, 194, 243, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #212529;
  background: #1ec2f3 linear-gradient(180deg, #40cbf5, #1ec2f3) repeat-x;
  border-color: #1ec2f3; }
  .btn-primary:hover {
    color: #fff;
    background: #0caedf linear-gradient(180deg, #30bae4, #0caedf) repeat-x;
    border-color: #0ba5d3; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(30, 170, 213, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #212529;
    background-color: #1ec2f3;
    border-color: #1ec2f3;
    background-image: none; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0ba5d3;
    background-image: none;
    border-color: #0b9bc7; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(30, 170, 213, 0.5); }

.btn-secondary {
  color: #fff;
  background: #212529 linear-gradient(180deg, #424649, #212529) repeat-x;
  border-color: #212529; }
  .btn-secondary:hover {
    color: #fff;
    background: #101214 linear-gradient(180deg, #343537, #101214) repeat-x;
    border-color: #0a0c0d; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #212529;
    border-color: #212529;
    background-image: none; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #0a0c0d;
    background-image: none;
    border-color: #050506; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5); }

.btn-success {
  color: #fff;
  background: #45b649 linear-gradient(180deg, #61c164, #45b649) repeat-x;
  border-color: #45b649; }
  .btn-success:hover {
    color: #fff;
    background: #3a9a3e linear-gradient(180deg, #58a95b, #3a9a3e) repeat-x;
    border-color: #37913a; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(97, 193, 100, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #45b649;
    border-color: #45b649;
    background-image: none; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #37913a;
    background-image: none;
    border-color: #338836; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(97, 193, 100, 0.5); }

.btn-info {
  color: #fff;
  background: #00c9ff linear-gradient(180deg, #26d1ff, #00c9ff) repeat-x;
  border-color: #00c9ff; }
  .btn-info:hover {
    color: #fff;
    background: #00abd9 linear-gradient(180deg, #26b7de, #00abd9) repeat-x;
    border-color: #00a1cc; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 209, 255, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #00c9ff;
    border-color: #00c9ff;
    background-image: none; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #00a1cc;
    background-image: none;
    border-color: #0097bf; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 209, 255, 0.5); }

.btn-warning {
  color: #212529;
  background: #ffd700 linear-gradient(180deg, #ffdd26, #ffd700) repeat-x;
  border-color: #ffd700; }
  .btn-warning:hover {
    color: #212529;
    background: #d9b700 linear-gradient(180deg, #dec226, #d9b700) repeat-x;
    border-color: #ccac00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 188, 6, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffd700;
    border-color: #ffd700;
    background-image: none; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #ccac00;
    background-image: none;
    border-color: #bfa100; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 188, 6, 0.5); }

.btn-danger {
  color: #fff;
  background: #f85032 linear-gradient(180deg, #f96a51, #f85032) repeat-x;
  border-color: #f85032; }
  .btn-danger:hover {
    color: #fff;
    background: #f7300d linear-gradient(180deg, #f84f31, #f7300d) repeat-x;
    border-color: #ef2b08; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(249, 106, 81, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #f85032;
    border-color: #f85032;
    background-image: none; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ef2b08;
    background-image: none;
    border-color: #e32908; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(249, 106, 81, 0.5); }

.btn-light {
  color: #212529;
  background: #f8f9fa linear-gradient(180deg, #f9fafb, #f8f9fa) repeat-x;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background: #e2e6ea linear-gradient(180deg, #e6eaed, #e2e6ea) repeat-x;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    background-image: none; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    background-image: none;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background: #343a40 linear-gradient(180deg, #52585d, #343a40) repeat-x;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background: #23272b linear-gradient(180deg, #44474b, #23272b) repeat-x;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
    background-image: none; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    background-image: none;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #1ec2f3;
  border-color: #1ec2f3; }
  .btn-outline-primary:hover {
    color: #212529;
    background-color: #1ec2f3;
    border-color: #1ec2f3; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(30, 194, 243, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #1ec2f3;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #1ec2f3;
    border-color: #1ec2f3; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(30, 194, 243, 0.5); }

.btn-outline-secondary {
  color: #212529;
  border-color: #212529; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #212529;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5); }

.btn-outline-success {
  color: #45b649;
  border-color: #45b649; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #45b649;
    border-color: #45b649; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 182, 73, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #45b649;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #45b649;
    border-color: #45b649; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(69, 182, 73, 0.5); }

.btn-outline-info {
  color: #00c9ff;
  border-color: #00c9ff; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #00c9ff;
    border-color: #00c9ff; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 201, 255, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #00c9ff;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00c9ff;
    border-color: #00c9ff; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 201, 255, 0.5); }

.btn-outline-warning {
  color: #ffd700;
  border-color: #ffd700; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffd700;
    border-color: #ffd700; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 215, 0, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffd700;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffd700;
    border-color: #ffd700; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 215, 0, 0.5); }

.btn-outline-danger {
  color: #f85032;
  border-color: #f85032; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #f85032;
    border-color: #f85032; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 80, 50, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #f85032;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f85032;
    border-color: #f85032; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 80, 50, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #1ec2f3;
  text-decoration: none; }
  .btn-link:hover {
    color: #0a92bb;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background: #f8f9fa linear-gradient(180deg, #f9fafb, #f8f9fa) repeat-x; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background: #1ec2f3 linear-gradient(180deg, #40cbf5, #1ec2f3) repeat-x; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent;
    background-image: none; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #1ec2f3;
    background: #1ec2f3 linear-gradient(180deg, #40cbf5, #1ec2f3) repeat-x; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(30, 194, 243, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #97e3f9; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #c7f0fc;
    border-color: #c7f0fc; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #1ec2f3;
  background: #1ec2f3 linear-gradient(180deg, #40cbf5, #1ec2f3) repeat-x; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(30, 194, 243, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(30, 194, 243, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(30, 194, 243, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(30, 194, 243, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #97e3f9;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(30, 194, 243, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #97e3f9;
    box-shadow: 0 0 0 0.2rem rgba(30, 194, 243, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background: #e9ecef linear-gradient(180deg, #eceff1, #e9ecef) repeat-x;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(30, 194, 243, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(30, 194, 243, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(30, 194, 243, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background: #1ec2f3 linear-gradient(180deg, #40cbf5, #1ec2f3) repeat-x;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background: #c7f0fc linear-gradient(180deg, #d0f2fc, #c7f0fc) repeat-x; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background: #1ec2f3 linear-gradient(180deg, #40cbf5, #1ec2f3) repeat-x;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background: #c7f0fc linear-gradient(180deg, #d0f2fc, #c7f0fc) repeat-x; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background: #1ec2f3 linear-gradient(180deg, #40cbf5, #1ec2f3) repeat-x;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background: #c7f0fc linear-gradient(180deg, #d0f2fc, #c7f0fc) repeat-x; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1ec2f3; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #1ec2f3;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0a92bb;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(30, 194, 243, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #1ec2f3;
  border-color: #1ec2f3; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #212529;
  background-color: #1ec2f3; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #212529;
    background-color: #0ba5d3; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(30, 194, 243, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #212529; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #0a0c0d; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5); }

.badge-success {
  color: #fff;
  background-color: #45b649; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #37913a; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(69, 182, 73, 0.5); }

.badge-info {
  color: #fff;
  background-color: #00c9ff; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #00a1cc; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 201, 255, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffd700; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #ccac00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 215, 0, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #f85032; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #ef2b08; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 80, 50, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #10657e;
  background: #d2f3fd linear-gradient(180deg, #d9f5fd, #d2f3fd) repeat-x;
  border-color: #c0eefc; }
  .alert-primary hr {
    border-top-color: #a8e7fb; }
  .alert-primary .alert-link {
    color: #0a4151; }

.alert-secondary {
  color: #111315;
  background: #d3d3d4 linear-gradient(180deg, #dadada, #d3d3d4) repeat-x;
  border-color: #c1c2c3; }
  .alert-secondary hr {
    border-top-color: #b4b5b6; }
  .alert-secondary .alert-link {
    color: black; }

.alert-success {
  color: #245f26;
  background: #daf0db linear-gradient(180deg, #e0f2e0, #daf0db) repeat-x;
  border-color: #cbebcc; }
  .alert-success hr {
    border-top-color: #b9e4ba; }
  .alert-success .alert-link {
    color: #163a17; }

.alert-info {
  color: #006985;
  background: #ccf4ff linear-gradient(180deg, #d4f6ff, #ccf4ff) repeat-x;
  border-color: #b8f0ff; }
  .alert-info hr {
    border-top-color: #9febff; }
  .alert-info .alert-link {
    color: #004152; }

.alert-warning {
  color: #857000;
  background: #fff7cc linear-gradient(180deg, #fff8d4, #fff7cc) repeat-x;
  border-color: #fff4b8; }
  .alert-warning hr {
    border-top-color: #fff09f; }
  .alert-warning .alert-link {
    color: #524500; }

.alert-danger {
  color: #812a1a;
  background: #fedcd6 linear-gradient(180deg, #fee1dc, #fedcd6) repeat-x;
  border-color: #fdcec6; }
  .alert-danger hr {
    border-top-color: #fcb9ad; }
  .alert-danger .alert-link {
    color: #571c11; }

.alert-light {
  color: #818182;
  background: #fefefe linear-gradient(180deg, #fefefe, #fefefe) repeat-x;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background: #d6d8d9 linear-gradient(180deg, #dcdedf, #d6d8d9) repeat-x;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #1ec2f3;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #1ec2f3;
    border-color: #1ec2f3; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #10657e;
  background-color: #c0eefc; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #10657e;
    background-color: #a8e7fb; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #10657e;
    border-color: #10657e; }

.list-group-item-secondary {
  color: #111315;
  background-color: #c1c2c3; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #111315;
    background-color: #b4b5b6; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #111315;
    border-color: #111315; }

.list-group-item-success {
  color: #245f26;
  background-color: #cbebcc; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #245f26;
    background-color: #b9e4ba; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #245f26;
    border-color: #245f26; }

.list-group-item-info {
  color: #006985;
  background-color: #b8f0ff; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #006985;
    background-color: #9febff; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #006985;
    border-color: #006985; }

.list-group-item-warning {
  color: #857000;
  background-color: #fff4b8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #857000;
    background-color: #fff09f; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #857000;
    border-color: #857000; }

.list-group-item-danger {
  color: #812a1a;
  background-color: #fdcec6; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #812a1a;
    background-color: #fcb9ad; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #812a1a;
    border-color: #812a1a; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001)); }

.carousel-control-next {
  right: 0;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001)); }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #1ec2f3 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0ba5d3 !important; }

.bg-secondary {
  background-color: #212529 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #0a0c0d !important; }

.bg-success {
  background-color: #45b649 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #37913a !important; }

.bg-info {
  background-color: #00c9ff !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #00a1cc !important; }

.bg-warning {
  background-color: #ffd700 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ccac00 !important; }

.bg-danger {
  background-color: #f85032 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ef2b08 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-gradient-primary {
  background: #1ec2f3 linear-gradient(180deg, #40cbf5, #1ec2f3) repeat-x !important; }

.bg-gradient-secondary {
  background: #212529 linear-gradient(180deg, #424649, #212529) repeat-x !important; }

.bg-gradient-success {
  background: #45b649 linear-gradient(180deg, #61c164, #45b649) repeat-x !important; }

.bg-gradient-info {
  background: #00c9ff linear-gradient(180deg, #26d1ff, #00c9ff) repeat-x !important; }

.bg-gradient-warning {
  background: #ffd700 linear-gradient(180deg, #ffdd26, #ffd700) repeat-x !important; }

.bg-gradient-danger {
  background: #f85032 linear-gradient(180deg, #f96a51, #f85032) repeat-x !important; }

.bg-gradient-light {
  background: #f8f9fa linear-gradient(180deg, #f9fafb, #f8f9fa) repeat-x !important; }

.bg-gradient-dark {
  background: #343a40 linear-gradient(180deg, #52585d, #343a40) repeat-x !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #1ec2f3 !important; }

.border-secondary {
  border-color: #212529 !important; }

.border-success {
  border-color: #45b649 !important; }

.border-info {
  border-color: #00c9ff !important; }

.border-warning {
  border-color: #ffd700 !important; }

.border-danger {
  border-color: #f85032 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #1ec2f3 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0a92bb !important; }

.text-secondary {
  color: #212529 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: black !important; }

.text-success {
  color: #45b649 !important; }

a.text-success:hover, a.text-success:focus {
  color: #307f33 !important; }

.text-info {
  color: #00c9ff !important; }

a.text-info:hover, a.text-info:focus {
  color: #008db3 !important; }

.text-warning {
  color: #ffd700 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #b39700 !important; }

.text-danger {
  color: #f85032 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #d62707 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.Cal__Day__root {
  display: inline-block;
  box-sizing: border-box;
  width: 14.28571%;
  list-style: none;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  user-select: none; }

.Cal__Day__root.Cal__Day__enabled.Cal__Day__highlighted, .Cal__Day__root.Cal__Day__enabled:active, .Cal__Day__root.Cal__Day__enabled:hover {
  position: relative;
  z-index: 1; }

.Cal__Day__root.Cal__Day__enabled.Cal__Day__highlighted:before, .Cal__Day__root.Cal__Day__enabled:active:before, .Cal__Day__root.Cal__Day__enabled:hover:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 52px;
  height: 52px;
  margin-top: -26px;
  margin-left: -26px;
  border-radius: 50%;
  background-color: #EFEFEF;
  z-index: -1; }

.Cal__Day__root.Cal__Day__enabled:hover:before {
  opacity: 0.5; }

.Cal__Day__root.Cal__Day__enabled.Cal__Day__highlighted:before, .Cal__Day__root.Cal__Day__enabled:active:before {
  opacity: 1; }

.Cal__Day__root:first-child {
  position: relative; }

.Cal__Day__root.Cal__Day__today {
  position: relative;
  z-index: 2; }

.Cal__Day__root.Cal__Day__today > span {
  color: #3d3d3d; }

.Cal__Day__root.Cal__Day__today.Cal__Day__disabled > span {
  color: #AAA; }

.Cal__Day__root.Cal__Day__today:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 52px;
  height: 52px;
  margin-top: -26px;
  margin-left: -26px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px;
  z-index: -1; }

.Cal__Day__root.Cal__Day__today.Cal__Day__disabled:before {
  box-shadow: inset 0 0 0 1px #BBB; }

.Cal__Day__root.Cal__Day__selected {
  position: relative; }

.Cal__Day__root.Cal__Day__selected > .Cal__Day__month, .Cal__Day__root.Cal__Day__selected > .Cal__Day__year {
  display: none; }

.Cal__Day__root.Cal__Day__selected:before {
  display: none; }

.Cal__Day__root.Cal__Day__selected .Cal__Day__selection {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 52px;
  height: 52px;
  margin-top: -26px;
  margin-left: -26px;
  border-radius: 50%;
  line-height: 56px;
  z-index: 2; }

.Cal__Day__root.Cal__Day__selected .Cal__Day__selection .Cal__Day__month {
  top: 9px; }

.Cal__Day__root.Cal__Day__selected .Cal__Day__selection .Cal__Day__day {
  position: relative;
  top: 5px;
  font-weight: bold;
  font-size: 18px; }

.Cal__Day__root.Cal__Day__disabled {
  color: #AAA;
  cursor: not-allowed; }

.Cal__Day__month, .Cal__Day__year {
  position: absolute;
  left: 0;
  right: 0;
  font-size: 12px;
  line-height: 12px;
  text-transform: capitalize; }

.Cal__Day__month {
  top: 5px; }

.Cal__Day__year {
  bottom: 5px; }

/*
 * Range selection styles
 */
.Cal__Day__range.Cal__Day__selected.Cal__Day__start:after, .Cal__Day__range.Cal__Day__selected.Cal__Day__end:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 50%;
  height: 52px;
  margin-top: -26px;
  box-shadow: inset 56px 56px; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__disabled .Cal__Day__selection.Cal__Day__selection {
  background-color: #EEE !important; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__disabled .Cal__Day__selection.Cal__Day__selection .Cal__Day__day, .Cal__Day__range.Cal__Day__selected.Cal__Day__disabled .Cal__Day__selection.Cal__Day__selection .Cal__Day__month {
  color: #AAA;
  font-weight: 300; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__start .Cal__Day__selection {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__start:after {
  right: 0; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__start.Cal__Day__end:after {
  display: none; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__betweenRange .Cal__Day__selection {
  left: 0;
  right: 0;
  width: 100%;
  margin-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__betweenRange .Cal__Day__day {
  top: 0;
  font-size: 16px; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__betweenRange .Cal__Day__month {
  display: none; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__end:after {
  left: 0; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__end .Cal__Day__selection {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  color: inherit !important;
  background-color: #FFF !important;
  border: 2px solid;
  box-sizing: border-box; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__end .Cal__Day__selection .Cal__Day__day {
  top: 4px; }

.Cal__Container__root {
  position: relative;
  display: table;
  z-index: 1;
  font: 14px 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.4em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  outline: none;
  text-align: left; }

.Cal__Container__root.Cal__Container__landscape {
  display: flex;
  flex-direction: row; }

.Cal__Container__root.Cal__Container__landscape .Cal__Container__wrapper {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  z-index: 1;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px; }

.Cal__Container__listWrapper {
  position: relative;
  overflow: hidden;
  background-color: #FFF; }

.Cal__Header__root {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  min-height: 98px;
  padding: 20px;
  line-height: 1.3;
  font-weight: 400;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }

.Cal__Header__root.Cal__Header__landscape {
  align-items: flex-start;
  min-width: 200px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 3px; }

.Cal__Header__root.Cal__Header__landscape .Cal__Header__dateWrapper.Cal__Header__day {
  flex-grow: 1;
  height: 76px; }

.Cal__Header__wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  cursor: pointer; }

.Cal__Header__wrapper.Cal__Header__blank {
  height: 58px;
  line-height: 58px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
  cursor: default; }

.Cal__Header__dateWrapper {
  position: relative;
  display: block;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.5);
  transition: color 0.3s ease; }

.Cal__Header__dateWrapper.Cal__Header__active {
  color: white; }

.Cal__Header__dateWrapper.Cal__Header__day {
  height: 38px;
  font-size: 36px;
  line-height: 36px;
  text-transform: capitalize; }

.Cal__Header__dateWrapper.Cal__Header__year {
  height: 20px;
  font-size: 18px;
  line-height: 18px; }

.Cal__Header__date {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.Cal__Header__range {
  display: flex;
  flex-grow: 1; }

.Cal__Header__range .Cal__Header__dateWrapper {
  overflow: visible; }

.Cal__Header__range .Cal__Header__wrapper:first-child:before, .Cal__Header__range .Cal__Header__wrapper:first-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 0;
  margin-top: -50px;
  margin-left: -50px;
  border-top: 100px solid transparent;
  border-bottom: 100px solid transparent;
  border-left: 60px solid; }

.Cal__Header__range .Cal__Header__wrapper:first-child:before {
  color: rgba(255, 255, 255, 0.15);
  transform: translateX(1px); }

.Cal__Header__range .Cal__Header__wrapper:last-child {
  margin-left: 60px; }

.Cal__Header__range .Cal__Header__wrapper .Cal__Header__date {
  white-space: nowrap;
  z-index: 1; }

.Cal__Today__root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 6px;
  box-sizing: border-box;
  transform: translate3d(0, -100%, 0);
  font-weight: 500;
  line-height: 0;
  z-index: 10;
  cursor: pointer;
  transition: transform 0.3s ease;
  transition-delay: 0.3s; }

.Cal__Today__root.Cal__Today__show {
  transform: translate3d(0, 0, 0);
  transition-delay: 0s; }

.Cal__Today__root.Cal__Today__show .Cal__Today__chevron {
  transition: transform 0.3s ease; }

.Cal__Today__root .Cal__Today__chevron {
  position: absolute;
  top: 50%;
  margin-top: -6px;
  margin-left: 5px;
  transform: rotate(270deg);
  transition: transform 0.3s ease; }

.Cal__Today__root.Cal__Today__chevronUp .Cal__Today__chevron {
  transform: rotate(180deg); }

.Cal__Today__root.Cal__Today__chevronDown .Cal__Today__chevron {
  transform: rotate(360deg); }

.Cal__MonthList__root {
  width: 100% !important;
  background-color: #FFF;
  -webkit-overflow-scrolling: touch; }

.Cal__MonthList__root.Cal__MonthList__scrolling > div {
  pointer-events: none; }

.Cal__MonthList__root.Cal__MonthList__scrolling label {
  opacity: 1; }

.Cal__Weekdays__root {
  position: relative;
  z-index: 5;
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.04); }

.Cal__Weekdays__day {
  padding: 15px 0;
  flex-basis: 14.28571%;
  flex-grow: 1;
  font-weight: 500;
  text-align: center; }

.Cal__Years__root {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F9F9F9; }

.Cal__Years__root:before, .Cal__Years__root:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 50px;
  pointer-events: none;
  z-index: 1; }

.Cal__Years__root:before {
  top: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%); }

.Cal__Years__root:after {
  bottom: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 100%); }

.Cal__Years__list {
  box-sizing: border-box; }

.Cal__Years__list.Cal__Years__center {
  display: flex;
  align-items: center;
  justify-content: center; }

.Cal__Years__year {
  display: flex;
  padding: 0 20px;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  box-sizing: border-box; }

.Cal__Years__year.Cal__Years__withMonths {
  border-bottom: 1px solid #E9E9E9; }

.Cal__Years__year.Cal__Years__withMonths label {
  height: 88px;
  padding-top: 12px;
  box-sizing: border-box; }

.Cal__Years__year label {
  flex-basis: 42%; }

.Cal__Years__year label span {
  flex-shrink: 0;
  color: #333; }

.Cal__Years__year ol {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  font-size: 14px; }

.Cal__Years__year ol li {
  display: flex;
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  list-style: none;
  border-radius: 50%;
  box-sizing: border-box;
  color: #444;
  font-weight: 400;
  text-transform: capitalize; }

.Cal__Years__year ol li.Cal__Years__currentMonth {
  border: 1px solid; }

.Cal__Years__year ol li.Cal__Years__selected {
  position: relative;
  z-index: 1;
  background-color: blue;
  color: #FFF !important;
  border: 0; }

.Cal__Years__year ol li.Cal__Years__disabled {
  cursor: not-allowed;
  color: #AAA; }

.Cal__Years__year ol li.Cal__Years__disabled:hover {
  background-color: inherit; }

.Cal__Years__year ol li:hover {
  background-color: #EFEFEF; }

.Cal__Years__year:hover label > span, .Cal__Years__year.Cal__Years__active label > span {
  color: inherit; }

.Cal__Years__year:hover, .Cal__Years__year.Cal__Years__active {
  position: relative;
  z-index: 2; }

.Cal__Years__year.Cal__Years__active {
  font-size: 32px; }

.Cal__Years__year.Cal__Years__currentYear {
  position: relative; }

.Cal__Years__year.Cal__Years__currentYear label > span {
  min-width: 50px;
  padding-bottom: 5px;
  border-bottom: 3px solid; }

.Cal__Years__year.Cal__Years__currentYear.Cal__Years__active label > span {
  min-width: 85px; }

.Cal__Years__year.Cal__Years__first {
  padding-top: 40px; }

.Cal__Years__year.Cal__Years__last {
  padding-bottom: 40px; }

.Cal__Animation__enter {
  opacity: 0;
  transform: translate3d(0, -100%, 0);
  transition: 0.25s ease; }

.Cal__Animation__enter.Cal__Animation__enterActive {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

.Cal__Animation__leave {
  transform: translate3d(0, 0, 0);
  transition: 0.25s ease; }

.Cal__Animation__leave.Cal__Animation__leaveActive {
  opacity: 0;
  transform: translate3d(0, 100%, 0); }

.Cal__Slider__root, .Cal__Slider__slide {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.Cal__Slider__root {
  overflow: hidden; }

.Cal__Slider__slide {
  padding: 20px 65px; }

.Cal__Slider__slide:first-child {
  padding-left: 20px; }

.Cal__Slider__wrapper {
  height: 100%;
  transition: transform 0.3s ease; }

.Cal__Slider__arrow {
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 100%;
  opacity: 0.7;
  cursor: pointer;
  border-left: 1px solid rgba(255, 255, 255, 0.1); }

.Cal__Slider__arrow svg {
  width: 15px; }

.Cal__Slider__arrow:hover {
  opacity: 1; }

.Cal__Slider__arrowRight {
  right: 0; }

.Cal__Slider__arrowLeft {
  left: 0;
  transform: scaleX(-1); }

.Cal__transition__enter {
  opacity: 0; }

.Cal__transition__enterActive {
  opacity: 1;
  transition: opacity 0.3s ease; }

.Cal__transition__leave {
  opacity: 1; }

.Cal__transition__leaveActive {
  opacity: 0;
  transition: opacity 0.3s ease; }

.Cal__Month__rows {
  position: relative;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.05) 100%); }

.Cal__Month__row {
  padding: 0;
  margin: 0; }

.Cal__Month__row:first-child {
  text-align: right; }

.Cal__Month__row:first-child li {
  background-color: #FFF;
  box-shadow: 0 -1px 0 #E9E9E9; }

.Cal__Month__row:nth-child(2) {
  box-shadow: 0 -1px 0 #E9E9E9; }

.Cal__Month__row.Cal__Month__partial:first-child li:first-child {
  box-shadow: 0px -1px 0 #E9E9E9, inset 1px 0 0 #E9E9E9; }

.Cal__Month__row.Cal__Month__partial:last-of-type li {
  position: relative;
  z-index: 1; }

.Cal__Month__label {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  font-size: 30px;
  font-weight: 500;
  z-index: 3;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.6);
  opacity: 0;
  transition: opacity 0.3s ease; }

.Cal__Month__label > span {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize; }

.Cal__Month__label.Cal__Month__partialFirstRow {
  top: 56px; }

/* Font Smoothing */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
td,
button,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.display-1,
.display-2,
.display-3,
.display-4,
.navbar,
.brand,
.alert {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Poppins, Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 400; }

.cr-app {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden; }

.nav-item .nav-link {
  display: flex;
  align-items: center; }
  .nav-item .nav-link.active {
    color: #fff;
    background: rgba(255, 255, 255, 0.23);
    opacity: 1; }

.cr-app {
  overflow: hidden; }

#coordinator-nav {
  background: #fbffd6;
  border-bottom: 1px solid #f8ffb4; }

#entry-guide-modal button.submit-entry {
  background: #1ec2f3;
  color: #ffffff;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  display: block;
  text-align: center;
  padding: 0.375rem 1.5rem;
  margin-right: auto;
  margin-left: auto;
  outline: none;
  margin-bottom: 15px; }

.seat-container .seat {
  width: 15px;
  border: 2px solid #1ec2f3;
  height: 15px;
  border-radius: 50%;
  position: absolute; }

.seat-container .seat-venue-2 {
  width: 12px;
  border: 2px solid #1ec2f3;
  height: 12px;
  border-radius: 50%;
  position: absolute; }

.floor-plan-back {
  position: absolute;
  right: 25px;
  padding-top: 0; }

.seat-container .seat-1 {
  top: -44px; }

.seat-container .seat-2 {
  top: -36px;
  right: -26px; }

.seat-container .seat-3 {
  top: -14px;
  right: -43px; }

.seat-container .seat-4 {
  top: 14px;
  right: -43px; }

.seat-container .seat-5 {
  top: 37px;
  right: -26px; }

.seat-container .seat-6 {
  top: 44px;
  right: 0; }

.seat-container .seat-7 {
  top: 37px;
  left: -26px; }

.seat-container .seat-8 {
  top: 14px;
  left: -43px; }

.seat-container .seat-9 {
  top: -14px;
  left: -43px; }

.seat-container .seat-10 {
  top: -36px;
  left: -26px; }

.seat-container {
  width: 15px;
  max-width: 15px;
  height: 15px;
  max-height: 15px;
  position: absolute; }

.entry-guide-card {
  height: 700px; }

#judge-nav {
  background: #e9f9ff;
  border-bottom: 1px solid #d4f3ff; }

.imgPreview {
  margin-bottom: 20px;
  border: solid 2px #1ec2f3;
  margin-top: 20px;
  max-width: 350px; }

.gallery-table-loading {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  width: 100%; }

.imgPreview img {
  margin-bottom: 0;
  max-height: 350px;
  width: 100%; }

.crop-container {
  position: relative;
  width: 500px;
  height: 300px; }

#resizer-button {
  max-width: 120px;
  width: 100%; }

#logo-resizer-container input[type="button"] {
  background: #1E2226;
  color: white;
  margin: 30px 0; }

#logo-resizer-container .reactEasyCrop_Contain {
  background: rgba(0, 0, 0, 0.5); }

.mw-100px {
  max-width: 100px; }

.croped-image-container {
  border: solid 2px #ced4da;
  max-width: 250px;
  max-height: 250px;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center; }

.Toastify__toast-body {
  font-family: 'Poppins'; }

.edit-sponsor-modal .crop-container,
.add-sponsor-modal .crop-container {
  position: relative;
  width: 350px;
  height: 200px; }

.judging-results-card .card-body {
  padding: 0 !important;
  border: 1px solid #dee2e6;
  border-radius: 5px; }

.judging-results-card:nth-child(2) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(3) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(4) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(5) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(6) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(7) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(8) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(9) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(10) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(11) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(12) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(13) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(14) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(15) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(16) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(17) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(18) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(19) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(20) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(21) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(22) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(23) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(24) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(25) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(26) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(27) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(28) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(29) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(30) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(31) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(32) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(33) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(34) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(35) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(36) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(37) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(38) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(39) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(40) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(41) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(42) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(43) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(44) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(45) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(46) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(47) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(48) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(49) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(50) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(51) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(52) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(53) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(54) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(55) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(56) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(57) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(58) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(59) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(60) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(61) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(62) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(63) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(64) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(65) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(66) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(67) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(68) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(69) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(70) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(71) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(72) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(73) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(74) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(75) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(76) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(77) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(78) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(79) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(80) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(81) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(82) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(83) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(84) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(85) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(86) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(87) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(88) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(89) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(90) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(91) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(92) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(93) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(94) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(95) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(96) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(97) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(98) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(99) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(100) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(101) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(102) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(103) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(104) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(105) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(106) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(107) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(108) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(109) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(110) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(111) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(112) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(113) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(114) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(115) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(116) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(117) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(118) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(119) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(120) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(121) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(122) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(123) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(124) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(125) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(126) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(127) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(128) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(129) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(130) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(131) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(132) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(133) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(134) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(135) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(136) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(137) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(138) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(139) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(140) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(141) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(142) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(143) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(144) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(145) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(146) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(147) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(148) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(149) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(150) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(151) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(152) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(153) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(154) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(155) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(156) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(157) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(158) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(159) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(160) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(161) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(162) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(163) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(164) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(165) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(166) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(167) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(168) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(169) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(170) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(171) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(172) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(173) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(174) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(175) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(176) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(177) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(178) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(179) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(180) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(181) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(182) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(183) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(184) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(185) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(186) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(187) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(188) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(189) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(190) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(191) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(192) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(193) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(194) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(195) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(196) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(197) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(198) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(199) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(200) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(201) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(202) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(203) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(204) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(205) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(206) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(207) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(208) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(209) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(210) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(211) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(212) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(213) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(214) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(215) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(216) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(217) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(218) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(219) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(220) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(221) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(222) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(223) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(224) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(225) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(226) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(227) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(228) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(229) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(230) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(231) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(232) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(233) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(234) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(235) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(236) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(237) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(238) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(239) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(240) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(241) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(242) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(243) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(244) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(245) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(246) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(247) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(248) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(249) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(250) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(251) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(252) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(253) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(254) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(255) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(256) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(257) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(258) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(259) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(260) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(261) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(262) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(263) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(264) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(265) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(266) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(267) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(268) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(269) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(270) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(271) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(272) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(273) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(274) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(275) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(276) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(277) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(278) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(279) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(280) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(281) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(282) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(283) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(284) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(285) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(286) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(287) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(288) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(289) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(290) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(291) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(292) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(293) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(294) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(295) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(296) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(297) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(298) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(299) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(300) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(301) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(302) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(303) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(304) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(305) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(306) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(307) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(308) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(309) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(310) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(311) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(312) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(313) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(314) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(315) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(316) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(317) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(318) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(319) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(320) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(321) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(322) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(323) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(324) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(325) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(326) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(327) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(328) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(329) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(330) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(331) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(332) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(333) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(334) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(335) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(336) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(337) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(338) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(339) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(340) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(341) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(342) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(343) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(344) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(345) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(346) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(347) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(348) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(349) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(350) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(351) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(352) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(353) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(354) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(355) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(356) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(357) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(358) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(359) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(360) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(361) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(362) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(363) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(364) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(365) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(366) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(367) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(368) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(369) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(370) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(371) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(372) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(373) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(374) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(375) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(376) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(377) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(378) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(379) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(380) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(381) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(382) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(383) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(384) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(385) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(386) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(387) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(388) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(389) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(390) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(391) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(392) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(393) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(394) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(395) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(396) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(397) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(398) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(399) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(400) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(401) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(402) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(403) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(404) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(405) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(406) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(407) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(408) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(409) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(410) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(411) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(412) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(413) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(414) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(415) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(416) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(417) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(418) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(419) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(420) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(421) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(422) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(423) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(424) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(425) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(426) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(427) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(428) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(429) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(430) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(431) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(432) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(433) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(434) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(435) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(436) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(437) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(438) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(439) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(440) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(441) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(442) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(443) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(444) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(445) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(446) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(447) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(448) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(449) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(450) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(451) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(452) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(453) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(454) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(455) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(456) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(457) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(458) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(459) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(460) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(461) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(462) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(463) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(464) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(465) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(466) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(467) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(468) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(469) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(470) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(471) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(472) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(473) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(474) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(475) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(476) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(477) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(478) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(479) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(480) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(481) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(482) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(483) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(484) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(485) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(486) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(487) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(488) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(489) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(490) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(491) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(492) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(493) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(494) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(495) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(496) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(497) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(498) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(499) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(500) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(501) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(502) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(503) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(504) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(505) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(506) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(507) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(508) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(509) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(510) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(511) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(512) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(513) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(514) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(515) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(516) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(517) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(518) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(519) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(520) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(521) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(522) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(523) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(524) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(525) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(526) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(527) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(528) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(529) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(530) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(531) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(532) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(533) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(534) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(535) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(536) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(537) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(538) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(539) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(540) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(541) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(542) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(543) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(544) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(545) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(546) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(547) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(548) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(549) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(550) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(551) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(552) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(553) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(554) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(555) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(556) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(557) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(558) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(559) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(560) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(561) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(562) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(563) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(564) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(565) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(566) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(567) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(568) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(569) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(570) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(571) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(572) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(573) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(574) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(575) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(576) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(577) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(578) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(579) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(580) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(581) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(582) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(583) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(584) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(585) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(586) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(587) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(588) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(589) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(590) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(591) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(592) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(593) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(594) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(595) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(596) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(597) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(598) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(599) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(600) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(601) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(602) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(603) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(604) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(605) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(606) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(607) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(608) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(609) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(610) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(611) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(612) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(613) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(614) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(615) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(616) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(617) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(618) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(619) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(620) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(621) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(622) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(623) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(624) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(625) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(626) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(627) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(628) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(629) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(630) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(631) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(632) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(633) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(634) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(635) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(636) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(637) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(638) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(639) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(640) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(641) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(642) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(643) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(644) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(645) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(646) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(647) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(648) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(649) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(650) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(651) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(652) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(653) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(654) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(655) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(656) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(657) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(658) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(659) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(660) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(661) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(662) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(663) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(664) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(665) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(666) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(667) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(668) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(669) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(670) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(671) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(672) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(673) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(674) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(675) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(676) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(677) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(678) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(679) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(680) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(681) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(682) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(683) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(684) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(685) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(686) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(687) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(688) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(689) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(690) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(691) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(692) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(693) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(694) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(695) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(696) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(697) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(698) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(699) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(700) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(701) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(702) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(703) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(704) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(705) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(706) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(707) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(708) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(709) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(710) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(711) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(712) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(713) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(714) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(715) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(716) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(717) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(718) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(719) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(720) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(721) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(722) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(723) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(724) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(725) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(726) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(727) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(728) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(729) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(730) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(731) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(732) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(733) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(734) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(735) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(736) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(737) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(738) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(739) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(740) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(741) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(742) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(743) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(744) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(745) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(746) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(747) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(748) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(749) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(750) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(751) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(752) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(753) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(754) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(755) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(756) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(757) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(758) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(759) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(760) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(761) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(762) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(763) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(764) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(765) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(766) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(767) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(768) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(769) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(770) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(771) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(772) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(773) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(774) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(775) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(776) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(777) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(778) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(779) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(780) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(781) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(782) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(783) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(784) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(785) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(786) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(787) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(788) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(789) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(790) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(791) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(792) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(793) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(794) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(795) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(796) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(797) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(798) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(799) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(800) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(801) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(802) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(803) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(804) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(805) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(806) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(807) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(808) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(809) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(810) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(811) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(812) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(813) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(814) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(815) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(816) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(817) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(818) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(819) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(820) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(821) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(822) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(823) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(824) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(825) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(826) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(827) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(828) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(829) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(830) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(831) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(832) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(833) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(834) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(835) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(836) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(837) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(838) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(839) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(840) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(841) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(842) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(843) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(844) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(845) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(846) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(847) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(848) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(849) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(850) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(851) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(852) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(853) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(854) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(855) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(856) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(857) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(858) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(859) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(860) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(861) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(862) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(863) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(864) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(865) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(866) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(867) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(868) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(869) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(870) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(871) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(872) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(873) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(874) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(875) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(876) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(877) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(878) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(879) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(880) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(881) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(882) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(883) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(884) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(885) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(886) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(887) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(888) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(889) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(890) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(891) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(892) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(893) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(894) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(895) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(896) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(897) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(898) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(899) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(900) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(901) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(902) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(903) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(904) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(905) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(906) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(907) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(908) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(909) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(910) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(911) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(912) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(913) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(914) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(915) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(916) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(917) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(918) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(919) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(920) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(921) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(922) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(923) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(924) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(925) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(926) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(927) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(928) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(929) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(930) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(931) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(932) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(933) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(934) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(935) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(936) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(937) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(938) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(939) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(940) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(941) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(942) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(943) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(944) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(945) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(946) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(947) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(948) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(949) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(950) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(951) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(952) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(953) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(954) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(955) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(956) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(957) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(958) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(959) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(960) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(961) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(962) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(963) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(964) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(965) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(966) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(967) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(968) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(969) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(970) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(971) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(972) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(973) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(974) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(975) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(976) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(977) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(978) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(979) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(980) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(981) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(982) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(983) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(984) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(985) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(986) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(987) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(988) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(989) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(990) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(991) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(992) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(993) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(994) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(995) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(996) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(997) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(998) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(999) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(1000) {
  margin-bottom: 48px; }

.judging-results-card:nth-child(1001) {
  margin-bottom: 48px; }

#report-modal {
  max-width: 95% !important;
  margin-top: 2%; }

.judging-results-card {
  padding: 25px;
  border: 0; }

.judging-results-card table {
  margin-bottom: 0;
  max-width: none;
  width: 100%;
  overflow-x: scroll;
  display: block; }

.judging-results-card table th {
  min-width: 300px;
  border-top: 0; }

.header-entry {
  background: #2e2e2e;
  color: #fff; }

.body-entry {
  background: whitesmoke; }

.header-criteria {
  background: #1ec2f3;
  color: #fff; }

.body-score {
  background: #f1fcff; }

.header-rating {
  background: #ff5d5d;
  color: #fff; }

.body-rating {
  background: #ffefef; }

.header-comment {
  background: #f0ff95; }

.body-comment {
  background: #ffffde; }

.judging-results-card table thead td {
  font-weight: 600;
  border-left: 0;
  border-top: 0; }

.judging-results-card table td {
  border: 1px solid #fff; }

.report-judge-name {
  padding: 15px;
  font-size: 18px;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 0; }

.judging-results-card td {
  white-space: nowrap;
  overflow: hidden;
  padding: 8px 15px; }

#bookings-modal {
  max-width: 1000px !important; }

.edit-sponsor-modal .croped-image-container,
.add-sponsor-modal .croped-image-container {
  max-width: 150px;
  max-height: 150px; }

.sidebar .nav a {
  transition: all 150ms ease-in; }

.card-header {
  text-transform: uppercase; }

.badge:empty {
  display: inline-block; }

#mainTab .nav-item .nav-link.active {
  color: #fff;
  background: #1E2226; }

#mainTab .nav-item .nav-link {
  cursor: pointer;
  text-align: center;
  display: block;
  font-weight: 700;
  font-size: 17px;
  transition: 0.4s; }

.popover-inner .bg-gradient-theme {
  background: linear-gradient(to bottom, #1c2023, #212529);
  border: none; }

.popover .arrow {
  display: none; }

#mainTab li.nav-item {
  width: 100%; }

@media only screen and (min-width: 769px) {
  #mainTab li.nav-item {
    max-width: 20%; } }

#mainTab .nav-tabs {
  border-bottom: 1px solid #dee2e6;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  max-width: 1034px; }

#mainTab .nav-tabs .nav-link:hover,
#tab .nav-tabs .nav-link:focus {
  background: #1E2226;
  color: #fff !important; }

#mainTab .form-container {
  max-width: 1034px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 40px;
  border-radius: 15px;
  padding: 40px 40px 25px 40px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  background: #ffffff; }

.gallery-video.gallery-video-big {
  width: 100%;
  height: auto; }

.container-iframe {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio */ }

.marquee-container {
  overflow: hidden; }

.marquee-item img {
  width: 100%;
  max-width: 180px; }

.marquee-item {
  padding-left: 25px;
  padding-right: 25px; }

@media only screen and (min-width: 1081px) {
  .marquee-item img {
    width: auto;
    height: 3.5vw;
    max-width: 100%; } }

@media only screen and (min-width: 1400px) {
  .marquee-item {
    padding-left: 60px;
    padding-right: 60px; } }

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none; }

.gallery-video.iframe {
  pointer-events: none;
  border: 0; }

.video-controller iframe {
  width: 100%;
  border: 0; }

.btn:focus, .btn.focus {
  box-shadow: none !important; }

.pointer-events-none {
  pointer-events: none !important; }

.AwardPage {
  background: url(../../src/assets/img/bg/form-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

#mainTab .form-control {
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-size: 14px; }

#mainTab label {
  color: black;
  font-weight: 700;
  text-transform: uppercase; }

#mainTab h3.livestream-title {
  text-transform: uppercase;
  font-weight: 500;
  color: #1E2226;
  font-size: 20px;
  margin-top: 10px; }

.h2.cr-page__title {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;
  font-weight: 700;
  color: #1ec2f3; }

.cr-page__header {
  display: block !important; }

nav.cr-header.bg-white.navbar.navbar-expand.navbar-light {
  display: flex; }

nav.navbar {
  background: #1E2226;
  text-align: center;
  color: #fff;
  display: block;
  margin-bottom: 0 !important; }

.cr-sidebar__content nav.text-center.navbar {
  background: transparent; }

#award_table .table-logo {
  width: inherit;
  max-width: 130px; }

#award_table .logo-name {
  margin-bottom: 0; }

#award_table .table-title {
  background: #1ec2f3;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 3px; }

#award_table h5 {
  margin-bottom: 0;
  color: #ffffff;
  font-weight: 700;
  font-size: 18px; }

#award_table .row > [class^='col-'] {
  margin-bottom: 0;
  padding-right: 0;
  padding-left: 0; }

#award_table .table-container {
  align-items: center;
  padding-top: 10px;
  padding-bottom: 40px;
  max-width: 1034px;
  margin-left: auto;
  margin-right: auto; }

#award_table {
  padding-right: 40px !important;
  padding-left: 40px !important; }

#award_table .action-btn {
  background: #1E2226;
  border-radius: 3px;
  border: none;
  color: #fff;
  padding: 4px 14px;
  font-size: 13px;
  font-weight: 700;
  outline: none; }

#award_table a {
  color: #1ec2f3;
  text-decoration: none; }

#award_table .col {
  padding-left: 0;
  padding-right: 0; }

#award_table .card-group {
  max-width: 1034px;
  margin-left: auto;
  margin-right: auto; }

#award_table .table thead th {
  border-bottom: none;
  border-top: 0; }

.setup-submit-container {
  max-width: 1034px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px; }

#award_table .table {
  margin-bottom: 0; }

#mainTab .card {
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  align-items: center;
  max-width: 1034px; }

#mainTab .card-title {
  font-weight: 700;
  font-size: 25px; }

#mainTab .card-body {
  padding: 20px 40px; }

#mainTab p.card-text {
  margin-bottom: 0; }

#mainTab p.card-text a {
  color: #1EC2F3;
  text-decoration: none; }

#mainTab .tab-content {
  padding-top: 30px;
  padding-bottom: 40px; }

#mainTab .host-container {
  display: block; }

#mainTab .sponsor-container {
  display: block;
  padding: 0; }

#mainTab .sponsor-logo {
  margin-bottom: 0;
  padding-bottom: 0;
  max-width: 75px; }

#mainTab .card-body a {
  color: #1EC2F3;
  text-decoration: none; }

#mainTab .sponsor-form {
  margin-top: 30px;
  display: table;
  text-align: center;
  margin-left: auto;
  margin-right: auto; }

#mainTab input.form-control-file {
  padding-left: 100px; }

.table-container .card-body {
  padding: 0; }

#mainTab .table th,
.table td {
  vertical-align: top; }

#mainTab .table {
  margin-bottom: 0; }

.sponsor-container .card-body {
  padding-bottom: 0 !important; }

.cr-sidebar::after {
  background: #1E2226 !important;
  /*background: linear-gradient(to top, #1ec2f3, #1ec2f3)!important;*/ }

.cr-sidebar__background {
  background-position: right !important; }

.cr-sidebar .nav .nav-link {
  /*font-weight: 700;*/
  color: #ffffff !important; }

.ticket-container .card-body {
  padding-bottom: 0 !important; }

#mainTab .ticket-container {
  display: block;
  padding: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.ticket-container .card-body {
  padding: 0 !important; }

.progress-bar-container {
  width: 100%;
  height: 10px;
  background: #a5a5a5;
  border-radius: 5px;
  position: relative; }

.progress-bar-percent {
  height: 10px;
  background: #1ec2f3;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  font-size: 10px;
  overflow: hidden; }

/*.ticket-container .table thead th {
  border-bottom: none;
}

.ticket-container .table th, .ticket-container .table td {
  border-top: none;
}*/
.modal-dialog {
  max-width: 700px; }

.ticket-container tr.ticket-header {
  background: #1ec2f3;
  color: #ffffff; }

.ticket-container .action-btn {
  background: #1E2226;
  border-radius: 3px;
  border: none;
  color: #fff;
  padding: 4px 14px;
  font-size: 13px;
  font-weight: 700;
  outline: none;
  transition: none; }

#logo-resizer-container input[type="file"] {
  margin-bottom: 20px; }

#mainTab .TicketBtn {
  background: #1E2226;
  color: #fff;
  padding: 5px 20px;
  border-radius: 3px;
  text-decoration: none;
  font-weight: 700;
  outline: none;
  border: none;
  transition: none; }

.AwardPage {
  padding-bottom: 40px; }

.AwardPage .card {
  margin-left: auto;
  margin-right: auto;
  max-width: 1034px;
  padding: 20px 10px 20px 10px; }

.AwardPage .card-title {
  font-weight: 700;
  font-size: 17px;
  max-width: 363px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 5px; }

.notification.notification-info {
  display: none !important; }

.AwardPage .card-body {
  padding: 0px 10px 0 20px; }

.AwardPage p.card-text {
  margin-bottom: 0;
  font-size: 13px; }

.AwardPage p.card-text a {
  color: #1EC2F3;
  text-decoration: none; }

.AwardPage .tab-content {
  padding-top: 30px;
  padding-bottom: 40px; }

.AwardPage img.card-img-left.card-img {
  margin-top: 5px; }

.AwardPage .AddBtn {
  background: #1E2226;
  color: #fff;
  padding: 5px 20px;
  border-radius: 3px;
  text-decoration: none;
  font-weight: 700;
  outline: none; }

.AwardPage .EditBtn {
  background: #1E2226;
  color: #fff !important;
  padding: 4px 14px;
  border-radius: 3px;
  text-decoration: none;
  font-weight: 700;
  outline: none;
  position: absolute;
  right: 20px;
  bottom: 20px; }

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none; }

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #1E2226 !important;
  background-image: none;
  border-color: transparent;
  border: none; }

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: none; }

.btn-secondary {
  background: #1E2226 !important;
  border: none; }

.modal-body {
  padding: 20px 30px 30px 30px; }

.modal-title {
  font-weight: 700;
  color: #1E2226;
  font-size: 20px;
  text-align: center; }

.modal-title-small {
  font-weight: 700;
  color: #1E2226;
  font-size: 16px;
  text-align: center; }

.modal-body label {
  color: #1E2226;
  font-weight: 700;
  text-transform: uppercase; }

.close {
  position: absolute;
  right: 10px;
  top: 8px;
  outline: none; }

.close span {
  outline: none; }

.modal-header {
  display: block; }

.modal-header .close {
  outline: none; }

.sponsor-container .action-btn {
  background: #1E2226;
  border-radius: 3px;
  border: none;
  color: #fff;
  padding: 4px 14px;
  font-size: 13px;
  font-weight: 700;
  outline: none;
  transition: none; }

button.modalBtn {
  background: #1EC2F3;
  border-radius: 3px;
  padding: 5px 30px;
  border: none;
  box-shadow: none;
  font-weight: 700;
  margin-left: auto;
  margin-right: auto;
  display: block;
  outline: none; }

button.modalBtn:hover {
  background: #1EC2F3; }

#mainTab .SponsorBtn {
  background: #1E2226;
  color: #fff;
  padding: 5px 20px;
  border-radius: 3px;
  text-decoration: none;
  font-weight: 700;
  outline: none;
  border: none;
  transition: none; }

.btnContainer {
  max-width: 1034px;
  margin-left: auto;
  margin-right: auto; }

.AwardContainer.row {
  max-width: 1070px;
  margin-left: auto;
  margin-right: auto; }

.DashboardPage .card-subtitle {
  font-weight: 700;
  color: #1E2226; }

body {
  padding-right: 0 !important; }

/* Entry Page CSS */
#your_details .form-control {
  font-size: 14px; }

.entryPage {
  padding-top: 50px;
  padding-bottom: 50px; }

.entryPage .entry_steps_form {
  max-width: 900px;
  width: 100%;
  margin-left: auto;
  margin-right: auto; }

.entryPage .entry_logo {
  width: 200px; }

#show-pdf-modal {
  display: block;
  background: rgba(0, 0, 0, 0.8);
  padding-top: 0; }

.entryPage .fm_logo {
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 20px; }

.entryPage .steps-container {
  margin-top: 20px; }

.entryPage .progress .icon_title {
  font-weight: 700;
  color: #000;
  margin-top: 10px;
  margin-left: 15px;
  font-size: 16px; }

.entryPage .progress .icon_steps {
  filter: brightness(0.5); }

.entryPage .progress .steps_img_container1 .icon_steps {
  padding: 14px 0px 0px 7px;
  filter: brightness(1); }

.entryPage .progress .icon_steps {
  max-width: 48px; }

.entryPage .progress .steps_img_container {
  padding: 11px 0px 0px 0px; }

.entryPage h2 {
  color: #1EC2F3;
  font-weight: 700; }

/*.entryPage .form-container {
  padding: 50px 30px;
  border-radius: 20px;
  margin-top: 30px;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}*/
.entryPage .required {
  font-style: italic;
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 15px; }

.entryPage label {
  margin-bottom: 5px; }

.hidden {
  display: none; }

.entryPage span.asterisk_red {
  color: #f00; }

.asterisk_red {
  color: #f00; }

.entryPage .form-p {
  margin-bottom: 10px;
  font-size: 16px; }

.entryPage .form-btn {
  background: #ffffff;
  color: #000;
  text-decoration: none;
  padding: 15px;
  border: 1px solid #dfdfdf;
  border-radius: 0.25rem;
  display: block;
  max-width: 170px;
  text-align: center;
  padding: 0.375rem 0.75rem;
  font-weight: 600; }

button:disabled,
button[disabled] {
  opacity: 0.5; }

.primary-btn,
.btn-primary {
  background: #1ec2f3 !important;
  color: #fff !important; }

.primary-btn.btn-hollow {
  background: #ffffff !important;
  color: #1ec2f3 !important; }

.primary-btn.btn-hollow:hover {
  background: #1ec2f3 !important;
  color: #ffffff !important; }

.success-btn,
.btn-success {
  background: #5ed55e !important;
  color: #fff !important; }

.warning-btn,
.btn-warning {
  background: #ffd700 !important;
  color: #fff !important; }

.cancel-btn {
  background: red !important;
  color: #fff !important; }

.no-shadow {
  box-shadow: none !important; }

.inline-block {
  display: inline-block !important; }

.submit-btn {
  background: #5ed55e !important;
  color: #fff !important;
  border-color: #5ed55e !important; }

.entryPage .form-btn:hover {
  color: #212529;
  background: #ebebeb; }

.entryPage .form-btn:focus {
  outline: none; }

.entryPage .left-btn {
  margin-left: auto; }

.entryPage .right-btn {
  margin-right: auto; }

.entryPage .center-btn {
  margin-right: auto;
  margin-left: auto; }

.entryPage .download_icon,
#admin-create-award .download_icon,
.modal-body .download_icon,
.update-entry .download_icon,
.draft-entry .download_icon,
.task-upload-card .download_icon {
  border: 1px solid #ced4da;
  padding: 80px;
  border-radius: 0.25rem;
  margin-top: 10px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400; }

.auto-height {
  height: auto !important; }

.dropdown-menu-nav:hover .dropdown-menu {
  display: block; }

.dropdown-menu-nav {
  display: flex;
  align-items: center; }

.dropdown-menu-nav .dropdown-menu {
  left: -70px;
  padding-top: 0;
  padding-bottom: 0;
  top: 80%;
  width: 202px; }

.modal-dialog-centered {
  align-items: start !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.entryPage .form-check {
  padding-left: 0; }

.entryPage .checkbox-form {
  padding-left: 20px; }

.btn-secondary.tabs-btn.active {
  background-color: #777777 !important;
  font-weight: 600; }

.tabs-btn.btn-secondary:not(:disabled):not(.disabled).active {
  background-color: #777777 !important;
  font-weight: 600; }

#booking-details h3.event-details-header {
  font-size: 20px; }

.btn-secondary.tabs-btn {
  background: #777777 !important;
  font-weight: 600; }

.roundroom-img {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%; }

.entryPage form {
  padding-left: 20px;
  padding-right: 20px; }

.progress {
  display: inline-flex;
  align-items: center;
  padding: 0;
  margin: 0 auto;
  max-width: 670px;
  width: 100%;
  list-style: none;
  height: auto;
  background: none;
  margin-bottom: 50px;
  margin-top: 40px; }

.progress-v2 {
  display: inline-flex;
  align-items: center;
  padding: 0;
  margin: 0 auto;
  max-width: 850px;
  width: 100%;
  list-style: none;
  height: auto;
  background: none;
  margin-bottom: 50px;
  margin-top: 40px; }

.progress.progress-allocation {
  display: block;
  height: 1.1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #1ec2f3;
  border-radius: 50rem;
  width: 55px;
  margin-top: 3px;
  margin-bottom: 0;
  position: relative;
  color: #fff; }

.progress.progress-allocation .progress-bar {
  position: absolute;
  height: 100%; }

.progress_title1 {
  text-align: center;
  font-size: 20px;
  margin-left: -35px;
  position: absolute; }

.progress_title2 {
  text-align: center;
  font-size: 20px;
  margin-left: -30px;
  position: absolute; }

.progress_title3 {
  text-align: center;
  font-size: 20px;
  margin-left: -10px;
  position: absolute; }

.progress_title4 {
  text-align: center;
  font-size: 20px;
  margin-left: -10px;
  position: absolute; }

.steps_img_container1 {
  width: 80px;
  height: 80px;
  border: 5px solid #F2F2F2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  background: #f2f2f2; }

.steps_img_container {
  width: 80px;
  height: 80px;
  border: 5px solid #F2F2F2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  background: #f2f2f2; }

.steps_img_container_active {
  width: 80px;
  height: 80px;
  border: 5px solid #F2F2F2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  flex-flow: column nowrap; }

.entryPage .progress .icon_steps_active {
  padding: 11px 0px 0px 0px;
  filter: brightness(1) !important;
  max-width: 48px; }

.progress .icon_step_active {
  padding: 11px 0px 0px 0px;
  max-width: 48px; }

.progress__bar {
  flex: 1;
  height: 8px;
  background: #F2F2F2; }

.progress--done {
  background: #1EC2F3; }

.progress--active {
  border: 5px solid #1EC2F3;
  color: #1EC2F3; }

/* BIS Login Form CSS */
#bis-form .btn-secondary {
  background: #1EC2F3 !important;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px; }

#bis-form .bis-link {
  color: #1EC2F3;
  text-decoration: none; }

.bis_form_card {
  border: none !important;
  background: transparent;
  padding-top: 50px;
  padding-bottom: 50px; }

#bis-form a.forget_password {
  color: #7F7F7F;
  text-align: center;
  text-decoration: none; }

@media only screen and (min-width: 981px) {
  .login-registration-row {
    width: 700px;
    min-height: 555px; }
  .maintenance-row {
    width: 700px;
    min-height: 555px; } }

.login-registration-row {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.39);
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 0px 0px 15px 0px rgba(20, 10, 10, 0.39); }

#bis-form .bis_signup_form h3 {
  font-weight: 700;
  font-size: 17px;
  margin-top: 30px;
  margin-left: 0; }

#bis-form .form_login {
  font-size: 28px;
  font-weight: 700;
  color: #1ec2f3;
  margin-top: 10px; }

#bis-form .bis_login_form {
  background: #fff;
  border-radius: 0.25rem;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto; }

.bis-logo-white {
  height: auto;
  min-height: 50px;
  max-width: 130px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: auto;
  margin-right: auto; }

.bis-logo-white img {
  width: 100%; }

#bis-form .card_bg_gray {
  background: #e6e6e6;
  padding: 30px 10px 10px 10px;
  border-radius: 5px; }

#bis-form .form-group {
  margin-bottom: 0; }

#bis-form .card_bg_white {
  background: #ffffff;
  padding: 30px 10px 30px 10px;
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-left: 0;
  margin-right: 0; }

.mobile-only {
  display: none; }

@media only screen and (max-width: 981px) {
  .bis-logo-white {
    margin-top: 20px !important; }
  .VEPcardbackground {
    height: auto !important;
    padding-bottom: 20px !important; }
  #bis-form .form_login {
    margin-top: 0 !important;
    margin-bottom: 10px !important;
    font-size: 22px !important; }
  .VEPbackground .form-control {
    margin-bottom: 20px !important; }
  .bis_signup_form.container {
    padding-left: 0;
    padding-right: 0; }
  .mobile-only {
    display: block !important; }
  #bis-form .card_bg_white {
    padding: 20px 0px 0 0 !important; } }

#bis-form h3 {
  font-weight: 700;
  font-size: 17px;
  margin-top: 30px;
  margin-left: -15px; }

/* BIS Profile CSS */
.username-row .edit-name {
  position: absolute;
  top: -5px;
  right: 0px;
  cursor: pointer; }

.companyname-row .edit-company {
  position: absolute;
  top: -5px;
  right: 0px;
  cursor: pointer; }

#profile-user-bg {
  width: 100%;
  height: 100%;
  max-height: 150px; }

#user-avatar-container {
  height: 100%;
  width: 100%;
  max-height: 170px;
  max-width: 170px;
  border-radius: 50%;
  background: white;
  padding: 10px;
  margin: 0px auto;
  z-index: 1;
  position: relative; }

#profile-view-container .avatar-image {
  width: 150px;
  height: 150px;
  border-radius: 50%; }

#user-avatar-container .edit-avatar {
  position: absolute;
  right: 5px;
  width: 35px;
  height: 35px;
  background: white;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer; }

#profile-view-container h4,
#profile-view-container h3,
#profile-view-container h6 {
  font-weight: 700; }

#profile-view-container .profile-cards-container {
  background: #fff;
  border-radius: 5px;
  padding: 30px 40px 20px 40px;
  box-shadow: 0px 0px 5px 0px #efefef; }

#profile-view-page {
  padding: 0 !important; }

#profile-view-container .left-cards {
  padding-right: 25px; }

#profile-view-container .right-cards {
  padding-left: 25px; }

#profile-view-container .col-md-6 {
  border-bottom: 1px solid #00000012; }

.modal .btn-secondary {
  background: #1E2226 !important;
  border: 0; }

.edit-profile-modal .modal-header,
.edit-password-modal .modal-header,
.edit-company-modal .modal-header {
  padding-left: 25px;
  padding-right: 25px; }

.edit-profile-modal input,
.edit-profile-modal textarea,
.edit-password-modal input,
.edit-password-modal textarea,
.edit-company-modal input,
.edit-company-modal select {
  border: 1px solid rgba(0, 0, 0, 0.125); }

.edit-profile-modal input:focus,
.edit-profile-modal textarea:focus,
.edit-password-modal input:focus,
.edit-password-modal textarea:focus {
  box-shadow: none !important; }

.edit-profile-modal .form-group,
.edit-password-modal .form-group,
.edit-company-modal .form-group {
  margin-bottom: 30px; }

.edit-profile-modal .modal-body,
.edit-password-modal .modal-body,
.edit-company-modal .modal-body {
  padding: 30px; }

#profile-view-container #change-pass-btn {
  color: #1EC2F3;
  cursor: pointer;
  text-decoration: none !important; }

.btn-info:hover {
  color: #fff;
  background: #1EC2F3;
  opacity: 1; }

.btn-info {
  color: #fff;
  background: #1EC2F3; }

.edit-password-modal .show-pass-btn {
  position: absolute;
  right: 40px;
  top: 11px;
  cursor: pointer; }

#profile-view-container {
  max-width: 1350px !important;
  padding-bottom: 50px; }

.edit-profile-modal .label-container {
  text-align: left;
  padding-top: 6px; }

.edit-password-modal .label-container {
  text-align: left;
  padding-top: 6px; }

.edit-company-modal .label-container {
  text-align: left;
  padding-top: 6px; }

.edit-profile-modal .label-container label {
  margin-bottom: 0; }

#profile-view-container #change-pass-btn:hover {
  text-decoration: underline; }

#logo-resizer-container input[type="button"] {
  background: #1E2226;
  color: white;
  margin: 30px 0; }

.cropper-container {
  height: 605px;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: 0 auto; }

.container-image {
  height: fit-content;
  margin: 30px 0; }

.container-cropper {
  height: 85%;
  padding: 10px; }

.cropper {
  height: 100%;
  position: relative; }

.container-buttons {
  display: flex;
  align-items: center;
  justify-content: center; }

@media only screen and (max-width: 1024px) {
  #profile-view-container .right-cards {
    padding-right: 15px; }
  #profile-view-container .left-cards {
    padding-left: 15px; }
  #profile-view-container .col-md-6 {
    border-bottom: none; } }

/* BIS Awards Page */
#bis-award h1 {
  font-size: 25px;
  font-weight: 700; }

.table-titles {
  font-size: 25px;
  font-weight: 700; }

#bis-award .awards {
  /*border-radius: 10px;*/
  background-color: #ffffff;
  /*padding: 10px 20px 0;
  margin: 20px 10px;*/
  min-height: 215px;
  position: relative; }

.not-visible {
  visibility: hidden; }

#bis-award .awards:hover {
  background-color: #1ec2f3;
  cursor: pointer;
  color: #ffffff;
  font-weight: 700;
  transition-delay: .1s; }

#bis-award .title-award {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5em;
  margin-top: 10px;
  margin-bottom: 15px; }

#bis-award .awards-img {
  max-width: 40px; }

#bis-award .awards-container {
  width: 60px;
  height: 60px;
  display: block;
  margin-right: auto;
  margin-left: auto;
  box-shadow: 0 0 10px 0 rgba(66, 66, 66, 0.4) !important;
  border-radius: 50%;
  padding: 10px;
  background-color: #fff; }

#awards-search,
#awards-date,
#awards-category {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border: none; }

#bis-award .form-group {
  display: flex;
  padding: 0 10px;
  margin-bottom: 10px; }

#bis-award .award-search-icon,
.award-date-icon,
.award-category-icon {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: none;
  background-color: #fff;
  padding-left: 15px;
  color: #ced4da;
  outline: none !important; }

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 15px !important;
  padding-left: 15px !important; }

#bis-award .form-control:focus {
  box-shadow: none; }

#bis-award .awards .check-icon svg {
  color: #1EC2F3; }

#bis-award .awards:hover .check-icon svg {
  color: #ffffff; }

@media only screen and (min-width: 981px) {
  #bis-award .title-award {
    min-height: 35px; } }

/*#bis-award .awards .check-icon{
  visibility: hidden;
}

#bis-award .awards:hover .check-icon{
  visibility: visible;
}*/
#bis-award .awards .awards-booking {
  padding: 10px;
  font-size: 12px;
  margin-right: -20px;
  margin-left: -20px;
  /*border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;*/
  background-color: #f2f2f2;
  position: absolute;
  width: 100%;
  bottom: 0;
  transition: 0.5; }

.check-icon {
  float: right; }

.awards:hover .awards-booking {
  background-color: #212529 !important;
  transition-delay: .1s; }

.awards-booking .categories {
  float: left; }

.awards-booking .booked {
  text-align: right; }

.awards-booking p {
  margin-bottom: 0;
  color: #888686; }

.awards:hover .awards-booking p {
  color: #fff;
  transition-delay: .1s; }

.awards .fit-awards-booking {
  margin-top: 40px; }

.pb-100 {
  padding-bottom: 100px !important; }

.pb-50 {
  padding-bottom: 50px !important; }

/* Bis Setup Award Page */
#bis_award_tab {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 48px;
  padding-bottom: 50px; }

#bis_award_tab.page-container {
  padding-bottom: 100px; }

#bis_award_tab h1 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 30px; }

.bis_navigations .card {
  font-weight: 700;
  font-size: 17px;
  text-align: center;
  display: block;
  cursor: pointer;
  background: #fff;
  padding: 15px; }

.bis_navigations .card:hover {
  color: #212529;
  background: #ebebeb; }

.bis_navigations .active.card {
  color: #212529;
  background-color: #ebebeb;
  border: none; }

#bis_award_tab .nav-tabs {
  border-bottom: none; }

#bis_award_tab .nav-tabs .nav-item {
  max-width: 356px;
  width: 100%; }

.timer-container {
  display: block !important;
  width: 50% !important;
  margin-bottom: 5px;
  margin-left: 0; }

.timer-container.big {
  width: 35% !important;
  margin-left: -28px; }

.timer-container .list-inline-item {
  font-size: 40px;
  padding-top: 15px;
  height: 60px; }

.timer-container .timer-text {
  font-size: 14px;
  margin-top: 13px;
  display: block; }

.timer-container .entrant-award-subheader {
  font-size: 18px; }

.entering-award .card {
  font-weight: 700;
  font-size: 15px;
  text-align: center;
  display: block;
  cursor: pointer;
  background: #ebebeb;
  padding: 15px;
  border: none; }

.entering-award .active.card {
  color: #212529;
  background: #fff;
  border: 1px solid #DFDFDF; }

.entering-award .nav-tabs .nav-item {
  max-width: 100% !important;
  width: 100%; }

#bis_award_tab .bis_tab_content {
  padding-top: 0;
  padding-bottom: 50px; }

#bis_award_tab .tab_btn_content {
  margin-bottom: 0;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: center; }

.award-sidebar h5 {
  font-size: 18px;
  font-weight: 600; }

.award-sidebar img {
  width: 100%; }

.table-loading {
  margin-bottom: -18px; }

#bis_award_tab .tab_btn_content_image {
  justify-content: center !important;
  display: flex !important;
  padding-top: 25px; }

#bis_award_tab .tab_btn_container {
  align-items: center;
  margin-top: 28px; }

.btn-main.sidebar-btn-alt {
  color: #212529 !important;
  border: 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 17px;
  text-align: center;
  display: block;
  cursor: pointer;
  background: #fff;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  text-transform: uppercase; }

.btn-main.sidebar-btn-alt.active {
  background: #ebebeb;
  border: 1px solid transparent; }

.btn-main.sidebar-btn-alt:hover {
  color: #212529; }

.btn.btn-main-alt {
  background: rgba(0, 0, 0, 0.125) !important;
  color: #212529 !important;
  border: 0; }

.btn-main-alt:hover,
.btn-main-alt:focus,
.btn-main-alt:active {
  background: rgba(0, 0, 0, 0.125) !important;
  color: #212529 !important;
  border: 0; }

#bis_award_tab .tab_btn_content:hover {
  background: #1EC2F3;
  color: #ffffff; }

#bis_award_tab .btn_text {
  font-size: 12px;
  margin-bottom: 0;
  text-transform: none; }

#bis_award_tab .btn_img {
  margin-left: auto;
  margin-right: auto;
  display: block;
  max-height: 41px;
  min-height: 41px; }

#bis_award_tab .bottom_container {
  align-items: center; }

#bis_award_tab .judging_panel_container {
  padding: 30px 30px 10px 30px; }

#bis_award_tab .judge_container {
  padding: 10px;
  margin-top: 110px;
  min-height: 386px; }

#bis_award_tab .judge_container:hover {
  color: #ffffff;
  background: #1ec2f3; }

#bis_award_tab .judge_container:hover .judge_desc {
  color: #ffffff; }

#bis_award_tab .judge_image {
  margin-top: -80px;
  margin-bottom: 20px; }

#bis_award_tab .judge_name {
  font-weight: 700;
  margin-bottom: 10px; }

#bis_award_tab .judge_desc {
  color: #1ec2f3;
  font-size: 14px;
  margin-bottom: 10px; }

#bis_award_tab .judge_content {
  font-size: 14px; }

/*Bis Awards Single Page*/
#bis_award_tab .admin-event-information {
  display: flex;
  padding: 10px;
  flex-direction: row;
  justify-content: left;
  align-items: left; }

#bis_award_tab .attending-digital-event {
  margin-left: -8px !important;
  margin-right: -8px !important;
  padding-bottom: 0px; }

#bis_award_tab .event-information p {
  padding-left: 10px; }

#bis_award_tab .event-information .tel-number {
  padding-left: 10px;
  color: #000; }

#bis_award_tab h3 {
  font-weight: 700 !important;
  font-size: 17px; }

.award-subheader {
  margin-bottom: 15px;
  font-size: 17px;
  font-weight: 700; }

#bis_award_tab .Sponsors {
  padding-bottom: 50px; }

#bis_award_tab .sponsor-name p {
  text-align: center; }

#bis_award_tab .sponsor-list {
  padding: 20px; }

#bis_award_tab .sponsor-list:hover {
  background-color: #fff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.125); }

.sponsor-image-container .sponsor-img {
  max-width: 120px; }

.sponsor-name .gold-sponsors {
  padding-top: 10px; }

.supporter-container .sponsor-img {
  max-width: 90px; }

#bis_award_tab .irish-times p {
  margin-bottom: 25px; }

.inperson-notice .card {
  font-size: 14px; }

.guests-notice .notice-list {
  display: -webkit-inline-box;
  padding-right: 15px; }

.guests-notice .notice-list p {
  padding-left: 10px; }

#bis_award_tab .guests-instruction {
  font-size: 16px; }

#bis_award_tab .notice-list svg,
#bis_award_tab .event-information svg,
#bis_award_tab .admin-event-information svg,
.event-information svg,
#bis_award_tab .itinerary-list svg,
.event-information-print-mgnt svg {
  color: #1ec2f3; }

#bis_award_tab .attending-live-event {
  padding-bottom: 50px; }

.life-event-itinerary .itinerary {
  justify-content: center; }

.life-event-itinerary .itinerary-list {
  font-size: 14px; }

.itinerary-list p {
  margin-bottom: 0;
  margin-left: 10px;
  display: inline-table; }

.life-event-itinerary .subject-change {
  font-size: 12px; }

.notice-button button {
  background: #1ec2f3;
  color: #ffffff;
  text-decoration: none;
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem; }

.notice-button button:focus {
  outline: none !important; }

.attending-digital-event .guests-notice {
  font-size: 14px; }

.digital-event-itinerary .itinerary {
  margin: 0;
  justify-content: center; }

#bis_award_tab .itinerary-time {
  color: #1ec2f3;
  margin: 10px 0 15px 25px;
  display: block; }

.itinerary-list .row {
  align-items: baseline; }

.itinerary-list {
  display: contents;
  font-size: 14px; }

.itinerary-list p {
  margin-bottom: 0;
  margin-left: 10px; }

.digital-event-itinerary .subject-change {
  font-size: 14px; }

.policies-procedures .card {
  padding: 20px; }

#bis_award_tab .policies-procedures {
  padding-right: 25px;
  padding-left: 10px; }

#bis_award_tab .policies-procedures p,
#bis_award_tab .policies-procedures li {
  font-size: 14px;
  line-height: 2; }

/*Bis Single Award Page - Details*/
/*#bis_single_award h3{
  font-weight: 700!important;
  padding-bottom: 15px;
  font-size: 20px;
}*/
#bis_single_award {
  /*max-width: 1070px;*/
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /*padding-top: 50px;*/
  padding-bottom: 50px; }

#bis_single_award h1 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 25px; }

#bis_single_award a.nav-link {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  display: block;
  cursor: pointer;
  background: #fff;
  margin-left: 5px;
  margin-right: 5px;
  padding: 15px;
  box-shadow: 0px 0px 5px 0px #efefef;
  border-radius: 5px;
  border: none; }

#bis_single_award a.nav-link:hover {
  color: #ffffff;
  background: #1EC2F3;
  border: none; }

#bis_single_award a.active.nav-link {
  color: #ffffff;
  background: #1EC2F3;
  border: none; }

#bis_single_award .nav-tabs {
  border-bottom: none; }

#bis_single_award .nav-tabs .nav-item {
  width: 100%; }

#bis_single_award .bis_tab_content {
  padding-top: 25px;
  padding-bottom: 50px; }

#bis_single_award h2 {
  font-size: 25px; }

#bis_single_award .details {
  padding-bottom: 50px !important; }

#bis_single_award .HR-award {
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #efefef;
  padding: 25px 20px 20px;
  border-radius: 10px; }

#bis_single_award .Entry {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px #efefef; }

.Entry .Entry-container {
  justify-content: center;
  padding: 30px 20px; }

.submit-entry-container .submit-entry,
.submit-judging {
  width: 100%;
  background: #1ec2f3;
  color: #ffffff;
  text-decoration: none;
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  display: block;
  text-align: center;
  padding: 0.375rem 0.75rem; }

.divider {
  width: 80px;
  padding: 2px;
  background: #1ec2f3;
  margin-bottom: 20px; }

.award-header {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 10px; }

h2.award-header {
  font-size: 22px; }

.btn-main {
  background: #1e2226;
  font-weight: 600;
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 6px;
  padding-bottom: 6px; }

.btn-main:hover {
  color: #fff; }

/*.Criteria .criteria-list {
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #efefef;
  border-radius: 10px;
}*/
.Criteria .criteria-description {
  font-size: 18px; }

.criteria-list .criteria-alignment {
  display: -webkit-inline-box; }

.criteria-list .checkcircle {
  padding-right: 10px;
  color: #1ec2f3; }

.criteria-list .criteria-alignment p {
  margin-bottom: 10px; }

.criteria-list .criteria-alignment .criteria-text {
  padding-right: 20px; }

/*Bis Setup Award Page V2 - How to Enter*/
.how-to-enter .terms-conditions li {
  font-size: 14px;
  padding-bottom: 15px; }

#bis_single_award .how-to-enter {
  padding-bottom: 50px; }

.submitting-entry-container {
  padding-bottom: 50px; }

.submitting-entry-container .submitting-entry-info h4 {
  font-size: 18px;
  font-weight: 700; }

.submitting-entry-container .submitting-entry-info li,
.submitting-entry-container .submitting-entry-info i {
  font-size: 14px;
  padding-bottom: 10px; }

.step-by-step-container .step-list {
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #efefef;
  text-align: -webkit-center;
  padding: 20px 15px 0;
  border-radius: 10px;
  margin-top: 100px;
  min-height: 350px; }

.step-list .step-image {
  text-align: -webkit-center;
  margin-top: -90px; }

.step-list .step-image p {
  font-size: 100px;
  font-weight: 700;
  color: #1EC2F3;
  border-radius: 50%;
  max-width: 150px;
  box-shadow: 0px 0px 5px 0px #efefef;
  background-color: #fff; }

.step-list .step-name p {
  font-size: 16px;
  font-weight: 700;
  min-height: 50px; }

.step-list .step-desc p,
.step-list .step-desc li {
  font-size: 14px;
  text-align: left; }

.step-list .step-desc ul {
  padding-left: 15px;
  margin-bottom: 0 !important; }

.step-list .step-desc li {
  padding-bottom: 10px; }

/*Bis Single Award Page - Entry Guide PDF*/
#entry-row .alert hr {
  border-top-color: #857000; }

.entry-guide-thank-you .thank-you-notice {
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 30px; }

.entry-registration-form .entry-form-input {
  min-height: 50px;
  width: 100%;
  border-radius: 10px;
  border: none;
  margin-bottom: 20px;
  padding-left: 25px;
  box-shadow: 0px 0px 5px 0px #efefef; }

.entry-registration-form .entry-registration-button {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%; }

.entry-registration-button .entry-button {
  color: #fff;
  background-color: #1EC2F3;
  font-weight: 700;
  border: none;
  border-radius: 10px;
  min-height: 50px;
  width: 100%;
  outline: none; }

.entry-guide-thank-you .hr-leadership-image {
  border-radius: 50%;
  background-color: #fff;
  max-width: 300px;
  padding: 50px;
  margin-right: auto;
  margin-left: auto;
  box-shadow: 0px 0px 5px 0px #efefef; }

.entry-registration-form .entry-contact-info {
  margin-top: 20px;
  padding-left: 20px; }

.entry-registration-form .entry-contact-info a {
  color: #000;
  margin: 0 15px 0 5px;
  font-size: 15px; }

.entry-registration-form .entry-contact-info a:hover {
  text-decoration: none; }

.entry-registration-form .entry-contact-info svg {
  color: #1EC2F3; }

/*Bis Award Payment Step 1*/
.step-progress--active {
  border: 5px solid #1EC2F3;
  color: #fff; }

.stepPage .steps-container {
  margin-top: 20px; }

.icon_title {
  font-weight: 700;
  color: #000;
  margin-top: 10px;
  font-size: 18px; }

.stepPage .step-progress .number-steps {
  filter: brightness(0.5); }

.stepPage .step-progress .steps_img_container1 .number-steps {
  padding: 14px 0px 0px 7px;
  filter: brightness(1); }

.stepPage .step-progress .icon_steps {
  max-width: 48px; }

.stepPage .step-progress .steps_img_container {
  padding: 11px 0px 0px 0px; }

.steps-progress {
  display: inline-flex;
  align-items: center;
  padding: 0;
  margin: 0 auto;
  max-width: 670px;
  width: 100%;
  list-style: none;
  height: auto;
  background: none;
  margin-bottom: 50px;
  margin-top: 40px; }

.steps-progress .number_steps_active {
  color: #fff !important; }

.steps-progress .number_steps_active,
.steps-progress .number-steps {
  font-size: 50px;
  font-weight: 700; }

.steps-progress .number-steps {
  color: #C3C3C3; }

.steps-progress .first {
  color: #fff !important; }

.progress_title1 {
  text-align: center;
  font-size: 20px;
  margin-left: -35px;
  position: absolute; }

.progress_title2 {
  text-align: center;
  font-size: 20px;
  margin-left: -30px;
  position: absolute; }

.progress_title3 {
  text-align: center;
  font-size: 20px;
  margin-left: -10px;
  position: absolute; }

.progress_title4 {
  text-align: center;
  font-size: 20px;
  margin-left: -10px;
  position: absolute; }

.stepPage .step-progress .icon_steps_active {
  padding: 11px 0px 0px 0px;
  filter: brightness(1) !important;
  max-width: 48px; }

.progress__bar {
  flex: 1;
  height: 8px;
  background: #F2F2F2; }

.entry-progress {
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 0; }

.step-progress--done {
  background: #1EC2F3;
  color: #fff; }

.step-progress--active {
  border: 5px solid #1EC2F3; }

.stepPage {
  background-image: url("../assets/img/logo/entry-bg.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 50px;
  padding-bottom: 50px; }

.stepPage .entry_steps_form {
  max-width: 900px;
  width: 100%;
  margin-left: auto;
  margin-right: auto; }

.stepPage .steps-container {
  margin-top: 20px; }

.stepPage .step-progress .icon_title {
  font-weight: 700;
  color: #000;
  margin-top: 10px; }

.stepPage .step-progress .number_steps {
  filter: brightness(0.5); }

.stepPage .step-progress .steps_number_container1 .number_steps {
  padding: 14px 0px 0px 7px;
  filter: brightness(1); }

.stepPage .step-progress .icon_steps {
  max-width: 48px; }

.stepPage .step-progress .steps_number_container {
  padding: 11px 0px 0px 0px; }

.stepPage .form-container {
  box-shadow: 0px 0px 12px 0px #c5c5c5;
  padding: 50px 30px;
  border-radius: 20px;
  margin-top: 30px;
  background-image: url("../assets/img/logo/form-bg.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat; }

.stepPage .step-progress .icon_steps_active {
  padding: 11px 0px 0px 0px;
  filter: brightness(1) !important;
  max-width: 48px; }

#award-hero {
  background: #f5f5f5;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: -8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

#award-hero img {
  max-width: 100px;
  width: 100%; }

#award-hero h2 {
  font-weight: 600;
  color: #1e2226;
  margin-top: 10px;
  font-size: 25px; }

#booking-details {
  padding: 48px 15px; }

.payment-steps-form form {
  width: 100%; }

#booking-details .booking-info-step-1 {
  padding: 20px; }

#booking-details .booking-info-step-1 input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #e6eafe;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(106, 130, 251, 0.4); }

#booking-details h3 {
  font-size: 17px;
  font-weight: 700;
  color: #212529;
  line-height: 1.5; }

#booking-details .booking-info-step-1 input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s; }

#judge-assignment-table img.profile_table,
#coordinator-assignment-table img.profile_table {
  max-width: 60px !important; }

#booking-details .total-price p {
  margin-bottom: 0;
  font-weight: 700;
  text-align: right; }

#booking-details .total-price p.small {
  font-size: 14px; }

#booking-details .quantity {
  max-width: 70px; }

#booking-details .price {
  text-align: right; }

.performance-benchmark-chart {
  width: 100%; }

.all-winners {
  width: 100%; }

.total-price.card {
  background: #efefef; }

.total-price.info {
  background: #ccf4ff; }

.irish-times-logo {
  width: 100%;
  margin-top: 15px; }

.performance-benchmark-container {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto; }

.performance-benchmark-container-lg {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto; }

.hidden-attendee-id {
  opacity: 0 !important;
  margin-bottom: -40px; }

.all-winners-container {
  max-width: 6 00px;
  margin-left: auto;
  margin-right: auto; }

.irish-times-container {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto; }

.booking-button.text-left button {
  margin-right: auto;
  margin-left: 0; }

.booking-button.text-right button {
  margin-right: 0;
  margin-left: auto; }

.booking-button .form-button {
  background: #ffffff;
  color: #000;
  text-decoration: none;
  border: 1px solid #dfdfdf;
  border-radius: 0.25rem;
  display: block;
  max-width: 230px;
  text-align: center;
  padding: 0.375rem 0.75rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  font-weight: 600; }

.booking-button .form-button:hover {
  color: #212529;
  background: #ebebeb; }

.booking-button .form-button:focus {
  outline: none; }

.booking-info-step-1 .booking-button {
  width: 100%; }

#booking-details .radio_btn {
  margin-right: 15px;
  margin-left: 20px; }

#booking-details .price_booking,
#booking-details .price_booking_discount {
  font-weight: 400;
  padding-left: 10px; }

#booking-details .price_booking_discount {
  text-decoration: line-through;
  color: #808080; }

.purchasing ::marker {
  color: #1EC2F3;
  font-size: 25px; }

span.package_title {
  font-size: 18px; }

/*Bis-Receipt*/
.bis-receipt-container .payment-receipt .col-lg-6 {
  display: inline; }

.bis-receipt-container .payment-receipt tr {
  display: inline-grid;
  padding: 0px 30px; }

.bis-receipt-container .receipt-table {
  padding-left: 15px;
  padding-right: 15px; }

#bis_receipt .product-name {
  text-align: left; }

#bis_receipt .table td {
  border: none !important; }

#bis_receipt .pricing-container {
  width: 100%; }

.receipt-table hr {
  margin-top: 0; }

#bis_receipt .btn-receipt {
  background: #1ec2f3;
  color: #ffffff;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  display: block;
  text-align: center;
  padding: 5px 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  float: right;
  outline: none;
  text-decoration: none !important; }

.bis-receipt-container h3 {
  color: #1ec2f3;
  font-size: 20px;
  font-weight: 600; }

#bis_receipt {
  padding: 50px 20px; }

.bis-receipt-container svg {
  color: #fff;
  font-size: 55px;
  border-radius: 50%;
  border: 2px solid #1ec2f3;
  background: #1ec2f3; }

#bis_receipt .card {
  margin-left: auto;
  margin-right: auto; }

.preview .nav-tabs {
  border-bottom: none; }

.main-header {
  font-size: 25px;
  font-weight: 700; }

.preview .preview_logo {
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 20px; }

.preview .award_col_title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0; }

.preview .award_entry_content {
  margin-bottom: 0px;
  line-height: 1.8; }

.preview .award_entry_btn {
  background: #1E2226;
  color: #fff;
  text-decoration: none;
  padding: 0px 5px;
  margin-left: 10px;
  float: right; }

.preview .table {
  margin-bottom: 0; }

.total {
  font-weight: 700 !important;
  font-size: 17px !important; }

.preview .form-control {
  font-size: 14px; }

.form-invalid {
  border-color: #dc3545 !important; }

.form-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important; }

.preview .form-btn {
  background: #ffffff;
  color: #000;
  text-decoration: none;
  padding: 15px;
  border: 1px solid #dfdfdf;
  border-radius: 0.25rem;
  display: block;
  max-width: 230px;
  text-align: center;
  padding: 0.375rem 0.75rem;
  font-weight: 600; }

.preview .form-btn:hover {
  color: #212529;
  background: #ebebeb; }

.preview .form-btn:focus {
  outline: none; }

.preview .left_btn {
  margin-left: auto;
  width: 100%; }

.invalid-feedback {
  display: block; }

.preview .right_btn {
  margin-right: auto; }

.thank_you_heading {
  font-size: 35px;
  font-weight: 600; }

.profile_btn .btn-secondary {
  width: 100%;
  margin-top: 15px; }

.profile_btn a {
  text-decoration: none !important; }

.preview p.profile_number {
  color: #1e2226;
  font-size: 30px;
  line-height: .8;
  font-weight: 700; }

.preview img.profile_table {
  max-width: 90px;
  width: 100%;
  margin-bottom: 0; }

.preview .book_svg {
  color: #fd88ff;
  width: 50px;
  height: 50px; }

.preview .entry_svg {
  color: #6564cb;
  width: 50px;
  height: 50px; }

.preview .icons_row {
  align-items: center; }

.preview .award-search-icon {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: none;
  background-color: #fff;
  padding-left: 15px;
  color: #ced4da;
  outline: none !important; }

.preview .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

.preview .award-search-container {
  display: flex;
  padding: 0 10px;
  margin-bottom: 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }

.preview .form-control:focus {
  box-shadow: none !important; }

.admin_award_table th {
  border: none; }

.preview .book_svg_single {
  color: #1ec2f3;
  width: 50px;
  height: 50px; }

.preview .entry_svg_single {
  color: #1ec2f3;
  width: 50px;
  height: 50px; }

.preview .categories_svg_single {
  color: #1ec2f3;
  width: 50px;
  height: 50px; }

.remove-icon:hover,
.edit-icon:hover {
  cursor: pointer; }

.modal.show {
  display: block !important;
  background: rgba(0, 0, 0, 0.5); }

.absolute {
  position: absolute !important; }

.list-group-flush .list-group-item:focus {
  outline: none !important; }

.fixed {
  position: fixed !important; }

.react-modal-close {
  margin: 0 !important;
  padding-top: 10px !important; }

.text-left .modal-title {
  text-align: left; }

.preview img.judge_img {
  max-width: 42px; }

.preview .judge_container {
  align-items: center; }

.preview .gray_bg.card {
  background: #1E2226; }

.preview .users_svg {
  color: #ffffff;
  width: 50px;
  height: 50px; }

.preview .judge_svg {
  color: #767676;
  width: 50px;
  height: 50px; }

.preview .sponsors_svg {
  color: #4fb9b9;
  width: 50px;
  height: 50px; }

.stage-box {
  width: 200px;
  height: 100px;
  margin-top: 10px;
  border-radius: 0;
  background: #222;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0; }

.venue-stage-box {
  width: 200px;
  height: 100px;
  margin-top: 10px;
  border-radius: 0;
  background: #dadedf;
  color: #000;
  position: absolute;
  top: 0;
  left: 0; }

.table-box {
  width: 100px;
  height: 100px;
  margin-top: 10px;
  border-radius: 100%;
  border: none !important;
  background: #ebebeb;
  color: #000; }

.stage-box h2 {
  margin-top: 30px; }

.preview img.img_table {
  max-width: 40px;
  width: 100%; }

.user_award_table th {
  font-size: 16px !important; }

.action_table {
  position: relative;
  display: inline-block;
  cursor: pointer; }

.action_table .action_content {
  visibility: hidden;
  width: 200px;
  padding: 0;
  right: 15px;
  top: 5px;
  position: absolute;
  z-index: 1;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.39); }

.action_content_large {
  width: 250px !important; }

.action_table:hover .action_content {
  visibility: visible; }

.action_table .action_container {
  padding: 0px !important; }

.action_container a {
  text-decoration: none;
  color: #212529;
  font-size: 14px;
  font-weight: 600;
  padding-left: 12px;
  display: inline-block;
  width: 115px; }

.action_container .link_container {
  padding: 10px 30px;
  text-align: left !important; }

.action_container .link_container:hover {
  background: #ebebeb; }

.modal-preview .modal-body {
  padding: 0px; }

.modal-preview label {
  color: inherit;
  font-weight: 500;
  text-transform: none; }

.modal-preview .form-check-inline {
  display: grid;
  margin-right: 0; }

.modal-preview .card-body {
  padding: 10px 15px; }

.modal-preview p,
.preview p {
  margin-bottom: 0; }

.modal-preview img.img_table {
  max-width: 40px;
  width: 100%; }

.modal-preview .card_container.card-body {
  padding: 20px; }

.table-card {
  position: absolute;
  left: 0;
  right: 0; }

.bis-tooltip-table {
  cursor: pointer; }

.bis-tooltip-attendee {
  position: relative;
  display: inline-block; }

.bis-tooltip.no-bg {
  background: transparent;
  border: 0px; }

.bis-tooltip-table .bis-tooltiptext {
  visibility: hidden;
  width: 100%;
  top: -20px;
  left: 0;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer !important; }

.bis-tooltip-table .bis-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent; }

.bis-tooltip-attendee .bis-tooltiptext {
  visibility: hidden;
  width: 130px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 105%;
  left: 50%;
  margin-left: -65px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer; }

.bis-tooltip-attendee .bis-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent; }

.bis-tooltip-table:hover .bis-tooltiptext {
  visibility: visible; }

.bis-tooltip-attendee:hover .bis-tooltiptext {
  visibility: visible; }

.modal-preview .seat_container.card-body {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  position: relative; }

.page-container {
  position: relative;
  min-height: 110vh; }

.footer {
  position: absolute;
  bottom: 0;
  width: 100%; }

.BookingsPage .table th {
  border-top: none; }

.BookingsContainer.row {
  display: block; }

.BookingsContainer .table-hover tbody tr:hover {
  color: inherit !important;
  background-color: transparent !important; }

.action_container hr {
  margin: 0; }

.dashboard-p {
  font-size: 14px; }

.action_table .admin-content {
  visibility: hidden;
  padding: 0;
  right: 15px;
  top: 5px;
  position: absolute;
  z-index: 1; }

.table-responsive {
  overflow: hidden !important; }

.VEPcardbackground {
  background-image: url("../assets/img/logo/VEP_.png");
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat; }

.payment-success-title {
  font-size: 25px !important; }

.VEPbackground {
  background-image: url("../assets/img/logo/Component 125 – 1.png");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 20px;
  padding-right: 20px; }

.cardbgcolor {
  background-color: #fff;
  color: #000;
  border-bottom: 1px solid #f8f9fa; }

.useravatar {
  padding: 0.5px; }

.useravatarborder:hover {
  box-shadow: 0px 0px 5px 0px #1ec2f3; }

.profilename {
  font-size: 15px;
  font-weight: 800; }

.profilesubtitle {
  font-size: 12px;
  font-weight: 500; }

/* #VEPcontainer{
  margin-top:10%;
  margin-bottom: 10%;
} */
/* #VEPform{
  background-color: white;
} */
/* New Css */
.category_checkbox {
  height: 20px;
  width: 20px;
  margin-top: 5px; }

.paymentPage .trophy-img {
  width: 100%;
  margin: 0px auto 0 auto;
  display: block; }

/* Judge Category Stylings */
#judge-category .progress-group h4 {
  font-weight: 700;
  font-size: 18px; }

#judge-category .progress-group p {
  font-size: 16px;
  letter-spacing: 1px; }

#judge-category .judge-category-header {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 700; }

#judge-category .view-span {
  font-weight: 600;
  padding-left: 12px; }

#coordinator-category .progress-group h4 {
  font-weight: 700;
  font-size: 18px; }

#coordinator-category .progress-group p {
  font-size: 16px;
  letter-spacing: 1px; }

#coordinator-category .coordinator-category-header {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 700; }

#coordinator-category .view-span {
  font-weight: 600;
  padding-left: 12px; }

.description-card p:last-child {
  margin-bottom: 0 !important; }

.category-spot-checking-modal .remarks-textarea {
  width: 100%;
  padding: 10px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }

.category-spot-checking-modal.modal-preview .card-body {
  padding: 1.25rem !important; }

.category-spot-checking-modal .remarks-textarea:focus {
  box-shadow: none;
  outline: none; }

.category-spot-checking-modal .main-header {
  font-size: 20px;
  font-weight: 600;
  color: #212529;
  letter-spacing: 1px; }

.category-spot-checking-modal .sub-header {
  font-size: 17px;
  font-weight: 600;
  color: #212529;
  letter-spacing: 1px;
  margin-bottom: 10px; }

.category-spot-checking-modal .contents {
  font-size: 16px;
  color: #212529;
  margin-bottom: 10px; }

.category-spot-checking-modal {
  max-width: 1200px !important; }

.category-spot-checking-modal .award-logo {
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  display: block;
  width: 100%; }

.category-spot-checking-modal .modal-group {
  display: flex;
  justify-content: center; }

.category-spot-checking-modal .bottom-border {
  border-bottom: 1px solid #dee2e6; }

.category-spot-checking-modal .preview_logo {
  width: 100%;
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 20px; }

/*  judge assignment status color */
.judgeassignmentssvgcolor,
.coordinatorassignmentssvgcolor {
  background-color: #78e678;
  margin: 2px;
  border-radius: 50%;
  z-index: 1;
  position: absolute; }

.entry-scored {
  background-color: #78e678;
  border-radius: 50%;
  margin-top: -5px; }

.entry-unscored {
  background-color: #212529;
  color: #fff;
  border-radius: 50%;
  margin-top: -5px; }

/*  New button color    */
.newbtntheme {
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: 600; }

.newbtntheme:hover {
  color: #212529;
  background: #ebebeb; }

.newbtntheme:focus {
  outline: none; }

.newbtntheme-main {
  background: #1e2226;
  font-weight: 600;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 0.25rem; }

.newbtntheme-main:hover {
  color: #212529;
  background: #ebebeb; }

.newbtntheme-main:focus {
  outline: none; }

.preview_content .preview_widget {
  padding-top: 10px;
  padding-bottom: 5px; }

.preview_content .user_table {
  table-layout: fixed; }

.table-layout-fixed {
  table-layout: fixed; }

/* Invoice */
.receipt-icon {
  color: #1ec2f3;
  width: 50px;
  height: 50px; }

.profile_number_invoices {
  color: #1e2226;
  font-size: 30px;
  line-height: .8;
  font-weight: 700; }

.invoices_head_modal,
.invoice_info,
.tax_data {
  font-weight: 600; }

.invoice_table_head {
  background: #1EC2F3;
  color: #fff; }

.invoice_award_table th {
  border: none; }

.invoice_header,
.invoice_modal_footer {
  background: #1EC2F3;
  border: 0; }

.invoice_header h5 {
  color: #fff; }

.shift_category_popup {
  width: 213px !important; }

.shift_category_popup a {
  display: initial; }

.category-prioritization-coordinator {
  position: absolute;
  right: 15px;
  top: 15px; }

.admin-checklist-select select {
  max-width: 125px;
  display: inline-block !important;
  margin-left: 10px;
  padding-left: 5px !important;
  padding-right: 5px !important; }

.icon.danger_status {
  background-color: #ff4141;
  margin: 2px;
  border-radius: 50%;
  z-index: 1;
  position: relative; }

.icon.success_status {
  background-color: #78e678;
  margin: 2px;
  border-radius: 50%;
  z-index: 1;
  position: relative; }

.icon.pending_status {
  background-color: #ffbe46;
  margin: 2px;
  border-radius: 50%;
  z-index: 1;
  position: relative; }

.conflict_status {
  background-color: #ff4141;
  margin: 2px;
  border-radius: 50%;
  z-index: 1;
  position: absolute;
  cursor: pointer; }

.pending_status {
  background-color: #ffbe46;
  margin: 2px;
  border-radius: 50%;
  z-index: 1;
  position: absolute; }

.approved_status {
  background-color: #78e678;
  margin: 2px;
  border-radius: 50%;
  z-index: 1;
  position: absolute; }

.right-column {
  width: 120px; }

.change-image-container {
  position: relative; }

.change-photo {
  border-radius: 50%;
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer; }

.change-image-container:hover .change-photo {
  opacity: 0.8;
  box-shadow: 0px 0px 10px 0px #1ec2f3; }

.notifications-popup.popover {
  min-width: 400px !important;
  left: 130px !important;
  max-height: 300px;
  overflow-y: scroll;
  box-shadow: none; }

.chat-notifications-popup.popover {
  min-width: 380px !important;
  left: 130px !important;
  max-height: 300px;
  overflow-y: scroll;
  box-shadow: none; }

.notification-single {
  cursor: pointer; }

.logout-btn {
  background: #ef8484 !important;
  color: #fff !important;
  border-color: #ef8484 !important; }

.allocation-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer; }

.allocation-tooltip .allocation-tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px; }

.allocation-tooltip .reallocation-tooltiptext.allocation-tooltiptext {
  width: 320px;
  left: -270%; }

.allocation-tooltip .allocation-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent; }

.allocation-tooltip:hover .allocation-tooltiptext {
  visibility: visible; }

.conflict {
  position: relative; }

.conflict .conflicttext {
  visibility: hidden;
  width: 180px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 10px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  font-size: 15px;
  margin-left: -85px;
  margin-bottom: 10px;
  transition: 500ms; }

.bis-tooltip.table-tooltip {
  background: transparent;
  border: 0;
  padding: 0;
  font-weight: 400; }

.bis-tooltip.table-tooltip .bis-tooltiptext {
  width: 250px;
  left: -20px;
  bottom: 105%; }

.bis-tooltip {
  position: relative;
  display: inline-block;
  font-weight: 600;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 0.25rem;
  cursor: pointer; }

.bis-tooltip-gallery {
  position: relative;
  display: inline-block; }

.bis-tooltip-gallery .tooltiptext {
  visibility: hidden;
  position: absolute;
  width: 235px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px;
  font-size: 12px;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity .6s; }

.tooltip-top {
  bottom: 29%;
  left: 20%;
  margin-left: -60px; }

.tooltip-top::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent; }

.bis-tooltip-gallery:hover .tooltiptext {
  visibility: visible;
  opacity: 1; }

.bis-tooltip .bis-tooltiptext {
  visibility: hidden;
  width: 130px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -65px;
  font-weight: 600;
  font-size: 14px; }

.bis-tooltip .bis-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent; }

.bis-tooltip:hover .bis-tooltiptext {
  visibility: visible; }

.bis-tooltip-progress {
  position: relative;
  cursor: pointer; }

.bis-tooltip-progress .bis-tooltip-progresstext {
  visibility: hidden;
  width: 130px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 52%;
  margin-left: -65px;
  font-weight: 600;
  font-size: 14px; }

.bis-tooltip-progress .bis-tooltip-progresstext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent; }

.bis-tooltip-progress:hover .bis-tooltip-progresstext {
  visibility: visible; }

.bis-tooltip-newcoor {
  position: relative;
  display: inline-block;
  font-weight: 600;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 0.25rem;
  cursor: pointer; }

.bis-tooltip-newcoor .bis-tooltiptext-newcoor {
  visibility: hidden;
  width: 155px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -65px;
  font-weight: 600;
  font-size: 14px; }

.bis-tooltip-newcoor .bis-tooltiptext-newcoor::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent; }

.bis-tooltip-newcoor:hover .bis-tooltiptext-newcoor {
  visibility: visible; }

.allocation-name-tooltip {
  position: relative;
  display: inline-block; }

.panel-percentage {
  margin-bottom: 20px !important; }

.panel-percentage .badge {
  font-size: 14px;
  padding: 7px 10px;
  font-weight: 400; }

.allocation-name-tooltip .allocation-name-tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 15px;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 50%;
  margin-left: -200px; }

.allocation-name-tooltip .allocation-name-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent; }

.allocation-name-tooltip:hover .allocation-name-tooltiptext {
  visibility: visible; }

/*  for table links */
a {
  color: #1e2226; }

a:visited {
  text-decoration: none; }

a:hover {
  color: #1e2226; }

.conflict .conflicttext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent; }

.conflict:hover .conflicttext {
  visibility: visible; }

/* New Admins Modal*/
.admins-modal {
  max-width: 900px !important;
  width: 100%; }

.admins_svg {
  color: #8C8888;
  width: 50px;
  height: 50px; }

.notification-list-unread {
  border-left: 2px solid #29B6F6;
  width: auto;
  background-color: #ffffff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06); }

.notification-list {
  width: auto;
  background-color: #ffffff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06); }

.notification-img {
  height: 48px;
  width: 48px;
  border-radius: 5px; }

.font-style {
  font-family: Poppins, Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 13px; }

.details-img_table {
  border: 1px solid #DEE2E6 !important;
  padding: 5px !important; }

.forgot-password-card {
  height: 100vh;
  justify-content: center;
  align-items: center; }

.forgot-password-question {
  font-size: 28px;
  font-weight: 700;
  color: #1ec2f3; }

.forgot-password-button {
  background: #1EC2F3 !important;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px; }

.button-icon {
  max-width: 17px;
  margin-bottom: 3px;
  margin-right: 10px; }

.award-button-icon {
  max-width: 35px;
  margin-bottom: 25px;
  margin-top: 15px; }

.award-button-text {
  font-size: 20px; }

.talk-to-us {
  color: #1EC2F3 !important;
  text-decoration: none; }

.sponsors-logo {
  margin: 0 auto;
  width: 100%;
  max-width: 80px;
  display: block; }

.sponsors-heading {
  text-align: center;
  color: #1ec2f3;
  margin-bottom: 20px; }

.sponsors-card {
  height: 170px; }

.irish-times-sponsor-logo {
  max-width: 120px !important;
  text-align: right !important;
  display: inline; }

.award-buttons:hover {
  background: #1EC2F3;
  color: #fff; }

.award-buttons:hover .award-button-icon {
  filter: brightness(0) invert(1); }

.submit-entry-btn {
  background: #1ec2f3;
  color: #ffffff;
  text-decoration: none;
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  text-align: center;
  padding: 0.375rem 0.75rem; }

.get-most-btn {
  background: #1ec2f3;
  color: #ffffff;
  text-decoration: none;
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  text-align: center;
  padding: 0.375rem 0.75rem;
  width: 345px; }

.content-item {
  list-style: none;
  padding-left: 15px; }

.content-item .content-item-list::before {
  content: "\2022";
  color: #FBD43B;
  font-weight: bold;
  display: inline-block;
  padding-right: 5px; }

#bis_award_tab .header-text {
  font-weight: 600 !important;
  font-size: 20px; }

.list-icon {
  display: block;
  width: 100%;
  margin-left: auto;
  max-width: 70px;
  margin-right: auto;
  margin-bottom: 15px; }

.checklist-item {
  list-style: none;
  display: flex;
  align-items: start;
  column-gap: 5px; }

.check-icon {
  margin-top: 5px; }

.blue-box {
  background-image: linear-gradient(#62CAF2, #1ec2f3);
  border: none;
  text-align: center;
  border-radius: 25px;
  color: #fff; }

.blue-box-content {
  font-weight: 600;
  font-size: 30px;
  line-height: 1.3;
  max-width: 223px;
  margin: 0 auto; }

.blue-box-content-two {
  font-weight: 600;
  font-size: 30px;
  line-height: 1.3;
  max-width: 280px;
  margin: 0 auto; }

.blue-box-number {
  color: #1ec2f3;
  font-weight: 700;
  font-size: 30px; }

.white-circle-bg {
  background: #fff;
  border-radius: 50%;
  max-width: 46px;
  margin: 0 auto; }

.steps-image-icon {
  max-width: 60px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
  margin-bottom: 15px; }

.arrow-down-button {
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  max-width: 45px;
  margin-top: 30px; }

.view-all-btn {
  background: #1ec2f3;
  border: none;
  padding: 10px 100px;
  color: #fff;
  border-radius: 5px; }

.info-award-header {
  background: #1ec2f3;
  color: #fff; }

.flex-header-item {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px; }

.info-header-icon {
  max-width: 35px;
  width: 100%; }

.event-info-headtext {
  color: #1ec2f3;
  font-weight: 700; }

.check-icon-event {
  width: 100%;
  max-width: 28px;
  margin-right: 10px; }

.info-blue-box {
  background: #1ec2f3;
  border: none;
  border-radius: 10px;
  color: #fff;
  padding: 0px 15px; }

.left-border {
  border-left: 4px solid #FBD43B;
  padding-left: 15px; }

.form-card {
  background: #FAFAFA;
  border: none;
  margin-bottom: 15px; }

.form-input {
  padding: 5px;
  border: 1px solid #C5C5C5;
  border-radius: 5px;
  width: 100%;
  outline: none; }

.details-blue-card {
  background: #1ec2f3;
  border: none;
  color: #fff; }

.details-blue-icon {
  width: 100%;
  max-width: 65px;
  margin: 0 auto;
  display: block; }

.check-blue-icon {
  width: 20px;
  height: 20px;
  margin-right: 15px; }

.blue-left-border {
  border-left: 4px solid #1ec2f3; }

.yellow-left-border {
  border-left: 4px solid #FFB400; }

.profile_logo {
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative; }

.profile-pencil svg {
  font-size: 14px;
  margin-top: 4px;
  margin-left: 10px; }

.caution-icon {
  width: 25px;
  height: 24px;
  margin-right: 15px; }

.white-bg-circle {
  position: absolute;
  bottom: 3px;
  left: 65px;
  right: 0;
  padding: 7px;
  background: #fff;
  max-width: 40px;
  border-radius: 50%;
  border: 1px solid #DEE2E6;
  cursor: pointer; }

.white-bg-circle svg {
  font-size: 18px;
  margin-left: 3px; }

.profile-blue-icon {
  width: 100%;
  max-width: 40px; }

.profile-left-icon {
  width: 100%;
  max-width: 24px;
  margin-right: 7px;
  height: 24px; }

.profile-left-btn:hover {
  background: #1ec2f3;
  color: #fff; }

.payment-left-btn {
  background: #1ec2f3;
  color: #fff; }

.payment-left-btn:hover {
  background: #fff;
  color: #000; }

.profile-left-btn:hover .profile-left-icon {
  filter: invert(100%); }

.profile-left-btn:hover svg {
  color: #fff; }

.progress-bar-percentage {
  height: 10px;
  background: #05CC33;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  font-size: 10px;
  overflow: hidden; }

.blue-line-horizontal {
  max-width: 4px;
  width: 100%;
  height: 100%;
  max-height: 77px;
  background: #1ec2f3;
  margin: 0 auto; }

.blue-line-horizontal-second {
  max-width: 4px;
  width: 100%;
  height: 100%;
  max-height: 48px;
  background: #1ec2f3;
  margin: 0 auto; }

.header-text {
  font-weight: 600 !important;
  font-size: 20px; }

.circle-check-notify {
  border: 1px solid #1ec2f3;
  padding: 5px 1px;
  border-radius: 50%;
  max-width: 35px;
  margin: 0 auto; }

.blue-highlight-text {
  padding: 3px 5px;
  background: #51D8FF;
  max-width: 183px;
  margin-top: 3px;
  border-radius: 5px;
  color: #fff; }

.circle-caution-notify {
  border: 1px solid #1ec2f3;
  padding: 6px 5px;
  border-radius: 50%;
  max-width: 35px;
  margin: 0 auto; }

.disabled-btn {
  cursor: not-allowed !important; }

.terms-condition-btn {
  background: #C9C9C9;
  color: #fff; }

.terms-condition-btn .profile-left-icon {
  filter: invert(100%); }

.terms-condition-book {
  background: #C9C9C9;
  color: #ffffff;
  text-decoration: none;
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  cursor: not-allowed !important; }

.refer-judge-btn {
  padding: 5px 20px !important; }

.active .profile-left-btn {
  background: #1ec2f3;
  color: #fff; }

.active .entrant-left-icon {
  filter: brightness(100) invert(0); }

.active .profile-left-btn .profile-left-icon {
  filter: invert(100%); }

.active.profile-left-btn {
  background: #1ec2f3;
  color: #fff; }

.active.profile-left-btn .profile-left-icon {
  filter: invert(100%); }

.blue-col-modal {
  background: #1ec2f3;
  border-radius: 5px 0px 0px 5px; }

.image-white-bg {
  background: #fff;
  padding: 25px;
  max-width: 130px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 10px; }

.divider-modal {
  margin-top: 7px;
  margin-bottom: 7px;
  border-width: 1px; }

.checklist-list p {
  font-size: 0.8em; }

.wrap-description {
  white-space: pre-wrap; }

.profile-info .profile-data {
  padding: 0 !important;
  border: 0 !important;
  outline: none;
  font-weight: 700;
  display: inline-block; }

.profile-info .profile-data:hover {
  text-decoration: underline; }

#bis-footer {
  position: absolute;
  bottom: 0;
  width: 100%; }

.entry-data .profile-data {
  width: 170px;
  margin-left: 31px;
  padding: 0;
  outline: none;
  border: 0;
  margin-bottom: 20px; }

.image-column .circle-white-bg {
  position: absolute;
  bottom: -10px;
  right: -5px;
  padding: 8px;
  background: #fff;
  max-width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #DEE2E6;
  cursor: pointer; }

.image-column .circle-white-bg svg {
  font-size: 13px;
  margin-bottom: 8px; }

.checklist-icon {
  width: 100%;
  max-width: 20px;
  margin-right: 10px;
  max-height: 20px; }

.checklist {
  display: flex; }

#Popover1 small {
  color: #fff !important; }

.history .items {
  padding-left: 18px; }

.history .items .items-list {
  margin-bottom: 10px; }

.message-date {
  color: #B9B9B9;
  font-size: 15px;
  background: #fff;
  margin-top: -28px;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto; }

.ticket-description,
.award-description {
  white-space: pre-line; }

.comment-receive {
  width: fit-content !important;
  max-width: 230px !important;
  min-width: 230px !important;
  margin-bottom: 10px; }

.comment-send {
  width: fit-content !important;
  max-width: 230px !important;
  min-width: 230px !important;
  background: #DEF7FF;
  border: 1px solid #BBECFB;
  margin-left: auto;
  margin-bottom: 10px; }

.comment-send-name {
  max-width: 230px !important;
  float: right;
  width: 100%;
  font-size: 12px;
  margin-bottom: -14px !important;
  position: absolute;
  bottom: -10px;
  left: 0;
  text-align: right; }

.comment-receive-name {
  max-width: 230px !important;
  float: right;
  width: 100%;
  font-size: 12px;
  margin-bottom: -14px !important;
  position: absolute;
  bottom: -10px;
  right: 0; }

.user-icon-image {
  margin-left: auto;
  width: 100%;
  max-width: 35px;
  margin-right: auto;
  display: block;
  margin-bottom: 10px; }

.history .comment-scroll {
  overflow-y: scroll;
  height: 400px;
  overflow-x: hidden; }

.judging-panel-name {
  color: #1ec2f3; }

.judging-panel:hover .judging-panel-name {
  color: #fff; }

.judge-panel-img {
  /* position: absolute; */
  border-radius: 50%;
  border: 1px solid #ffffff;
  /* left: 50px; */
  /* top: -70px; */
  margin-top: -100px;
  position: relative;
  margin-bottom: 20px; }

.entrant-judge-panel-img {
  border-radius: 50%;
  border: 1px solid #ffffff;
  margin-top: -100px;
  position: relative;
  margin-bottom: 20px;
  width: 150px;
  height: 150px;
  background-position: center;
  background-size: cover;
  margin-left: auto;
  margin-right: auto; }

.judging-panel {
  background-color: #EDEDED; }

.judging-panel:hover {
  color: #ffffff;
  background-color: #1ec2f3; }

.entrant-award-subheader {
  color: #1ec2f3;
  font-size: 21px;
  font-weight: 700; }

.entrant-entries {
  font-size: 70px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center; }

.entrant-icon {
  width: 60px;
  height: 60px; }

.entrant-widget {
  display: flex;
  align-items: center;
  cursor: pointer; }

.entrant-category-check {
  position: absolute;
  z-index: 1;
  color: #05CC33;
  top: 8px;
  right: 22px; }

#bis_award_tab .category-link:hover .entrant-category-check {
  color: #ffffff; }

.slick-next {
  right: 0px !important; }

.slick-prev {
  left: 0px !important; }

.sliding-category svg {
  width: 30px !important;
  height: 30px !important;
  color: #fff !important;
  background: #000 !important;
  padding: 5px !important;
  border-radius: 50%; }

.sliding-category svg:hover {
  background: #000 !important;
  opacity: 0.4; }

.category-slider {
  max-width: 240px;
  margin: 0 auto;
  display: block !important; }

.category-slider-card:hover {
  color: #ffffff;
  background-color: #1ec2f3; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0 auto !important;
  padding: 0;
  max-width: 750px; }

.list-inline-item {
  background-image: url(../assets/img/icons/timer-bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 110px;
  height: 95px;
  font-family: 'DS-Digital', sans-serif;
  font-size: 60px;
  color: #fff;
  line-height: 0.4;
  font-weight: 600;
  border-radius: 10px;
  padding-top: 25px;
  align-items: center; }

.list-inline-item {
  background-image: url(../assets/img/icons/timer-bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 110px;
  height: 95px;
  font-family: 'DS-Digital', sans-serif;
  font-size: 60px;
  color: #fff;
  line-height: 0.4;
  font-weight: 600;
  border-radius: 10px;
  padding-top: 25px;
  align-items: center; }

.timer-text {
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  font-family: Poppins; }

.list-inline-item-home {
  background-image: url(../assets/img/icons/timer-bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 220px;
  height: 95px;
  font-family: 'DS-Digital', sans-serif;
  font-size: 60px;
  color: #fff;
  line-height: 0.4;
  font-weight: 600;
  border-radius: 10px;
  padding-top: 25px;
  align-items: center;
  display: inline-block;
  margin: 0px 20px; }

.card-grey {
  background: #F2F2F2; }

#bis_award_tab .tab_btn_container {
  margin-top: 0px !important; }

.custom-select {
  background: #fff;
  border-radius: 5px;
  color: #000;
  height: 40px;
  padding: 10px;
  width: 100%;
  border: 1px solid #D4D4D4; }

#custom-select-content {
  float: left;
  margin-top: -2px; }

#custom-select-icon {
  float: right; }

#custom-select-icon svg {
  margin-bottom: 5px; }

.custom-select-options {
  background: #fff;
  border-radius: 5px;
  padding: 20px 10px;
  position: absolute;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  transition: .5s;
  width: 100%;
  z-index: 5;
  border: 1px solid #D4D4D4;
  margin-top: -10px;
  box-shadow: 0px 1px 4px 1px #d4d4d4;
  overflow-y: scroll;
  max-height: 400px; }

.custom-select-options ul {
  list-style: none;
  padding-left: 25px;
  margin-bottom: 0; }

.custom-select-options ul li {
  margin-bottom: 10px;
  line-height: 1.3; }

.custom-select-options [type='checkbox'] {
  margin-top: 3px; }

.header-social-image {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
  max-width: 45px; }

.header-social-total {
  text-align: center;
  font-size: 23px;
  font-weight: 700;
  margin-bottom: 0; }

.header-social-text {
  text-align: center;
  margin-bottom: 0; }

.header-card {
  height: 155px;
  /* max-height: 146px; */ }

.social-header {
  text-align: center;
  font-size: 22px;
  font-weight: 700; }

.search-bar {
  max-width: 300px;
  position: relative; }

.search-gallery svg {
  position: absolute;
  z-index: 1;
  right: 125px;
  bottom: 22px;
  cursor: pointer; }

.upload .card {
  max-width: 140px;
  margin-left: auto; }

.gallery-image {
  width: 100%;
  max-width: 185px;
  position: relative;
  margin-left: auto;
  margin-right: auto; }

.gallery-card-icon {
  color: #ababab; }

.gallery-card-icon:hover {
  color: #000000; }

.upload button {
  background: #fff;
  border: 1px solid #DFDFDF;
  padding: 4px 30px; }

.header-modal-img {
  width: 100%;
  max-width: 40px;
  margin-right: 10px; }

.modal-card-gal {
  border-top: none;
  border-radius: 0;
  height: 350px; }

.upload-modal-img {
  width: 100%;
  max-width: 25px;
  margin-right: 10px; }

.modal-card-gal .card-footer {
  background: #EBEBEB; }

.modal-card-gal .card-footer button {
  background: #1ec2f3; }

.modal-close {
  margin-top: -12px !important; }

.gal-modal-text {
  text-align: center;
  font-size: 14px;
  margin-bottom: 10px !important; }

.tag-image svg {
  position: absolute;
  z-index: 1;
  font-size: 25px;
  top: 7px;
  left: 27px; }

.tag-grey-bg {
  background: #EBEBEB;
  margin-bottom: 10px; }

.tag-grey-bg svg {
  font-size: 18px; }

.done-button {
  background: #EBEBEB; }

.days-select {
  width: 115px;
  margin-left: auto; }

.social-graph-image {
  width: 30px;
  height: 30px;
  margin-right: 10px; }

.bar-card {
  width: 100%;
  height: 65px;
  border-radius: 0; }

.bar-card .card-body {
  padding: 0; }

.bar-graph-blue {
  border: 22px solid #1EC2F3;
  align-items: center;
  margin-top: 10px;
  width: auto; }

.graph-level {
  display: flex;
  column-gap: 13px;
  margin-left: 40px; }

.social-percent-share {
  background: #F8F9FA;
  border: 0;
  border-radius: 0; }

.social-name {
  font-weight: 700;
  margin-bottom: 0; }

.share-count,
.share-percent {
  margin-bottom: 0; }

.social-progress-bar-container {
  width: 100%;
  height: 10px;
  background: #fff;
  border-radius: 5px;
  position: relative; }

.social-progress-bar-percent {
  height: 10px;
  background: #1ec2f3;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  font-size: 10px;
  overflow: hidden; }

.percent-number-center {
  text-align: center;
  margin-top: -59px;
  font-weight: 700;
  margin-bottom: 45px; }

.send-comment-button {
  border: 0;
  background: transparent;
  outline: none;
  margin: 0 auto;
  display: block; }

.send-comment-button:focus {
  outline: none; }

.social-lg-card {
  height: 566px; }

.image-base-tags {
  display: flex;
  column-gap: 20px;
  align-items: center;
  justify-content: center; }

.version-scrollable a {
  font-size: 0.8em; }

.version-scrollable li {
  line-height: 1 !important;
  margin-bottom: 5px !important; }

.version-scrollable.logs li {
  line-height: 1.5 !important;
  font-size: 12px; }

.checklist-scrollable {
  overflow-x: hidden; }

.version-scrollable ul {
  padding-left: 20px;
  list-style-type: none;
  padding-left: 0; }

.submittion-entries-card {
  height: 160px; }

.card-popup-function {
  border: 0;
  border-radius: 0;
  background: transparent; }

.card-popup-function-body {
  padding: 0; }

.card-popup-funtion-footer {
  text-align: right;
  padding: 0px;
  visibility: hidden; }

.card-popup-function:hover {
  border: 1px solid #DFDFDF;
  border-radius: 5px;
  background: #fff; }

.card-popup-function-body:hover {
  padding: 5px; }

.card-popup-funtion-footer:hover {
  visibility: visible;
  padding: 5px 10px; }

.media-tabs .card {
  text-align: center;
  display: block;
  cursor: pointer;
  background: #ebebeb;
  border: none; }

.media-tabs .active.card {
  color: #212529;
  background: #fff;
  border: 1px solid #DFDFDF; }

.edit-max-score::-webkit-inner-spin-button {
  opacity: 1; }

.edit-max-score {
  font-size: 16px;
  padding: 0;
  border: 0;
  margin-top: -8px; }

.edit-max-score:focus {
  outline: none; }

.bis-text {
  color: #1ec2f3;
  font-weight: 600; }

.hr-divider {
  margin-top: 0px !important; }

.custom-select-categories {
  background: #fff;
  border-radius: 5px;
  color: #000;
  height: 40px;
  padding: 10px;
  width: 100%;
  max-width: 300px;
  border: 1px solid #D4D4D4; }

.custom-select-categories:focus {
  outline: none; }

.custom-select-options-categories {
  background: #fff;
  border-radius: 5px;
  padding: 20px 10px;
  position: absolute;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  transition: .5s;
  width: 100%;
  max-width: 300px;
  z-index: 5;
  border: 1px solid #D4D4D4;
  margin-top: -10px;
  box-shadow: 0px 1px 4px 1px #d4d4d4; }

.custom-select-options-categories ul {
  list-style: none;
  padding-left: 25px;
  margin-bottom: 0; }

.custom-select-options-categories ul li {
  margin-bottom: 10px;
  line-height: 1.3; }

.custom-select-options-categories [type='checkbox'] {
  margin-top: 3px; }

#bis_award_tab .booking-nav .nav-item {
  width: 100%;
  max-width: 100% !important; }

#bis_award_tab .booking-nav .active.card {
  color: #212529;
  background: #fff;
  border: 1px solid #DFDFDF; }

.booking-nav .card {
  font-weight: 700;
  font-size: 17px;
  text-align: center;
  display: block;
  cursor: pointer;
  background: #ebebeb;
  padding: 15px; }

.booking-nav .card:hover {
  color: #212529;
  background: #ebebeb; }

.attendee-card .attendee_input {
  position: relative; }

.attendee-card .pencil-svg {
  position: absolute;
  top: 61px;
  right: 25px;
  bottom: 0;
  font-size: 13px; }

.attendee-card .person-icon {
  font-size: 25px;
  margin-bottom: 3px; }

.attendee_input:focus + .pencil-svg {
  display: none;
  visibility: hidden; }

#bis_award_tab .logo-img {
  width: 100%;
  max-width: 65px; }

.update-document-button {
  width: 100%;
  background: #1ec2f3;
  color: #fff;
  outline: none;
  border: none;
  padding: 7px;
  border-radius: 5px; }

.update-document-button:focus {
  outline: none; }

.update-document-button:hover {
  background: #267FC3; }

.text-xoverflow {
  max-width: auto;
  overflow-x: auto;
  white-space: nowrap; }

.event-information-print-mgnt p {
  margin-bottom: 0px; }

.event-information-print-mgnt svg {
  margin-bottom: 0px;
  margin-right: 7px; }

.artwork-assigned-btn {
  background: gold;
  outline: none;
  border: 2px solid gold;
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff; }

.artwork-design-revision-btn {
  background: #E8500D;
  outline: none;
  border: 2px solid #E8500D;
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff; }

.artwork-design-review-btn {
  background: #8E7CC3;
  outline: none;
  border: 2px solid #8E7CC3;
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff; }

.artwork-approved-btn {
  background: #1ec2f3;
  outline: none;
  border: 2px solid #1ec2f3;
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff; }

.artwork-print-revision-btn {
  background: #a73807;
  outline: none;
  border: 2px solid #a73807;
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff; }

.artwork-for-printing-btn,
.artwork-printing-in-progress-btn {
  background: #CF9905;
  outline: none;
  border: 2px solid #CF9905;
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff; }

.artwork-done-btn {
  background: #049818;
  outline: none;
  border: 2px solid #049818;
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff; }

.artwork-design-in-progress-btn {
  background: #A9D153;
  outline: none;
  border: 2px solid #A9D153;
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff; }

.artwork-design-for-approval-btn {
  background: #D80BB6;
  outline: none;
  border: 2px solid #D80BB6;
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff; }

.artwork-status-btn {
  min-width: 200px; }

.artwork-profile-img {
  width: 100%;
  max-width: 40px;
  margin-left: 20px; }

.artwork-upload-docs {
  background: #fff;
  border: 1px solid #DEE2E6;
  border-radius: 3px;
  padding: 6px 15px;
  margin: 15px 0px;
  outline: none; }

.design-upload-container {
  min-height: 144px;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative; }

h6.comment-info-head {
  color: #8D8D8D;
  margin-bottom: 0px;
  font-size: 12px; }

.comment-date-head {
  display: grid;
  grid-template-columns: auto auto auto;
  max-width: 280px;
  align-content: center;
  align-items: center;
  margin: 1px 0px; }

.date-divider {
  width: 1px;
  background: #DEE2E6;
  height: 100%; }

.comment-area {
  border: 0;
  outline: 0;
  background-color: transparent;
  resize: none;
  box-shadow: none !important; }

.artwork-comment-body {
  background-color: #F2F2F2;
  border: none;
  border-radius: 0;
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  padding-top: 10px;
  padding-bottom: 10px; }

.revision-alert {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between; }

.revision-alert-card {
  background: #FE240C66;
  color: #fff;
  border: 2px solid #EE786B;
  margin-bottom: 15px; }

.header-alert {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: start;
  text-align: center; }

.entry-support-icons {
  margin-bottom: 25px;
  max-width: 150px;
  width: 100%; }

.entry-support-card:hover {
  transform: scale(1.1);
  cursor: pointer; }

.entry-support-card {
  box-shadow: 0px 0px 20px 0px #00000014;
  border: none;
  transition: all .3s ease;
  height: 280px;
  width: 100%;
  margin-bottom: 20px; }

.newbuttontheme {
  background-color: #1ec2f3;
  border: 1px solid #1ec2f3;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff; }

.newbuttontheme:hover {
  color: #fff;
  background: #1ec2f3;
  border: 1px solid #1ec2f3; }

.newbuttontheme:focus {
  outline: none; }

.entrant-home-card {
  box-shadow: 0px 0px 20px 0px #00000014;
  border: none;
  transition: all .3s ease;
  height: 100%;
  width: 100%; }

.entrant-home-card:hover {
  transform: scale(1.1);
  cursor: pointer; }

.profile-left-btn:hover .entrant-left-icon {
  filter: brightness(0) invert(1); }

.payment-left-btn:hover .entrant-left-icon {
  color: #1ec2f3 !important; }

.profile-left-btn .entrant-left-icon {
  width: 100%;
  max-width: 24px;
  margin-right: 7px;
  height: 24px;
  color: #1ec2f3 !important; }

.payment-left-btn .entrant-left-icon {
  width: 100%;
  max-width: 24px;
  margin-right: 7px;
  height: 24px;
  color: #fff !important; }

.status-resubmit {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  column-gap: 10px; }

.edit-entry-image {
  width: 100%;
  max-width: 160px; }

.single-entry-forms {
  margin-left: 30px; }

.single-entry-pop-up {
  padding: 6px 13px;
  border: 1px solid #CED4DA;
  margin-left: 30px;
  margin-bottom: 10px; }

.white-background-circle {
  position: absolute;
  right: -10px;
  bottom: -16px;
  height: 35px;
  width: 35px;
  background: #fff;
  max-width: 50px;
  border-radius: 50%;
  border: 1px solid #DEE2E6;
  cursor: pointer; }

.white-background-circle svg {
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 7px; }

.sample-winning-head {
  color: #fff;
  text-align: center;
  max-width: 85px;
  margin-left: auto;
  margin-right: auto;
  padding: 12px;
  font-size: 40px;
  font-weight: 600;
  border-radius: 50%;
  margin-top: 10px;
  margin-bottom: 15px; }

.sample-winning-entry-card {
  border-radius: 10px !important;
  height: 355px; }

.sample-winning-entry-card .card-header {
  border-radius: 20px 20px 0px 0px !important; }

.sample-winning-introduction {
  background-image: linear-gradient(#277DC2, #1ec2f3);
  border-radius: 0px 0px 10px 10px;
  border: none; }

.sample-winning-mainbody {
  background-image: linear-gradient(#DEB51D, #E6C23C);
  border-radius: 0px 0px 10px 10px;
  border: none; }

.sample-winning-support {
  background-image: linear-gradient(#50A546, #65CE59);
  border-radius: 0px 0px 10px 10px;
  border: none; }

.sample-winning-materials {
  background-image: linear-gradient(#C2272E, #F31E1E);
  border-radius: 0px 0px 10px 10px;
  border: none; }

.sample-winning-items {
  padding-left: 30px;
  color: #fff; }

.entrant-home-modal-image {
  width: 100%;
  max-width: 250px;
  margin-bottom: 20px;
  margin-top: 20px; }

.award-profile {
  max-width: 20px;
  margin-right: 7px;
  height: 20px; }

.notification-time {
  background: #1ec2f3;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 5px;
  color: #fff;
  max-width: 220px;
  text-align: center; }

.jucor-time {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  column-gap: 10px;
  align-items: center; }

.jucor-time-card {
  display: grid;
  grid-template-columns: auto auto;
  justify-items: center; }

.invitation-progress-bar-container {
  width: 132px;
  height: 10px;
  background: #a5a5a5;
  border-radius: 5px;
  position: relative; }

.invitation-progress-bar-percent {
  height: 10px;
  background: #1ec2f3;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  font-size: 10px;
  overflow: hidden; }

.profile-link-buttons {
  margin-right: 7px;
  width: 100%;
  padding: 7px 10px;
  border: none;
  border-radius: 3px;
  color: #fff;
  font-weight: 500;
  max-width: 230px; }

.profile-link-buttons:focus {
  outline: none; }

.profile-link-icons {
  width: 100%;
  max-width: 17px;
  margin-top: -5px; }

.accordion-head {
  display: flex;
  justify-content: space-between; }

.accordion-radio {
  margin-right: 14px;
  display: inline-flex;
  column-gap: 10px; }

.accordion-title {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 5px;
  align-items: center; }

.accordion-drop {
  max-width: 335px;
  position: absolute;
  z-index: 1; }

.reviewed-categories {
  background-color: #1ec2f3;
  border: 1px solid #1ec2f3;
  cursor: normal; }

.time-header-judge {
  box-shadow: 0px 0px 20px 0px #00000014;
  border: none; }

.comment-auto-scroll {
  overflow-y: scroll;
  height: 400px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column-reverse;
  bottom: 0;
  position: relative; }

.connected-accounts {
  position: absolute;
  top: 18px;
  right: 55px; }

.gallery-home-card {
  box-shadow: 0px 0px 20px 0px #00000014;
  border: none;
  transition: all .3s ease;
  height: 100%;
  width: 100%;
  max-width: 700px;
  margin: 30px auto; }

.gallery-home-card:hover {
  transform: scale(1.1, 1.1);
  cursor: pointer; }

.gallery-home-card img {
  width: 100%;
  max-width: 290px; }

.gallery-checkbox {
  display: flex;
  justify-content: space-between; }

.gallery-custom-select {
  background: #fff;
  border-radius: 5px;
  color: #000;
  height: 38px;
  padding: 10px;
  width: 100%;
  border: 1px solid #D4D4D4;
  max-width: 140px; }

.gallery-custom-select:focus {
  outline: none; }

#gallery-custom-select-content {
  float: left;
  margin-top: -2px; }

#gallery-custom-select-icon {
  float: right; }

#gallery-custom-select-icon svg {
  margin-bottom: 8px; }

.gallery-custom-select-options {
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  transition: .5s;
  width: 100%;
  max-width: 129px;
  z-index: 9999;
  border: 1px solid #D4D4D4;
  margin-top: -12px;
  box-shadow: 0px 1px 4px 1px #d4d4d4;
  max-height: 400px; }

.gallery-custom-select-options ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0; }

.gallery-custom-select-options ul li {
  line-height: 1.4; }

.gallery-custom-select-options svg {
  color: #1ec2f3;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 20px; }

.gallery-custom-select .block-icon {
  color: #1ec2f3;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 20px; }

.gallery-search-bar svg {
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 20px; }

.gallery-image {
  width: 100%; }

.button-theme-hallow {
  border-radius: 5px;
  border: 1px solid #DEE2E6;
  padding: 0.375rem 0.75rem;
  background: #fff; }

.button-theme-hallow:hover {
  background: #1ec2f3;
  color: #fff; }

.button-theme-hallow:focus {
  outline: none; }

.button-theme-hallow:hover svg {
  color: #fff; }

.button-theme-hallow .seat-veiw-edit-icon {
  color: #000; }

.button-theme-hallow svg {
  font-size: 20px;
  margin-right: 5px; }

.navigation-arrow {
  place-self: center;
  text-align: center; }

.gallery-image-preview.modal-dialog {
  max-width: 500px; }

.gallery-background-image {
  background-size: cover;
  background-position: center;
  min-height: 145px;
  width: 100%;
  position: relative; }

.gallery-background-image-large {
  background-size: cover;
  background-position: center;
  min-height: 250px;
  width: 100%; }

.navigation-arrow-button {
  background: transparent;
  border: none;
  outline: none;
  width: 80px;
  text-align: center; }

.navigation-arrow-button:focus {
  outline: none; }

.navigation-arrow-button svg {
  font-size: 70px;
  opacity: 0.24; }

.social-share {
  box-shadow: 0px 0px 20px 0px #00000014;
  border: none;
  height: 100%;
  width: 100%;
  cursor: pointer; }

.social-share img {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  max-width: 170px; }

.remove-icon {
  position: absolute;
  right: 25px;
  top: 25px;
  color: red;
  background: #fff;
  border-radius: 50%;
  font-size: 20px; }

.email-badge {
  background: #E4E4E4;
  color: #6E6E6E;
  width: auto;
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px !important;
  border-radius: 6px;
  padding: 4px 8px; }

.email-badge svg {
  margin-top: 4px;
  color: #fff;
  margin-bottom: 2px;
  float: right;
  background: red;
  border-radius: 50px;
  cursor: pointer; }

.social-media-icons svg {
  margin: 10px 20px;
  font-size: 25px;
  color: #C4C5C5; }

.social-media-icons svg:hover {
  background-color: #EEEEEE;
  border-radius: 5px;
  padding: 4px;
  transform: scale(1.5);
  font-size: 25px;
  color: #858583;
  cursor: pointer; }

.add-social-account {
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer; }

.social-account {
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px; }

.social-account:hover {
  background: #EBEBEB; }

.active .social-account {
  background: #EBEBEB; }

.social-account img {
  width: auto;
  height: 50px;
  border-radius: 100px;
  border: 1px solid rgba(0, 0, 0, 0.2); }

.social-account.card p,
.add-social-account.card p {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.social-account-checkbox {
  position: absolute;
  right: 15px;
  left: auto;
  top: 15px; }

.add-social-account:hover {
  background: #EBEBEB; }

.add-social-account svg {
  background: #F2F2F2;
  padding: 10px;
  font-size: 50px;
  border-radius: 50%;
  color: #858583; }

.add-social-account:hover svg {
  background: #D8D8D8;
  color: #3E3F3E; }

.select-all-icon {
  margin-right: 10px;
  font-size: 20px;
  border-radius: 50%;
  transform: scale(1.4);
  padding: 3px;
  color: #fff; }

.image-checkbox {
  position: absolute;
  z-index: 999;
  top: 5px;
  left: 20px; }

.button-icon-image {
  position: absolute;
  z-index: 9;
  bottom: 5px;
  right: 5px; }

.play-btn {
  width: 50px;
  height: 50px;
  /* opacity: 0.5; */
  position: absolute;
  top: 65px;
  left: 65px;
  color: #fff;
  cursor: pointer; }

.newbuttontheme.gallery-filter-btn {
  background: #fff;
  border: 1px solid #dfdfdf;
  color: #212529; }

.newbuttontheme.gallery-filter-btn.active {
  background-color: #1ec2f3;
  border: 1px solid #1ec2f3;
  color: #ffffff; }

.preview-media-image,
.preview-media-video {
  width: 100%;
  height: auto; }

.image-overlay-container {
  position: relative;
  width: 100%;
  max-height: 185px;
  min-height: 185px;
  background-size: cover;
  background-position: center;
  margin-bottom: 10px; }

.create-award-logo {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 200px;
  height: 150px;
  margin-left: auto;
  margin-right: auto; }

.image-overlay-container .overlay {
  position: absolute;
  top: 0;
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center; }

.image-overlay-container:hover .overlay {
  opacity: 1; }

.add-more-gallery .active p {
  color: #1c2023 !important; }

.sm-account-thumb:hover .social-small-icon-close {
  display: inline-block; }

.sm-account-thumb:hover .social-small-icon {
  display: none; }

.social-profile-image-header {
  border-radius: 50%;
  max-width: 50px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.2); }

.add-more-account {
  height: 50px;
  color: #BFBFBF;
  padding: 12px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid #BFBFBF;
  cursor: pointer;
  position: static;
  min-width: 50px; }

.social-media-ellipsis {
  max-width: 313px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.social-media-ellipsis a {
  text-decoration: none; }

.sm-account-thumb-controller {
  max-width: 640px;
  overflow: hidden;
  overflow-x: auto;
  padding-bottom: 10px; }

.sm-account-thumb-controller::-webkit-scrollbar {
  width: 5px;
  height: 8px; }

.add-more-account-pop-up::-webkit-scrollbar {
  width: 5px;
  height: 8px; }

.sm-account-thumb-controller::-webkit-scrollbar-track {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }

.add-more-account-pop-up::-webkit-scrollbar-track {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }

.sm-account-thumb-controller::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #1ec2f3;
  outline: 1px solid rgba(0, 0, 0, 0.2); }

.add-more-account-pop-up::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #1ec2f3;
  outline: 1px solid rgba(0, 0, 0, 0.2); }

.sm-account-thumb-controller::-webkit-scrollbar:vertical {
  display: none; }

.add-more-account-pop-up::-webkit-scrollbar:vertical {
  display: none; }

.comment-upload {
  border: none;
  margin-bottom: 10px;
  margin-top: 10px; }

.comment-upload:focus {
  outline: none !important; }

.social-small-icon {
  position: absolute;
  right: 10px;
  padding: 3px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #fff;
  z-index: 9; }

.light-spinner-btn .spinner-border {
  max-width: 24px;
  max-height: 24px; }

.social-small-icon-close {
  position: absolute;
  right: 10px;
  width: 20px;
  display: none;
  height: 20px;
  border-radius: 50%;
  color: red;
  background: #fff;
  cursor: pointer;
  z-index: 9; }

.social-media-publish .card {
  width: 100%;
  max-width: 200px;
  align-items: center;
  align-content: center;
  display: grid;
  justify-content: center;
  cursor: pointer;
  background: #F2F2F2; }

.social-media-publish svg.small-icon-close,
.gallery-background-image-large svg.small-icon-close {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 20px;
  display: inline-block;
  height: 20px;
  padding: 0;
  border-radius: 50%;
  color: red;
  background: #fff;
  cursor: pointer;
  z-index: 1;
  border: none; }

.social-media-publish .card:hover svg,
.social-media-publish .card:hover p {
  color: #000; }

.social-media-publish svg {
  font-size: 35px;
  color: #999898;
  border: 2px dashed;
  padding: 16px;
  width: 80px;
  height: 80px; }

.social-media-publish p {
  color: #999898; }

.social-image-option {
  margin-top: 15px; }

.social-image-option svg {
  color: #A2A2A3;
  font-size: 25px; }

.social-image-option svg:hover {
  color: #313031; }

.schedule-post.card {
  position: absolute;
  top: -155px;
  left: 10px;
  text-align: center;
  width: 100%;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #00000047;
  z-index: 1; }

.remove-media-icon {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 1; }

.custom-video-play {
  color: #1ec2f3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0 !important; }

.selection-div:hover .bis-tooltip {
  display: block; }

.selection-div .bis-tooltip {
  z-index: 1;
  right: 8px;
  padding: 0;
  background: none;
  border: 0;
  top: 8px;
  display: none;
  position: absolute;
  transition: 0.3s; }

#edit-profile-page.preview .award_entry_content:hover {
  color: #495057; }

.selection-add-more {
  cursor: pointer;
  background: #F2F2F2; }

.selection-add-more svg {
  border: 2px dashed;
  padding: 16px;
  margin-top: 5px; }

.profile-picture-container {
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 500px; }

.gif-loading {
  width: 100%;
  max-width: 100px;
  margin-top: 15px; }

.custom-radio {
  background: #fff;
  text-align: center;
  border-radius: 50px;
  cursor: pointer;
  max-height: 14px;
  min-height: 14px;
  min-width: 14px;
  max-width: 14px;
  display: inline-block;
  vertical-align: middle;
  border: 2px solid #1ec2f3;
  padding: 1px;
  margin-right: 5px; }

.custom-radio.active .custom-tick {
  width: 8px;
  height: 8px;
  background: #1ec2f3;
  border-radius: 10px; }

.custom-checkbox {
  background: #fff;
  padding: 1px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  max-height: 16px;
  min-height: 16px;
  min-width: 16px;
  max-width: 16px;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid rgba(0, 0, 0, 0.2); }

.custom-checkbox.small {
  max-height: 14px;
  min-height: 14px;
  min-width: 14px;
  max-width: 14px; }

.custom-checkbox.small svg {
  width: 10px;
  height: 10px; }

.custom-checkbox.thumbnail-checkbox {
  position: absolute;
  z-index: 1;
  top: 5px;
  left: 5px; }

.custom-checkbox.active {
  background: #1ec2f3; }

.custom-checkbox .custom-check {
  line-height: 1.2;
  display: none; }

.custom-checkbox.active .custom-check {
  display: block; }

.add-more-account-pop-up {
  text-align: center;
  position: absolute;
  z-index: 9;
  top: 70px;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 0px 10px 0px #00000047;
  max-width: 300px;
  min-width: 300px;
  overflow-x: auto; }

.select-image-box svg {
  color: #1ec2f3; }

.select-image-box {
  margin-top: 75px; }

.bis-needs-action-link {
  color: #267FC3;
  text-decoration: underline;
  cursor: pointer; }

.bis-needs-action-link:hover {
  color: #1ec2f3; }

.priority-card {
  margin-bottom: 20px;
  position: relative; }

.allocation-name {
  font-weight: 700;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
  display: block;
  cursor: pointer; }

.social-media-add-container {
  display: flex; }

.badge-big {
  font-size: 16px;
  padding: 7px 10px;
  font-weight: 400; }

.priority-number {
  color: #ffffff;
  height: 30px;
  width: 30px;
  padding-top: 3px;
  text-align: center;
  border-radius: 50px;
  display: inline-block; }

.priority-number-percent {
  height: 40px;
  width: 40px;
  font-size: 14px;
  padding-top: 10px;
  font-weight: 400;
  vertical-align: middle; }

.priority-number.priority-number-big {
  color: #ffffff;
  height: 40px;
  width: 40px;
  text-align: center;
  border-radius: 50px;
  padding-top: 8px;
  display: inline-block; }

.priority-number.priority-number-small {
  font-size: 12px;
  padding-top: 7px; }

.judge-allocation-row {
  cursor: pointer; }

.cursor-pointer {
  cursor: pointer !important; }

.cursor-none {
  cursor: auto !important; }

.gallery-video {
  object-fit: cover;
  background-color: #212529;
  border: 0; }

.video-controller {
  max-height: 185px;
  min-height: 185px; }

.media-description {
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  padding: 5px 10px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 12px; }

.media-category {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.media-div-container:hover .media-edit,
.media-div-container:hover .media-delete,
.media-div-container:hover .media-copy {
  display: block; }

.video-controller:hover .media-edit,
.video-controller:hover .media-delete,
.video-controller:hover .media-copy {
  display: block; }

.active-logo-container {
  min-height: 135px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain, cover;
  position: relative;
  border: 2px solid #dfdfdf; }

.logo-div-container:hover .logo-download,
.logo-div-container:hover .logo-delete {
  display: block; }

.logo-delete {
  position: absolute;
  padding: 7px;
  border-radius: 50px;
  background: #ef2b08;
  padding-top: 4px;
  right: 19px;
  top: 14px;
  z-index: 2;
  line-height: 1;
  display: none;
  cursor: pointer; }

.logo-delete:hover {
  background: #fff; }

.logo-delete:hover svg {
  color: #ef2b08 !important; }

.logo-download {
  position: absolute;
  padding: 7px;
  border-radius: 50px;
  background: #64ce58;
  padding-top: 4px;
  right: 50px;
  top: 14px;
  z-index: 2;
  line-height: 1;
  display: none;
  cursor: pointer; }

.logo-download:hover {
  background: #fff; }

.logo-download:hover svg {
  color: #64ce58 !important; }

.media-copy {
  position: absolute;
  padding: 7px;
  border-radius: 50px;
  background: #008000;
  padding-top: 4px;
  right: 78px;
  top: 8px;
  z-index: 2;
  line-height: 1;
  display: none;
  cursor: pointer; }

.media-copy:hover {
  background: #fff; }

.media-copy:hover svg {
  color: #008000 !important; }

.media-edit {
  position: absolute;
  padding: 7px;
  border-radius: 50px;
  background: #1ec2f3;
  padding-top: 4px;
  right: 43px;
  top: 8px;
  z-index: 2;
  line-height: 1;
  display: none;
  cursor: pointer; }

.media-edit:hover {
  background: #fff; }

.media-edit:hover svg {
  color: #1ec2f3 !important; }

.media-delete {
  position: absolute;
  padding: 7px;
  border-radius: 50px;
  background: #ef2b08;
  padding-top: 4px;
  right: 8px;
  top: 8px;
  z-index: 2;
  line-height: 1;
  display: none;
  cursor: pointer; }

.media-delete:hover {
  background: #fff; }

.media-delete:hover svg {
  color: #ef2b08 !important; }

.media-delete.copy {
  background: #008000; }

.media-delete.copy:hover {
  background: #fff !important; }

.media-delete.copy:hover svg {
  color: #008000 !important; }

.gallery-video.gallery-video-big {
  width: 100%;
  height: auto; }

.main-gallery-bg {
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 185px;
  max-height: 185px;
  cursor: pointer; }

.main-gallery-bg-small {
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100px;
  max-height: 100px; }

.allocation-button {
  cursor: pointer; }

.reallocated-icon {
  max-width: 20px;
  margin-left: 10px; }

.panel-approved {
  color: #45b649;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 30px; }

.priority-edit {
  position: absolute;
  right: 20px;
  top: 4px; }

.priority-1 {
  background: #45b649; }

.priority-2 {
  background: #A9E500; }

.priority-3 {
  background: #E5B000; }

.priority-4 {
  background: #D67830; }

.priority-5 {
  background: #D63030; }

.priority-name {
  display: inline-block;
  margin-left: 10px;
  border: 1px solid #CCD5E0;
  width: 90%;
  padding: 5px 10px;
  border-radius: 5px; }

.title {
  position: relative;
  margin-top: 30px;
  width: 100%;
  text-align: center; }

.timeline {
  position: relative;
  width: 100%; }

.timeline .timeline-container {
  position: relative;
  width: 100%; }

.timeline .timeline-continue {
  position: relative;
  width: 100%; }

.timeline .timeline-continue::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 95%;
  top: 0;
  left: 128px;
  margin-left: -1px;
  background: #1ec2f3; }

.timeline .row.timeline-right,
.timeline .row.timeline-left .timeline-date {
  text-align: left; }

.timeline .timeline-date {
  font-size: 14px;
  font-weight: 600;
  margin: 57px 0 0 0; }

.header-notification {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; }

.timeline .timeline-date::after {
  content: '';
  display: block;
  position: absolute;
  width: 14px;
  height: 14px;
  top: 60px;
  background: #1ec2f3;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  z-index: 1; }

.form-control:disabled, .form-control[readonly] {
  cursor: not-allowed; }

.timeline .row.timeline-left .timeline-date::after {
  left: -7px; }

.timeline .row.timeline-right .timeline-date::after {
  right: -7px; }

.timeline .timeline-box,
.timeline .timeline-launch {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 6px;
  background: #ffffff; }

.timeline .timeline-launch {
  width: 100%;
  margin: 15px 0;
  padding: 0;
  border: none;
  text-align: center;
  background: transparent; }

.timeline .row.timeline-left .timeline-box::after,
.timeline .row.timeline-left .timeline-box::before {
  left: 100%; }

.timeline .row.timeline-right .timeline-box::after,
.timeline .row.timeline-right .timeline-box::before {
  right: 100%; }

.timeline .timeline-launch .timeline-box::after,
.timeline .timeline-launch .timeline-box::before {
  left: 50%;
  margin-left: -10px; }

.timeline .timeline-box::after {
  top: 26px;
  border-color: transparent transparent transparent #ffffff;
  border-width: 10px; }

.timeline .timeline-box::before {
  top: 25px;
  border-color: transparent transparent transparent #dddddd;
  border-width: 11px; }

.timeline .row.timeline-right .timeline-box::after {
  border-color: transparent #ffffff transparent transparent; }

.timeline .row.timeline-right .timeline-box::before {
  border-color: transparent #dddddd transparent transparent; }

.timeline .timeline-launch .timeline-box::after {
  top: -20px;
  border-color: transparent transparent #dddddd transparent; }

.timeline .timeline-launch .timeline-box::before {
  top: -19px;
  border-color: transparent transparent #ffffff transparent;
  border-width: 10px;
  z-index: 1; }

.timeline .timeline-box .timeline-icon {
  position: relative;
  width: 40px;
  height: auto;
  float: left; }

.social-shared-photo {
  width: 100%; }

.social-media-publish-timeline {
  display: flex;
  column-gap: 10px;
  overflow: hidden;
  height: 150px;
  overflow-x: scroll; }

.timeline-image-close img {
  position: relative; }

.timeline-image-close svg {
  position: absolute;
  z-index: 1;
  margin-top: -8px;
  background: #fff;
  width: 20px;
  height: 20px;
  color: #E04E5E;
  border-radius: 50%;
  right: -8px; }

.timeline-image-profile img {
  width: 100%;
  border-radius: 50%; }

.social-media-pop-up-linkedin {
  padding: 7px;
  max-width: 50px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  background: #0076B4;
  position: relative; }

.social-media-pop-up .linkedIn-icon {
  color: #fff;
  cursor: pointer;
  width: 35px;
  height: 35px; }

.social-media-pop-up-facebook {
  padding: 7px;
  max-width: 50px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  background: #1977D3;
  position: relative; }

.social-media-pop-up .facebook {
  color: #fff;
  cursor: pointer;
  width: 35px;
  height: 35px; }

.social-media-pop-up-instagram {
  padding: 7px;
  max-width: 50px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  background: #F6248D;
  position: relative; }

.social-media-pop-up .instagram {
  color: #fff;
  cursor: pointer;
  width: 35px;
  height: 35px; }

.social-media-pop-up-twitter {
  padding: 7px;
  max-width: 50px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  background: #00BEF4;
  position: relative; }

.social-media-pop-up .twitter {
  color: #fff;
  cursor: pointer;
  width: 35px;
  height: 35px; }

.social-small-pop-up {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 15px; }

.add-more-account-pop-up p {
  font-size: 14px; }

.schedule-pop-up {
  position: absolute;
  top: 215px;
  border: none;
  transition: ease 1s;
  text-align: center;
  right: 0;
  left: 0;
  box-shadow: 0px 0px 10px 0px #00000047; }

.timeline-image-close {
  position: relative;
  height: 100%;
  margin-top: 10px; }

.gallery-modal-tooltip {
  position: relative;
  display: inline-block;
  font-weight: 600;
  background-color: #ffffff;
  border-radius: 0.25rem;
  cursor: pointer;
  margin-right: 15px; }

.gallery-modal-tooltip .gallery-modal-tooltiptext {
  visibility: hidden;
  width: 180px;
  background-color: black;
  opacity: 0.8;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -89px;
  font-weight: 600;
  font-size: 14px;
  text-align: left; }

.gallery-modal-tooltip .gallery-modal-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent; }

.gallery-modal-tooltip:hover .gallery-modal-tooltiptext {
  visibility: visible; }

.time-schedule {
  color: #A2A2A3;
  max-width: 98px; }

.time-schedule:hover {
  color: #313031; }

.prio-categories {
  display: flex;
  border-radius: 50%;
  border: 10px solid #1ec2f3;
  color: #ffffff;
  width: 20px;
  height: 20px;
  font-size: 12px;
  justify-content: center;
  align-items: center; }

.prio-div {
  display: inline-block; }

.check-connected {
  position: absolute;
  right: 0;
  color: #05CC33;
  top: 0;
  background: #fff;
  border-radius: 50%; }

/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 16px;
  cursor: pointer;
  font-size: 22px;
  margin-bottom: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid #838383;
  border-radius: 2px;
  background: #fff; }

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #1EC2F3;
  border: 1px solid #1EC2F3; }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none; }

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block; }

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 5px;
  top: 0px;
  width: 0px;
  height: 10px;
  border-radius: 21px;
  border: solid white;
  border-width: 13px 3px 0px 0;
  transform: rotate(45deg); }

.ant-divider {
  display: none !important; }

.ant-card-body .ant-row:nth-child(2) {
  display: none !important; }

.info-badge {
  width: 100%;
  max-width: 200px;
  text-align: center;
  color: #fff; }

.stakeholder-head-image {
  display: flex; }

.stakeholder-dropdown-option {
  position: absolute;
  top: 56px;
  width: 100%;
  z-index: 1;
  max-width: 222px; }

.stakeholder-dropdown-option p {
  text-transform: capitalize; }

.dropdown-option-assigned {
  background: #013AF5;
  width: 5px;
  height: 5px;
  margin-top: 2px;
  margin-right: 7px;
  padding: 9px;
  border-radius: 5px; }

.dropdown-option-revision {
  background: #E8500D;
  width: 5px;
  height: 5px;
  margin-top: 2px;
  margin-right: 7px;
  padding: 9px;
  border-radius: 5px; }

.dropdown-option-printing {
  background: #CF9905;
  width: 5px;
  height: 5px;
  margin-top: 2px;
  margin-right: 7px;
  padding: 9px;
  border-radius: 5px; }

.dropdown-option-done {
  background: #049818;
  width: 5px;
  height: 5px;
  margin-top: 2px;
  margin-right: 7px;
  padding: 9px;
  border-radius: 5px; }

.stakeholder-dropdown-option p:hover {
  color: #1EC2F3;
  cursor: pointer; }

.stakeholder-dropdown-assigned {
  background: #013AF5; }

.stakeholder-dropdown-revision {
  background: #E8500D; }

.stakeholder-dropdown-printing {
  background: #CF9905; }

.stakeholder-dropdown-done {
  background: #049818; }

.stakeholder-dropdown {
  background: #1EC2F3; }

.stakeholder-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer; }

.stakeholder-tooltip .stakeholder-tooltiptext {
  visibility: hidden;
  width: 305px;
  background-color: black;
  opacity: 0.8;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 0%;
  margin-left: -112px;
  font-weight: 600;
  font-size: 14px; }

.stakeholder-tooltip .stakeholder-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent; }

.stakeholder-tooltip:hover .stakeholder-tooltiptext {
  visibility: visible; }

.stakeholder-image {
  width: 100%;
  max-width: 124px;
  margin-right: 14px; }

.stakeholder-card-page {
  height: 150px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center; }

.artwork-overlay {
  display: flex;
  position: absolute;
  width: 113px;
  max-width: 100%;
  color: #ffffff;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  justify-content: space-around;
  align-items: center;
  font-size: 24px; }

.floorplan-checkbox {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: start;
  column-gap: 20px;
  margin-bottom: 15px;
  margin-top: 20px; }

.seats-dnd {
  min-height: 500px; }

.seat-custom-select {
  background: #fff;
  border-radius: 5px;
  color: #000;
  height: 40px;
  padding: 10px;
  width: 100%;
  max-width: 300px;
  border: 1px solid #D4D4D4; }

.seat-custom-select:focus {
  outline: none; }

#seat-custom-select-content {
  float: left;
  margin-top: -2px; }

#seat-custom-select-icon {
  float: right; }

#seat-custom-select-icon svg {
  margin-bottom: 5px; }

.seat-custom-select-options {
  background: #fff;
  border-radius: 5px;
  padding: 20px 10px;
  position: absolute;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  transition: .5s;
  width: 100%;
  max-width: 250px;
  z-index: 5;
  border: 1px solid #D4D4D4;
  margin-top: -10px;
  box-shadow: 0px 1px 4px 1px #d4d4d4; }

.seat-custom-select-options ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0; }

.seat-custom-select-options ul li {
  margin-bottom: 10px;
  line-height: 1.3; }

.seat-custom-select-options [type='checkbox'] {
  margin-top: 3px;
  margin-left: 9px; }

.seat-view-legend {
  display: flex;
  column-gap: 30px;
  justify-content: flex-start; }

.seat-view-legend .taken {
  border-left: 25px solid #28C342;
  padding-left: 12px; }

.seat-view-legend .available {
  border-left: 25px solid #CFD2D5;
  padding-left: 12px; }

.attendee-container.available {
  border-left: 25px solid #CFD2D5;
  padding-left: 12px; }

.attendee-container.available.small {
  border-left: 16px solid #CFD2D5;
  font-size: 16px;
  padding-left: 5px;
  height: 17px;
  line-height: 1; }

.attendee-container.taken {
  border-left: 25px solid #28C342;
  padding-left: 12px; }

.attendee-box {
  font-size: 50px;
  float: left;
  height: 25px;
  width: 25px;
  /* margin-bottom: 15px; */
  /* border: 1px solid black; */
  clear: both; }

.attendee-box.taken {
  background-color: #1ec2f3; }

.attendee-box.available {
  background-color: #CFD2D5; }

.seats-dnd-card {
  border: 2px solid #CFD2D5;
  height: 100%; }

.seats-dnd-card .card-body {
  padding: 15px 5px; }

.seat-dnd-table-list img {
  width: 100%;
  max-width: 30px;
  align-self: center;
  height: 100%; }

.seat-dnd-table-list {
  padding: 3px;
  align-items: center; }

.seat-dnd-table-list .taken {
  width: 20px;
  height: 20px;
  background: #28C342; }

.seat-dnd-table-list .available {
  width: 20px;
  height: 20px;
  background: #CFD2D5; }

.seat-dnd-table-list .info {
  display: flex;
  column-gap: 4px;
  align-items: center;
  place-self: flex-start; }

.seat-dnd-table-list svg {
  color: #E7BA4C; }

.seats-search-bar svg {
  position: absolute;
  top: 10px;
  right: 23px;
  font-size: 20px; }

.bis-link-text {
  color: #1ec2f3; }

.seat-table-image {
  width: 100%;
  max-width: 100px; }

.seats-modal-image {
  width: 100%;
  max-width: 200px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 25px;
  margin-bottom: 40px; }

.seat-profile-icon {
  font-size: 35px;
  color: #1ec2f3; }

.seat-trophy-icon {
  color: #E7BA4C;
  margin-right: 2px; }

.seat-navigation {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 20px;
  margin: 0px 15px; }

.tables-arrangement .table-number-container {
  border: none;
  text-align: center;
  line-height: 1;
  padding: 5px;
  margin-bottom: 10px;
  background: #eee;
  border-radius: 3px;
  min-width: 90px;
  min-height: 90px;
  max-width: 130px; }

.table-Round .table-number-container {
  border-radius: 100px; }

.table-number-text {
  margin-top: 20px; }

.react-draggable {
  cursor: grab; }

.average-priority-name-tooltip {
  position: relative;
  display: inline-block; }

.average-priority-name-tooltip .average-priority-name-tooltiptext {
  visibility: hidden;
  width: 270px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 50%;
  margin-left: -135px;
  padding: 5px 15px; }

.average-priority-name-tooltip .average-priority-name-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent; }

.average-priority-name-tooltip:hover .average-priority-name-tooltiptext {
  visibility: visible; }

.overall-average-priority-name-tooltip {
  position: relative;
  display: inline-block; }

.overall-average-priority-name-tooltip .overall-average-priority-name-tooltiptext {
  visibility: hidden;
  width: 340px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 15px;
  position: absolute;
  z-index: 1;
  bottom: 200%;
  left: 50%;
  margin-left: -170px; }

.overall-average-priority-name-tooltip .overall-average-priority-name-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent; }

.overall-average-priority-name-tooltip:hover .overall-average-priority-name-tooltiptext {
  visibility: visible; }

.history-name-tooltip {
  position: relative;
  display: inline-block; }

.history-name-tooltip .history-name-tooltiptext {
  visibility: hidden;
  width: 115px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 15px;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 50%;
  margin-left: -53px; }

.history-name-tooltip .history-name-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent; }

.history-name-tooltip:hover .history-name-tooltiptext {
  visibility: visible; }

.edit-name-tooltip {
  position: relative;
  display: inline-block; }

.edit-name-tooltip .edit-name-tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 15px;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 50%;
  margin-left: -48px; }

.edit-name-tooltip .edit-name-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent; }

.edit-name-tooltip:hover .edit-name-tooltiptext {
  visibility: visible; }

.newbuttontheme-cancel {
  background-color: red;
  border: 1px solid red;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff; }

.custom-select-invoices {
  background: #fff;
  border-radius: 5px;
  color: #000;
  height: 38px;
  padding: 10px;
  width: 240px;
  margin-left: 10px;
  border: 1px solid #D4D4D4;
  outline: none; }

.custom-select-invoices:focus {
  outline: none; }

.search-icon-invoices {
  position: absolute;
  top: 8px;
  margin-left: 170px;
  font-size: 20px; }

.search-input-invoices {
  position: relative;
  width: 350px;
  margin-right: 10px; }

.bis-tooltip-download {
  position: relative;
  display: inline-block;
  font-weight: 600;
  cursor: pointer; }

.bis-tooltip-download .bis-tooltiptext-download {
  visibility: hidden;
  width: 130px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -65px;
  font-weight: 600;
  font-size: 14px; }

.bis-tooltip-download .bis-tooltiptext-download::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent; }

.bis-tooltip-download:hover .bis-tooltiptext-download {
  visibility: visible; }

.entry-feedback {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }

.entry-feedback svg {
  font-size: 20px; }

.script-container {
  height: 766px;
  max-width: 100%; }

.script-container-body {
  overflow-y: scroll; }

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 766px;
  max-width: 100%; }

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.panel-divider {
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 1px;
  background: #1EC2F3; }

.judging-panel:hover .panel-divider {
  background: #fff; }

.colored-bullets {
  min-width: 15px;
  min-height: 15px;
  border-radius: 50%;
  margin-top: 5px; }

.entry-list {
  display: flex;
  align-content: center;
  align-items: flex-start;
  column-gap: 8px; }

.submit-disabled {
  background-color: #6cddff;
  border: 1px solid #1ec2f3;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
  pointer-events: auto !important;
  cursor: not-allowed !important; }

.action_container span {
  color: #212529;
  font-size: 14px;
  font-weight: 600;
  padding-left: 12px;
  display: inline-block;
  width: 115px; }

.scores-input::-webkit-outer-spin-button,
.scores-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.scores-input {
  width: 80px;
  margin-left: auto;
  margin-right: auto; }

.radio-invoice-selection {
  display: flex;
  column-gap: 10px; }

.radio-invoice {
  display: flex;
  column-gap: 30px; }

.banktransfer-textarea {
  min-height: 100px; }

.bank_transfer_number::-webkit-outer-spin-button,
.bank_transfer_number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.no-arrow-number::-webkit-outer-spin-button,
.no-arrow-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.terms-po-number {
  width: 200px !important;
  display: inline-block !important;
  margin-left: 10px; }

canvas.sigCanvas {
  border: 1px solid #CED4DA;
  width: 100%; }

.file-upload-image {
  width: 100%;
  max-width: 80px; }

.add-upload-file .card {
  border: 1px solid #DADADA;
  box-shadow: none; }

.file-upload {
  text-align: center;
  box-shadow: none;
  display: flex;
  /* justify-content: center;
  align-items: center;
  align-content: center; */
  border: none;
  width: 100%;
  min-height: 350px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='1' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); }

.upload_label {
  margin-top: 90px;
  cursor: pointer; }

.product-image-bg {
  width: 100%;
  height: 100%;
  min-height: 163px;
  background-position: center;
  /* background-size: cover; */
  background-size: contain;
  max-height: 163px;
  position: relative;
  background-repeat: no-repeat; }

.media-library-image-bg {
  width: 100%;
  height: 400px;
  min-height: 200px;
  background-position: center;
  /* background-size: cover; */
  background-size: contain;
  position: relative;
  background-repeat: no-repeat; }

.ticket-image-bg {
  width: 100%;
  height: 100%;
  min-height: 30px;
  background-position: center;
  /* background-size: cover; */
  background-size: contain;
  max-height: 30px;
  position: relative;
  background-repeat: no-repeat; }

#admin-gallery-upload {
  max-width: 900px; }

.product-image-bg.admin {
  min-height: 189px;
  max-height: 189px;
  background-size: cover; }

.image-item {
  max-height: 243px; }

.remove-product-image {
  right: 0px;
  top: 0px;
  color: #fff;
  background: red;
  border-radius: 50px;
  width: 25px;
  height: 25px;
  padding: 5px; }

.remove-vimeo-field {
  position: absolute;
  right: 5px;
  margin-top: -45px;
  z-index: 1;
  cursor: pointer; }

.bis-tooltip-vimeo.table-tooltip .bis-tooltiptext-vimeo {
  width: 250px;
  left: -20px;
  bottom: 105%; }

.bis-tooltip-vimeo {
  position: relative;
  display: inline-block;
  font-weight: 600;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 0.25rem;
  cursor: pointer; }

.bis-tooltip-vimeo .bis-tooltiptext-vimeo {
  visibility: hidden;
  width: 160px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
  font-weight: 600;
  font-size: 14px; }

.bis-tooltip-vimeo .bis-tooltiptext-vimeo::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent; }

.bis-tooltip-vimeo:hover .bis-tooltiptext-vimeo {
  visibility: visible; }

.action_table_container {
  position: absolute;
  top: 15px;
  right: 15px;
  width: auto; }

.profile-picture-container {
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 500px; }

.divider-small {
  width: 50px;
  background: #1ec2f3;
  margin-bottom: 15px;
  margin-top: 10px; }

.iframe-card {
  height: 1000px; }

.collateral-folder {
  width: 100%;
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer; }

.collateral-pdf {
  width: 100%;
  max-width: 90px;
  margin-left: auto;
  margin-right: auto; }

.collateral-folder-container {
  max-width: 54rem;
  min-height: 3rem; }

.collaterals {
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 115px;
  max-height: 115px;
  cursor: pointer; }

.collaterals-overlay-container {
  position: relative;
  width: 100%;
  max-height: 170px;
  min-height: 170px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center; }

.collateral-label {
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  padding: 5px 10px;
  color: #000;
  font-size: 12px; }

.collateral-pdf-iframe {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  height: 500px;
  /* 16:9 Aspect Ratio */ }

.collateral-image-iframe {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio */ }

.modal .artwork-add-button {
  background: #fff !important;
  color: #000;
  border-radius: 0.25rem; }

.card-body .artwork-add-button {
  background: #fff !important;
  color: #000;
  border-radius: 0.25rem; }

.artwork-add-icon {
  font-size: 25px; }

.artwork-title {
  color: #287EC3; }

.add-artwork-modal label {
  font-weight: bold; }

.edit-artwork label {
  font-weight: bold; }

.artwork-type-submit-buttons {
  color: #fff;
  background-color: #1ec2f3;
  border: 1px solid #dfdfdf;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600; }

.artwork-head-divider {
  border: 1px solid #DFDFDF;
  position: absolute;
  left: 50%;
  right: 50%;
  top: 0;
  width: 1px;
  height: 100%;
  border-right: 0; }

.timeline-artwork {
  position: relative; }

.timeline-artwork::after {
  content: '';
  position: absolute;
  width: 2px;
  background-color: #b5aaaa;
  top: 0;
  bottom: 11px;
  left: 6px; }

.timeline-artwork .timeline-container {
  padding-left: 5px;
  position: relative;
  background-color: inherit; }

.timeline-artwork .timeline-container::after {
  content: '';
  position: absolute;
  width: 11px;
  height: 11px;
  right: -16px;
  background-color: #b5aaaa;
  top: 6px;
  border-radius: 50%;
  z-index: 1; }

.timeline-artwork .right {
  left: 5%;
  margin-bottom: 12px;
  width: 95%; }

.collateral-logs-btn {
  float: right;
  position: absolute;
  top: 13px;
  right: 50px; }

.timeline-artwork .right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent; }

.timeline-artwork .right::after {
  left: -5%; }

.timeline-artwork .content {
  background-color: white;
  padding-top: 3px;
  padding-bottom: 3px; }

.timeline-card {
  padding: 5px 10px;
  z-index: 9;
  margin-bottom: 12px;
  background-color: #efefef; }

.status-artwork-show {
  min-width: 201px;
  position: absolute;
  z-index: 9;
  top: 40px;
  text-align: left; }

.status-artwork-show p:last-child {
  margin-bottom: 0; }

.status-artwork-show p {
  margin-bottom: 5px; }

.artwork-assigned-box {
  background: gold;
  outline: none;
  border-radius: 3px;
  width: 20px;
  height: 20px; }

.artwork-design-revision-box {
  background: #E8500D;
  outline: none;
  border-radius: 3px;
  width: 20px;
  height: 20px; }

.artwork-design-review-box {
  background: #8E7CC3;
  outline: none;
  border-radius: 3px;
  width: 20px;
  height: 20px; }

.artwork-approved-box {
  background: #1ec2f3;
  outline: none;
  border-radius: 3px;
  width: 20px;
  height: 20px; }

.artwork-print-revision-box {
  background: #a73807;
  outline: none;
  border-radius: 3px;
  width: 20px;
  height: 20px; }

.artwork-for-printing-box,
.artwork-printing-in-progress-box {
  background: #CF9905;
  outline: none;
  border-radius: 3px;
  width: 20px;
  height: 20px; }

.artwork-done-box {
  background: #049818;
  outline: none;
  border-radius: 3px;
  width: 20px;
  height: 20px; }

.artwork-for-approval-box {
  background: #D80BB6;
  outline: none;
  border-radius: 3px;
  width: 20px;
  height: 20px; }

.artwork-design-in-progress-box {
  background: #A9D153;
  outline: none;
  border-radius: 3px;
  width: 20px;
  height: 20px; }

.page-plan-item {
  max-width: 100%;
  height: 150px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer; }

.modal-select-collateral {
  min-height: 150px; }

.artwork-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; }

.artwork-tooltip .artwork-tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px; }

.artwork-tooltip .artwork-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent; }

.artwork-tooltip:hover .artwork-tooltiptext {
  visibility: visible; }

.more-designs {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  text-align: center;
  font-size: 40px;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  padding-top: 45px; }

.collateral-image-item {
  max-height: 235px; }

.page-plan-card .card {
  width: 100%;
  user-select: none;
  height: 220px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  background-color: #ffffff;
  border-radius: 5px;
  z-index: 1;
  overflow: hidden; }

.page-plan-card .card .card_header {
  display: flex;
  justify-content: space-between;
  padding: 10px; }

.page-plan-card .card .card_header h1 {
  font-size: 22px;
  color: #0a253f; }

.page-plan-card .card .card_header p {
  font-size: 16px;
  padding: 10px 20px 0 0;
  color: #4a4970; }

.page-plan-card .card .content-image .fa {
  padding: 7px 10px;
  font-size: 25px;
  border-radius: 50px;
  color: #ffffff;
  background-color: #025aa5; }

.page-plan-card .card_info {
  color: #0a253f;
  font-size: 14px;
  font-weight: 500;
  display: flex; }

.page-plan-card .card_info p {
  margin: 15px 10px; }

.page-plan-card .card_info span {
  color: #4a4970;
  margin-right: 10px; }

.page-plan-card .card .card_footer {
  padding: 10px 20px;
  align-items: center;
  background-color: #F0F4F7;
  display: flex;
  justify-content: space-between;
  border-top: 3px solid #025aa5; }

.page-plan-card .card .card_footer .btn {
  width: 100px;
  font-size: 16px;
  height: 30px;
  padding: 0 10px;
  border: none;
  outline: none;
  color: #fff;
  background: #025aa5;
  cursor: pointer;
  border-radius: 5px; }

.page-plan-card .card_footer button span {
  margin-left: 10px; }

.page-plan-names {
  font-size: 17px; }

.page-plan-divider {
  width: 1px;
  max-height: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 1px;
  background: #EFEFEF; }

.collateral-upload-container {
  min-height: 60px;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative; }

.remove-page-collateral {
  right: 1px;
  top: 1px;
  color: #fff;
  background: red;
  border-radius: 50px;
  width: 15px;
  height: 15px;
  padding: 3px; }

.add-page-collateral-btn {
  display: flex;
  align-items: center;
  padding-bottom: 21px; }

.page-collateral-container {
  min-height: 135px;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative; }

.collateral-document-file {
  min-height: 125px;
  max-height: 125px;
  margin: 10px 0; }

.design-document-file {
  min-height: 153px;
  max-height: 153px; }

.collateral-video {
  width: 100%;
  object-fit: cover; }

.remove-collateral-icon {
  right: -10px;
  top: -6px;
  color: #fff;
  background: red;
  border-radius: 50px;
  width: 25px;
  height: 25px;
  padding: 5px; }

.page-plan-assignee {
  display: flex;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px; }

.script-header {
  background-color: #F8F8F8;
  font-size: 17px; }

.active-element {
  background-color: #1EC2F3;
  color: #fff; }

.inactive-element {
  background-color: #F8F8F8;
  color: #000; }

.event-container {
  position: relative; }

.event-card {
  display: block;
  width: 100%; }

.event-body {
  height: 118px;
  overflow: hidden;
  display: flex;
  justify-content: center; }

.event-script {
  height: 125px; }

.event-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: auto;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.event-container:hover .event-overlay {
  opacity: 1; }

.event-container:hover .newsletter-overlay-button {
  opacity: 1; }

.event-choose-btn {
  background: #1EC2F3 0% 0% no-repeat padding-box !important;
  border-radius: 3px;
  opacity: 1;
  width: 160px; }

.event-preview-btn {
  background: none !important;
  border: 1px solid #1EC2F3 !important;
  border-radius: 3px;
  opacity: 1;
  width: 160px;
  color: #1EC2F3 !important; }

.faq-add-button {
  background: #dfdfdf !important;
  color: #000;
  border-radius: 0.25rem; }

.script-editor {
  max-height: 672px;
  min-height: 672px;
  background: #fff;
  border: 1px solid #dfdfdf;
  padding: 0 25px; }

.blocked-mail-editor {
  max-height: 280px;
  min-height: 280px;
  background: #fff;
  border: 1px solid #dfdfdf;
  padding: 0 25px; }

.entry-guide-editor {
  max-height: 400px;
  min-height: 400px;
  background: #fff;
  border: 1px solid #dfdfdf;
  padding: 0 25px; }

.event-guide-editor {
  max-height: 400px;
  min-height: 400px;
  background: #fff;
  border: 1px solid #dfdfdf;
  padding: 0 25px; }

.cms-editor {
  max-height: 300px;
  min-height: 300px;
  background: #fff;
  border: 1px solid #dfdfdf;
  padding: 0 25px; }

.cms-menu-list {
  list-style-type: none; }

.cms-menu-card {
  background-color: #f9f9f9; }

.cms-menu-card .p {
  font-weight: 600; }

.delete-feature-input {
  position: absolute;
  top: 10px;
  right: 25px;
  background: red;
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 3px; }

.delete-feature-spinner {
  position: absolute;
  top: 10px;
  right: 25px;
  background: #fff;
  color: red;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 3px; }

.featured-list-paragraph {
  display: flex;
  align-items: center;
  column-gap: 5px; }

.featured-list-paragraph svg {
  color: #02B902; }

.delete-list-paragraph svg {
  color: red; }

.newbtntheme-grey {
  background-color: #ECEDEF;
  border: 1px solid #dfdfdf;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600; }

.newbtntheme-grey:hover {
  color: #212529;
  background: #fff; }

.newbtntheme-grey:focus {
  outline: none; }

.sponsor-hand-icon {
  width: 100%;
  max-width: 24px;
  margin-right: 7px;
  height: 24px;
  color: #1ec2f3 !important; }

.sponsor-event {
  display: flex;
  padding: 10px;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start; }

.sponsor-event svg {
  color: #1ec2f3; }

.sponsor-search-wrapper {
  position: relative; }

.sponsor-search-icon {
  padding: 4px;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 93%;
  color: #495057;
  transform: translateY(-50%); }

.sponsor-search-input {
  box-sizing: border-box; }

.sponsor-name {
  color: #1ec2f3; }

.sponsors-logo {
  min-height: 80px;
  width: 80px;
  background-size: cover;
  background-position: center;
  position: relative; }

.sponsor-type {
  color: #f0a728;
  font-weight: 700;
  font-size: 20px; }

.agreement-list {
  padding-left: 25px; }

.agreement-list li {
  font-size: 16px;
  padding-left: 10px; }

.agreement-list-header {
  font-weight: 600;
  margin-bottom: 15px; }

.agreement-list li::marker {
  content: url(../assets/img/icons/check-green-icon-resizes.png);
  display: inline-block;
  height: 12px;
  width: auto;
  margin-right: 10px; }

.upgrade-package {
  background-image: url(../assets/img/icons/your-sponsorship-agreement.png);
  background-position: absolute;
  background-repeat: no-repeat;
  background-size: cover; }

.upgrade-package-text {
  font-size: 18px; }

.upgarade-package-button {
  background-color: #1EC2F3;
  color: #fff;
  border: none;
  padding: 5px;
  border-radius: 5px;
  width: 50%;
  font-size: 18px; }

.upgarade-package-button:hover {
  background-color: #01bef5; }

.sponsor-person {
  font-weight: 600;
  margin-bottom: 0px;
  font-size: 14px; }

.sponsor-position {
  color: #287FC1;
  margin-bottom: 0px;
  font-size: 14px; }

.sponsor-contact {
  margin-bottom: 0px; }

.sponsor-contact img {
  height: 16px; }

.sponsor-phone {
  margin-bottom: 0px; }

.sponsor-phone a {
  font-size: 14px; }

.sponsor-phone a:hover {
  text-decoration: none; }

.sponsor-phone img {
  margin-right: 8px; }

.sponsor-email {
  margin-bottom: 0px; }

.sponsor-email a {
  font-size: 14px; }

.sponsor-email a:hover {
  text-decoration: none; }

.sponsor-email img {
  margin-right: 8px; }

.sponsorship-management p {
  font-size: 16px; }

.management-list {
  display: flex;
  align-items: center; }

.management-list img {
  width: 40px;
  margin-right: 15px; }

.management-list p {
  font-size: 16px; }

.list-image img {
  width: 200px;
  height: auto; }

.activation-items {
  font-weight: 700 !important;
  font-size: 18px;
  color: #1ec2f3; }

.sponsor-icon img {
  border-radius: 100%;
  width: 64px; }

.sponsor-profile p,
label {
  font-weight: 600; }

.sponsored-categories {
  min-height: 36px; }

.newsletter-button {
  background-color: #277dc2;
  padding: 7px;
  color: #fff;
  border: none;
  border-radius: 5px;
  width: 75%; }

.management-sponsor-save-btn {
  background-color: #277dc2;
  padding: 7px;
  color: #fff;
  border: none;
  border-radius: 5px;
  width: 75%; }

.newsletter h2 {
  font-size: 18px;
  font-weight: 600; }

.sponsored-categories-dropdown {
  position: absolute;
  z-index: 9;
  top: 100%;
  text-align: left;
  overflow-y: scroll;
  height: 150px; }

.contact-bg {
  background-color: #f2f2f2; }

.contact-details img {
  height: 16px; }

.sponsorship-management h2 {
  font-size: 16px; }

.newsletter-area {
  height: 400px; }

.event-info-collaterals h2 {
  font-weight: 600;
  color: #1ec2f3; }

.tab-header-active {
  background-color: #fff;
  height: 50px;
  width: 100%; }

.tab-header {
  background-color: #fff;
  height: 50px;
  width: 100%; }

.tab-contents {
  height: 300px; }

.list-image img {
  width: 250px;
  height: auto; }

.contact-details {
  margin-bottom: 0px;
  display: flex;
  align-items: center; }

.contact-bg {
  background-color: #f2f2f2; }

.contact-details img {
  height: 16px; }

.sponsor-contact {
  margin-left: 15px; }

.tab-icon {
  width: 18px;
  margin-right: 10px; }

.tab-blank {
  height: 300px;
  width: 100%;
  background-color: #fff; }

.sponsor-navigations .card {
  font-weight: 700;
  font-size: 17px;
  text-align: center;
  display: block;
  background: #fff;
  padding: 13px; }

.sponsor-navigations .active-tab {
  background-color: #fff; }

.sponsor-navigations .inactive-tab {
  background-color: #ebebeb; }

.attendants {
  background-color: #fff !important; }

.dropdown {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

.activity-comment-body {
  border-radius: 0px 3px 3px 0px;
  border-top: 0px;
  border-right: 0px;
  border-bottom: 0px;
  height: 100%; }

.newsletter-image {
  flex: 1 1 auto;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

.newsletter-image-url {
  min-height: 63px;
  min-width: 113px;
  max-height: 63px;
  max-width: 113px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

.newsletter-overlay-button {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: auto;
  opacity: 0;
  transition: 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.company-logo {
  position: relative;
  max-height: 95px;
  min-height: 95px;
  min-width: 95px;
  max-width: 95px;
  background-size: cover;
  background-position: center; }

.sponsor-home-logo {
  position: relative;
  height: 150px;
  width: auto;
  background-size: cover;
  background-position: center; }

.judge-profile-image {
  width: 200px;
  height: 200px;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat; }

.delete-stakeholders {
  background: red;
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 3px; }

.copy-to-clipboard:hover {
  color: #1ec2f3; }

.sponsor-tabs-svg {
  filter: brightness(0); }

.select-test {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

.select-test:focus {
  color: #495057;
  background-color: #fff;
  border-color: #97e3f9;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #C7F0FC; }

.remove-table {
  right: 18px;
  top: 3px;
  color: #fff;
  background: red;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  padding: 5px; }

.floor-plan-font-divider {
  border-bottom: 3px solid #000;
  width: 100%;
  height: 3px; }

.floor-plan-subfont {
  font-size: 30px;
  margin-top: 12px; }

.floor-plan-font span {
  font-size: 60px;
  font-weight: 700; }

/* for survey form tooltip */
.tooltip-survey {
  position: relative;
  display: inline-block; }

.tooltip-survey .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 110%;
  left: 47%;
  margin-left: -60px; }

.tooltip-survey .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent; }

.tooltip-survey:hover .tooltiptext {
  visibility: visible; }

/* end of for survey form tooltip */
.comment-data {
  white-space: pre-line; }

.comment-area {
  resize: auto;
  max-width: 100%;
  min-width: 100%; }

.alert-warning.bg-lightgray {
  background: #f2f2f2;
  color: #212529;
  border-color: #ddd; }

.alert-warning.bg-lightgray hr {
  border-top-color: #ddd !important; }

.trophy-image {
  height: auto;
  width: 100%;
  background-size: cover;
  background-position: 50%;
  position: relative;
  border-color: #dfdfdf;
  border-style: solid; }

.quill .ql-container {
  height: 146px; }

.ql-align-center {
  text-align: center; }

.part-content-preview {
  max-height: 400px;
  overflow-y: auto; }

/* for notifications page */
.notification-options-container {
  display: flex;
  justify-content: end;
  column-gap: 10px; }

.test-container {
  display: flex;
  align-items: center;
  column-gap: 10px; }

/* end of for notifications page */
/* Icons */
/* Define the keyframes for the rotating animation */
@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* Apply the rotating animation to the icon */
.icon-loading {
  animation: rotate 1s linear infinite; }

.invoice-logs ul {
  margin-bottom: 0; }

.rotate {
  animation: rotate 1.5s linear infinite; }

.award-cms-color {
  width: 100%;
  height: 30px;
  border-radius: 2px;
  border: 1px solid rgba(221, 221, 221, 0.867); }

@keyframes rotate {
  to {
    transform: rotate(360deg); } }

.entrant-chat-widget {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #1ec2f3;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 50%;
  width: 75px;
  height: 75px; }

.entrant-chat-widget-icon {
  font-size: 40px;
  position: relative;
  top: 18px;
  left: 18px;
  color: #fff; }
  .entrant-chat-widget-icon.new-message {
    left: -2px !important; }

.entrant-chat-widget:hover {
  animation-name: background-color;
  animation-duration: 2000ms;
  background-color: #fff !important; }

.entrant-chat-widget:hover .entrant-chat-widget-icon {
  color: #1ec2f3 !important; }

.entrant-chat-widget-container {
  position: fixed;
  z-index: 1;
  bottom: 100px;
  right: 20px;
  opacity: 0;
  transition: all .2s;
  visibility: hidden;
  width: 372px;
  height: calc(100% - 143px);
  max-height: 520px; }
  .entrant-chat-widget-container.chat-container-show {
    opacity: 1;
    visibility: visible; }

.entrant-chat-widget-container .comment-area {
  height: auto !important;
  resize: none !important; }

.entrant-chat-widget-container .entrant-chat-widget-container-card .chat-container {
  overflow-y: scroll; }

.chat-widget-notif {
  bottom: -3px;
  left: 55px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid white;
  top: -10px;
  display: none;
  justify-content: center;
  align-items: center; }
  .chat-widget-notif.new-message {
    display: inline-flex; }

.rdw-embedded-modal, .rdw-link-modal {
  height: 300px !important; }

.dot-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center; }

.dot-admin {
  background-color: #1ec2f3 !important; }

.dot-stakeholder {
  background-color: #ffd700 !important; }

.bg-new-gray {
  background-color: #f9f8fa !important; }

.wizard-cont {
  width: 100%; }

.progress-vertical-lines {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px; }

.progress-vertical-line {
  margin-top: 11px;
  width: 5px;
  height: 38px;
  max-height: 70px !important;
  background-color: #eee;
  position: relative;
  z-index: 1;
  margin-left: 9px; }

.progress-line-active {
  background-color: #1ec2f3 !important; }

.project-progress-container {
  margin: 0 0 24px 0; }

.project-progress-circle {
  width: 23px;
  height: 23px;
  border: 2px solid #eee;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff; }

.project-progress-check {
  border: 2px solid #1ec2f3 !important;
  background-color: #1ec2f3 !important; }

.project-progress-active {
  border: 2px solid #1ec2f3 !important;
  background-color: #ffffff !important; }

.project-progress-solid {
  width: 16px !important;
  height: 16px !important;
  border-radius: 50% !important;
  background-color: #1ec2f3 !important; }

.project-progress-number {
  font-size: 12px !important;
  color: #9c9c9c;
  font-weight: bold; }

.project-progress-contents {
  position: absolute;
  top: 1;
  width: 100%;
  z-index: 999; }

.project-status-inactive {
  color: #9c9c9c; }

.project-status-check {
  color: #000000; }

.project-status-active {
  color: #000000;
  font-weight: bold; }

.project-card-head-border {
  border-top: 5px solid #1ec2f3; }

.text-transform-none {
  text-transform: none !important; }

.border-success {
  border: 1px solid #45b64985 !important; }

.uploaded-project-file {
  border-radius: 10px;
  width: 105px;
  height: 105px;
  max-width: 190px;
  max-height: 190px;
  background-position: center;
  background-size: cover; }

.uploaded-project-file-add {
  border: 1px solid #9c9c9c;
  border-radius: 10px;
  width: 105px;
  height: 105px;
  max-width: 190px;
  max-height: 190px;
  background-position: center;
  background-size: cover; }

.uploaded-project-video {
  width: 105px;
  height: 105px;
  max-width: 190px;
  max-height: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; }

.video-x-icon {
  position: absolute;
  margin-bottom: 80px !important; }

.file-x-icon {
  position: absolute;
  margin-bottom: 80px !important;
  margin-left: -15px !important; }

.border-dotted {
  border: 1px dashed #9c9c9c; }

.project-file-upload {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #9c9c9c;
  cursor: pointer; }

.project-fs-10 {
  font-size: 10px; }

.project-fs-12 {
  font-size: 12px; }

.project-fs-14 {
  font-size: 14px; }

/* Define a keyframe animation for spinning */
@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* Apply the animation to the .loading class */
.upload-file-loading {
  animation: spin 4s linear infinite; }

.equal-height-col {
  display: flex !important;
  flex-direction: column !important; }

/* Project invoice file upload */
.invoice-file-uploader {
  width: 100% !important;
  height: 100% !important;
  border: 1px dashed #6c757da6 !important;
  border-radius: 5px !important; }

.text-gray-new {
  color: #9c9c9c !important; }

/* Project materials */
.project-material-links {
  font-size: 14px; }

.project-material-links:hover {
  color: #00BEF4; }

.tr-no-hover:hover {
  background-color: #ffffff !important; }

.action_table_filter {
  position: relative;
  display: inline-block;
  cursor: pointer; }

.action_table_filter .action_content_filter {
  width: 250px;
  padding: 0;
  right: 5px;
  top: 23px;
  position: absolute;
  z-index: 1;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.39); }

.filter-selector-hover:hover {
  background-color: #eee !important; }

.bg-white-bordered {
  border: 0.5px solid #dfdfdf !important;
  border-radius: 4px !important; }

#sponsorship-agreements .table-container {
  overflow-x: scroll;
  overflow-y: hidden; }

.ai-generator-container-bg {
  background: #f1f1f1; }

.custom-gray-alert {
  padding: 0.45rem 1.25rem !important;
  background-color: #e5e5e5 !important;
  color: #4a4a4a !important; }

.action-tooltip {
  position: relative; }

.action-tooltip .action-tooltip-text {
  visibility: hidden;
  position: absolute;
  width: 127px;
  background-color: #555;
  bottom: 33px;
  left: -53px;
  color: #fff;
  text-align: center;
  padding: 5px;
  font-size: 16px;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity .6s; }

.action-tooltip:hover .action-tooltip-text {
  visibility: visible;
  opacity: 1; }

.bookings-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: fit-content; }

.bookings-header div {
  text-transform: none; }

.booking-header-table-option {
  text-align-last: center;
  font-weight: 700;
  color: #212529; }

.steps-progress .feedback-form-steps-container-size {
  width: 60px;
  height: 60px; }

.steps-progress .feedback-form-steps-number-size {
  font-size: 38px; }

.upsell-item-description p:last-of-type {
  margin-bottom: 0px; }

.sponsor-logo-row {
  vertical-align: middle;
  align-items: center;
  justify-content: center; }

.sponsor-logo-container {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  min-height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.35);
  border-radius: 10px;
  margin-bottom: 30px;
  width: 100%; }

.sponsor-logo-container .sponsor-img {
  padding: 10px; }

.email-signature-logo {
  max-width: 300px; }

.email-signature-header {
  margin-bottom: 30px; }

#bis_award_tab .span-percent {
  position: absolute;
  right: 0px;
  top: -16px;
  left: 0px;
  color: black;
  font-size: 12px !important; }

#bis_award_tab #sponsorship-agreements .table th {
  vertical-align: top; }

#bis_award_tab #sponsorship-agreements .bis-tooltip .bis-tooltiptext {
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -100px;
  font-weight: 600;
  font-size: 14px; }

#bis_award_tab #progress_bar_judging .dashboard-progress-bar {
  position: relative;
  margin-top: 19px;
  overflow: visible !important; }

#bis_award_tab .action_table .action_content_sponsor_dashboard_company_name {
  width: 255px;
  padding: 0;
  right: 15px;
  top: 5px;
  position: absolute;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.39); }

#bis_award_tab .action_table .action_content_sponsor_dashboard_package {
  width: 255px;
  padding: 0;
  right: 15px;
  top: 5px;
  position: absolute;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.39); }

#bis_award_tab .action_table .action_content_sponsor_dashboard_registered_date {
  width: 270px;
  padding: 0;
  right: 15px;
  top: 5px;
  position: absolute;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.39);
  z-index: 1; }

#bis_award_tab .action_table .action_content_sponsor_dashboard_package td:hover {
  background-color: rgba(0, 0, 0, 0.075); }

#bis_award_tab .sponsorship_pagination {
  position: absolute;
  right: 22px;
  bottom: 33px; }

.sponsorship_image {
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: 50px;
  padding-bottom: 50px;
  min-height: 400px; }

.venue-plan-background {
  position: absolute;
  /* Position the image absolutely within the container */
  top: 0;
  left: 0;
  width: 100%;
  /* Make the image cover the full width of the container */
  height: 100%;
  /* Make the image cover the full height of the container */
  object-fit: cover;
  /* Ensure the image covers the container while preserving aspect ratio */ }

.switch-category {
  position: relative;
  display: inline-block;
  width: 41px;
  height: 18px;
  border: none !important; }

.switch-category input {
  opacity: 0;
  width: 0;
  height: 0; }

.slider-category {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s; }

.slider-category:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 5px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s; }

input:checked + .slider-category {
  background-color: #5ED55E; }

input:checked + .question-category {
  background-color: #1EC2F3 !important; }

input:focus + .slider-category {
  box-shadow: 0 0 1px #2196F3; }

input:checked + .slider-category:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(20px); }

.slider-category.round-category {
  border-radius: 34px; }

.slider-category.round-category:before {
  border-radius: 50%; }

.loading-category {
  opacity: 0.5;
  pointer-events: none; }

.booking-pricing-settings-nav {
  font-size: 16px;
  border-radius: 5px 5px 0 0;
  border-bottom: 0px !important;
  position: relative;
  z-index: 1; }

.font-weight-medium {
  font-weight: 500; }

#hero-bussiness-river .bis_login_form .booking-confirmation-h1 {
  color: #1EC2F3;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.6;
  margin-top: 10px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto; }

#hero-bussiness-river .bis_login_form .bis-link {
  color: #1ec2f3;
  text-decoration: none; }

#hero-bussiness-river .bis_login_form .btn-secondary {
  background: #1ec2f3 !important;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  max-width: 500px; }

#hero-bussiness-river .bis_signup_form .booking-confirmation-h1 {
  color: #1EC2F3;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.6;
  margin-top: 10px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto; }

#hero-bussiness-river .bis_signup_form input {
  max-width: 100% !important; }

#hero-bussiness-river .bis_signup_form .btn-secondary {
  background: #1ec2f3 !important;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  max-width: 100%; }

#hero-bussiness-river .bis_signup_form.container {
  max-width: 700px; }

#hero-bussiness-river .card_bg_white {
  background: #ffffff;
  padding: 30px 10px 30px 10px;
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-left: 0;
  margin-right: 0; }

#hero-bussiness-river .bis_signup_form h3 {
  font-weight: 700;
  font-size: 17px;
  margin-top: 30px;
  margin-left: 0; }

.remove-custom-logo {
  position: absolute;
  right: 10px;
  top: 10px;
  color: red;
  cursor: pointer;
  z-index: 9; }

.btn-outline-dark-prev,
.btn-outline-dark-prev:hover,
.btn-outline-dark-prev:focus {
  color: #393C41;
  border-color: #393C41;
  background-color: #ffffff; }

.btn-blue-new,
.btn-blue-new:hover,
.btn-blue-new:focus-visible .btn-blue-new:active {
  background-color: #1193ba !important;
  border-color: #1193ba !important;
  color: #ffffff !important; }

.circle-icon {
  background-color: #FFFFFF;
  border-radius: 50%; }

.questions-answers-list {
  padding-left: 20px; }

.questions-answers-list li {
  margin-bottom: 5px; }

.card-img-left {
  border-radius: 0;
  border-top-left-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px); }

.card-img-right {
  border-radius: 0;
  border-top-right-radius: calc(.25rem - 1px);
  border-bottom-right-radius: calc(.25rem - 1px); }

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    transform: rotate3d(0, 0, 1, -5deg); }
  to {
    transform: rotate3d(0, 0, 1, 0deg); } }

.swing {
  transform-origin: top center;
  animation-name: swing; }

.animated {
  animation-duration: 1s;
  animation-fill-mode: both; }

.can-click {
  cursor: pointer; }

.overflow-hidden {
  overflow: hidden; }

@media (min-width: 1200px) {
  .hidden-xl {
    display: none; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .hidden-lg {
    display: none; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .hidden-md {
    display: none; } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .hidden-sm {
    display: none; } }

@media (max-width: 575.98px) {
  .hidden-xs {
    display: none; } }

.modal-backdrop-light {
  background: #f8f9fa;
  opacity: 1; }
  .modal-backdrop-light.modal-backdrop.show {
    opacity: 1; }

.cr-header {
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6; }
  .cr-header__nav-right {
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto; }

.cr-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -224px;
  z-index: 5;
  width: 224px;
  font-weight: 200;
  color: #fff;
  background-position: center center;
  background-size: cover;
  transition: left 0.1s ease-in; }
  .cr-sidebar + .cr-content {
    transition: margin 0.1s ease-in; }
  .cr-sidebar::after, .cr-sidebar::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 100%;
    height: 100%;
    content: ''; }
  .cr-sidebar::before {
    background: #000;
    opacity: 0.33; }
  .cr-sidebar::after {
    z-index: 3;
    background: #fc5c7d;
    /* fallback for old browsers */
    background: linear-gradient(to bottom, #6a82fb, #fc5c7d);
    opacity: 1; }
  .cr-sidebar[data-image]:after {
    opacity: 0.77; }
  .cr-sidebar__content {
    position: relative;
    z-index: 4;
    height: calc(100vh - 20px);
    padding-bottom: 30px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none; }
    .cr-sidebar__content::-webkit-scrollbar {
      width: 0; }
  .cr-sidebar__background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover; }
  .cr-sidebar__nav-item-collapse {
    display: flex;
    justify-content: space-between;
    cursor: pointer; }
  .cr-sidebar__nav-item-icon {
    width: 1.5rem;
    height: 1.5rem;
    padding-right: 0.5rem; }
  .cr-sidebar--open {
    left: 0;
    transition: left 0.2s ease-in; }
    .cr-sidebar--open + .cr-content {
      margin: 0 0 0 224px;
      transition: margin 0.2s ease-in; }
  .cr-sidebar .nav {
    margin-top: 20px; }
    .cr-sidebar .nav .nav-item {
      margin: 5px 10px;
      color: #f8f9fa;
      border-radius: 4px;
      opacity: 0.86; }
    .cr-sidebar .nav .nav-link {
      color: #f8f9fa; }
      .cr-sidebar .nav .nav-link:hover {
        background: rgba(248, 249, 250, 0.15); }

.cr-content {
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0; }

.cr-page {
  min-height: 100vh; }
  .cr-page__header {
    display: flex;
    align-items: flex-end;
    align-self: center; }
    @media (max-width: 767.98px) {
      .cr-page__header {
        flex-direction: column;
        align-items: flex-start; } }
  .cr-page__title {
    padding-right: 0.5rem;
    margin-bottom: 1rem; }
    @media (max-width: 767.98px) {
      .cr-page__title {
        margin-bottom: 0; } }
  .cr-page__breadcrumb {
    align-self: flex-end;
    padding: 0;
    text-transform: capitalize;
    background: #f8f9fa; }
    @media (max-width: 767.98px) {
      .cr-page__breadcrumb {
        align-self: flex-start; } }
  .cr-page .row .col,
  .cr-page .row > [class^='col-'] {
    margin-bottom: 1rem; }

.cr-page-spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.cr-widget {
  display: flex;
  flex-direction: row; }
  .cr-widget__icon {
    flex: 0 0 auto;
    align-items: center;
    justify-content: center; }

.cr-search-form {
  position: relative; }
  .cr-search-form__icon-search {
    position: absolute;
    left: 8px; }
  .cr-search-form__input {
    /* stylelint-disable-next-line */
    padding-left: 35px !important; }

.rti--container, .rti--container:focus-visible, .rti--container:focus-within {
  align-items: center !important;
  background: var(--white) !important;
  border: 1px solid #ced4da !important;
  border-radius: 5px !important;
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 0.37rem !important;
  line-height: 1.4 !important;
  padding: 0.45rem !important;
  margin-top: 9px !important;
  box-shadow: none !important; }

.rti--tag {
  align-items: center !important;
  background: var(--primary) !important;
  color: var(--white);
  border-radius: 5px !important;
  display: inline-flex;
  justify-content: center;
  padding: 3px 5px !important;
  font-size: 12px !important; }

.rti--input {
  border: 0;
  outline: 0;
  font-size: 12px !important;
  line-height: 1.6 !important;
  width: 50% !important; }

.rti--tag button {
  background: none;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  line-height: inherit;
  padding: 0 0 0 10px !important;
  color: var(--white) !important;
  font-weight: 600; }

.rti--tag button:hover {
  color: var(--white) !important; }

.bg-gradient-theme {
  background: linear-gradient(to bottom, #1ec2f3, #212529); }

.bg-gradient-theme-top {
  background: linear-gradient(to top, #1ec2f3, #212529); }

.bg-gradient-theme-left {
  background: linear-gradient(to left, #1ec2f3, #212529); }

.bg-gradient-theme-right {
  background: linear-gradient(to right, #1ec2f3, #212529); }

.ButtonPage .btn {
  margin: 4px; }

.ButtonPage .btn-group .btn {
  margin: 0; }

.TablePage .card-body {
  overflow: hidden; }
